import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import GirdList from "../../components/Sections/GirdList";
import Services from "../../components/Sections/List";
import Clients from "../../components/Common/Clients";
import ContactUsNow from "../../components/Common/ContactUsNow";
import Footer from "../../components/Common/Footer";
import cloudHeader from "../../images/vectors/Cloud.svg";
import cloudContact from "../../images/vectors/Cloud-Contact.svg";
import { cloud } from "../../utils/cloud";
import { logos } from "../../utils/logos";

function Cloud() {
  return (
    <div>
      <Menu />

      <Header
        title="Unlock Efficiency & Scalability:"
        subTitle="GBT's Cloud Services"
        miniTitle="GBT Services"
        description="GBT's cloud services empower businesses to thrive in the digital age.  Our solutions provide the agility, reliability, and scalability you need to transform operations and unlock growth."
        vector={cloudHeader}
      />

      <GirdList
        className="mx-auto max-w-[1400px]"
        title="Our services"
        description="Build your Business in the Cloud with Flexible Solutions for Growth."
        content={cloud.services}
      />

      <Services
        title="Uses of"
        subTitle="cloud computing"
        description="You’re probably using cloud computing right now, even if you don’t realize it. If you use an online service to send email, edit documents, watch movies or TV, listen to music, play games, or store pictures and other files, it’s likely that cloud computing is making it all possible behind the scenes."
        content={cloud.uses}
        intro="Here are a few examples of what’s possible today with cloud services from a cloud provider:"
      />

      <GradientContainer
        className="h-[332px] md:h-[232px]"
        title="Top benefits of cloud computing"
        content="Cloud computing is a big shift from the traditional way businesses think about IT resources."
        description="Here are seven common reasons organizations are turning to cloud computing services:"
      />
      <GridItems content={cloud.benefits} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.customers}
      />

      <ContactUsNow
        title="Ready to dive in?"
        description="Don't hesitate to ask anything."
        vector={cloudContact}
        subject="Cloud Services"
      />
      <Footer />
    </div>
  );
}
export default Cloud;
