import { Link } from "react-router-dom";
import vector from "../../images/vectors/home-vector.svg";
import { contact } from "../../utils/contact";

const Info: React.FC = () => {
  return (
    <div className="w-full h-auto lg:h-[476px] bg-background bg-cover bg-center">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-24">
          <div className="w-auto md:w-[540px] flex flex-col gap-2">
            <h2 className="text-lightGray text-3xl lg:text-4xl font-extrabold uppercase leading-[56px] drop-shadow-blue">
              Contact Info
            </h2>

            <p className="text-justify text-lightGray/75 text-lg font-normal leading-normal">
              Need a helping hand with your IT needs? Reach out to our trusty
              team in any way you like:
            </p>

            <div className="text-white text-lg font-bold capitalize">
              Give us a buzz (24/7 Support):
              <ul className="mt-2 space-y-2">
                {contact.phone.map((item, index) => (
                  <li key={index} className="text-sm">
                    <div>
                      <div className="flex justify-start">
                        <img
                          src={item.icon}
                          alt="icon"
                          className="w-6 h-6 mt-1 mr-2"
                        />
                        <span className="text-lightGray/75 text-lg font-light hover:text-gray-200 underline">
                          {item.content}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <p className="text-white text-lg font-bold capitalize">
                Shoot us an email:
              </p>
              <ul className="mt-2">
                {contact.email.map((item, index) => (
                  <li key={index} className="text-sm">
                    <div className="text-lightGray/75 text-lg font-light hover:text-gray-200 underline">
                      <div className="flex justify-start">
                        <img
                          src={item.icon}
                          alt="icon"
                          className="w-6 h-6 mt-1 mr-2"
                        />
                        <span> {item.content}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <p className="text-white text-lg font-bold capitalize">
                Connect on social:
              </p>
              <ul className="w-80 grid grid-cols-3">
                {contact.soical.map((item, index) => (
                  <li key={index} className="text-sm">
                    <div className="flex flex-row gap-2">
                      <div className="flex">
                        <Link to={item.link}>
                          <img
                            src={item.icon}
                            alt="icon"
                            className="w-5 h-5 mt-2 mr-2"
                          />
                        </Link>
                        <Link
                          to={item.link}
                          className="text-lightGray/75 text-lg font-light hover:text-gray-200 underline"
                        >
                          {item.description}
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <img
            className="w-auto md:w-[320px] xl:w-[520px] transition ease-out delay-150 hover:translate-x-4 hover:scale-110  duration-300"
            src={vector}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default Info;
