export const switches = {
  S2982G: [
    {
      number: "1",
      title: "SNR-S2982G-24T-POE",
      description: "Managed Ethernet switch with PoE support.",
    },
    {
      number: "2",
      title: "SNR-S2982G-24TE",
      description: "Managed Ethernet switch with advanced management features.",
    },
    {
      number: "3",
      title: "SNR-S2982G-8T",
      description: "Compact managed Ethernet switch for smaller setups.",
    },
  ],

  S2985G: [
    {
      number: "1",
      title: "SNR-S2985G-48T",
      description: "High-port-density managed Ethernet switch.",
    },
    {
      number: "2",

      title: "SNR-S2985G-8T-POE",
      description: "Compact switch with PoE functionality.",
    },
  ],

  S2989G: [
    {
      number: "1",
      title: "SNR-S2989G-24TX",
      description: "Managed switch offering robust connectivity.",
    },
  ],

  S2995G: [
    {
      number: "1",
      title: "SNR-S2995G-12FX",
      description: "Managed Ethernet switch with fiber ports.",
    },
    {
      number: "2",
      title: "SNR-S2995G-24FX",
      description: "24-port fiber Ethernet switch.",
    },
    {
      number: "3",
      title: "SNR-S2995G-24TX",
      description: "High-performance Ethernet switch with 24 ports.",
    },
    {
      number: "4",
      title: "SNR-S2995G-24TX-POE",
      description: "Switch with 24 ports and PoE support.",
    },
    {
      number: "5",
      title: "SNR-S2995G-48TX-POE",
      description: "48-port switch with extensive PoE functionality.",
    },
  ],

  S300X: [
    {
      number: "1",
      title: "SNR-S300X-24FQ",
      description: "Advanced managed Ethernet switch with 24 fiber ports.",
    },
  ],
  S5210X: [
    {
      number: "1",
      title: "SNR-S5210X-8F",
      description: "Layer 2+ managed switch for high-performance networking.",
    },
  ],
};
