//offers
import DigitalTrans from "../images/vectors/Consultant/Digital-Transformation.svg";
import smartCity from "../images/vectors/Consultant/Smart-City.svg";
import eGov from "../images/vectors/Consultant/Prototyping.svg";
import dataWarehouse from "../images/vectors/Consultant/Data-Warehouse.svg";
import dataAnalytic from "../images/vectors/Consultant/Data-Reports.svg";
import dataModel from "../images/vectors/Consultant/Data-Points.svg";

//Uses
import sealCheck from "../images/grayIcons/SealCheck.svg";
import chartDonut from "../images/grayIcons/ChartDonut.svg";
import handshake from "../images/grayIcons/Handshake.svg";
import chartBar from "../images/grayIcons/ChartBar.svg";
import chartLineUp from "../images/grayIcons/ChartLineUp.svg";
import trophy from "../images/grayIcons/Trophy.svg";

export const consultancy = {
  offers: [
    {
      number: "1",
      vector: DigitalTrans,
      title: "Digital Transformation Strategies",
      description:
        "Our consultants work closely with you to develop a customized digital transformation strategy that aligns with your unique business goals. We help you leverage cutting-edge technologies to streamline operations, enhance customer experiences, and unlock new opportunities for growth.",
    },
    {
      number: "2",
      vector: smartCity,
      title: "Smart City Data Planning",
      description:
        "As cities become smarter, data plays a critical role in optimizing urban infrastructure, services, and citizen well-being. We assist in developing data-driven strategies for smart city initiatives, ensuring efficient data collection, analysis, and utilization.",
    },
    {
      number: "3",
      vector: eGov,
      title: "E-Gov Services Development",
      description:
        "Embrace the future of governance with our e-Government (E-Gov) services development expertise. We help streamline government processes, improve citizen engagement, and enhance service delivery through the strategic implementation of E-Gov solutions.",
    },
    {
      number: "4",
      vector: dataWarehouse,
      title: "Big Data & Data Warehousing",
      description:
        "Unlock the potential of your data with our Big Data and data warehousing solutions. We help you collect, store, organize, and analyze vast amounts of data, empowering you to gain valuable insights and make data-driven decisions.",
    },
    {
      number: "5",
      vector: dataAnalytic,
      title: "Data Analytics & Visualization",
      description:
        "Transform raw data into actionable insights with our data analytics and visualization expertise. Our team utilizes advanced analytics tools to uncover hidden patterns, trends, and relationships within your data, translating complex information into clear and compelling visualizations for informed decision-making.",
    },
    {
      number: "6",
      vector: dataModel,
      title: "Data Modeling & Architecture",
      description:
        "Create a solid foundation for your data initiatives with our data modeling and architecture services. We design efficient data models and robust data architectures that ensure the scalability, security, and accessibility of your data assets.",
    },
  ],

  benefits: [
    {
      icon: sealCheck,
      title: "Experienced & Certified Consultants",
      description:
        "Our team comprises highly skilled and certified consultants with extensive experience across various industries. We stay at the forefront of data-driven trends and technologies, ensuring we offer the most innovative and effective solutions for your specific business challenges.",
    },
    {
      icon: chartDonut,
      title: "Data-Driven Approach",
      description:
        "We don't just collect data – we use it to drive real results. Our data-driven approach ensures that every recommendation and strategy is grounded in fact and delivers tangible outcomes that contribute to your overall business objectives.",
    },
    {
      icon: handshake,
      title: "Collaborative Partnership",
      description:
        "We believe in a collaborative approach. We take the time to understand your unique business goals, challenges, and existing infrastructure. Through open communication and close collaboration, we develop customized solutions that seamlessly integrate with your existing processes.",
    },
    {
      icon: chartBar,
      title: "Scalable & Sustainable Solutions",
      description:
        "We understand that your business needs are constantly evolving. Our solutions are designed to grow alongside your organization. We prioritize scalability and sustainability, ensuring your data initiatives remain effective and adaptable in the long run.",
    },
    {
      icon: chartLineUp,
      title: "Measurable Results",
      description:
        "We are committed to delivering measurable results. We track key performance indicators (KPIs) aligned with your specific goals, providing ongoing performance reports and data insights to demonstrate the impact of our consultancy services on your business success.",
    },
    {
      icon: trophy,
      title: "Proven Track Record",
      description:
        "We have a proven track record of success in helping businesses across various industries achieve their data-driven goals. Our portfolio showcases real-world examples of how we've partnered with clients to unlock the power of data and achieve significant results.",
    },
  ],
};
