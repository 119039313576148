import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import List from "../../components/Sections/List";
import Items from "../../components/Sections/TwoItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Hosting-Header.svg";
import contact from "../../images/vectors/Server.svg";
import { hosting } from "../../utils/hosting";
import { logos } from "../../utils/logos";

function Hosting() {
  return (
    <div>
      <Menu />

      <Header
        title="Craft Your Digital Legacy:"
        subTitle="Powerful Hosting & Domain"
        miniTitle="GBT Services"
        description="Your Success is Our Priority:  We're passionate about helping businesses flourish online. GBT offers top-tier hosting, unforgettable domain names, and exceptional support – all designed to empower your website and fuel your online journey."
        vector={header}
      />

      <GradientContainer
        className="h-[357px] md:h-[257px]"
        title="Building Your Online Presence with gbt"
        content="In today's digital age, a strong online presence is essential for any business or individual looking to thrive. Here at GBT, we understand the importance of crafting a website that reflects your brand and captures your audience's attention."
        description="We offer a variety of tools and services to help you build and maintain a successful online presence:"
      />
      <Items content={hosting.tools} />

      <List
        title="Go beyond"
        subTitle="hosting"
        description="While hosting is the foundation of a successful website, GBT offers a comprehensive suite of additional services to elevate your online presence and take your website to the next level:"
        content={hosting.services}
      />

      <GradientContainer
        className="h-[307px] md:h-[207px]"
        title="Why Choose GBT Hosting & Domains?"
        content="At GBT, we believe your website deserves the best. We provide the tools and expertise to craft a remarkable online presence, starting with the perfect domain name and a secure, high-performance hosting foundation"
      />

      <GridItems content={hosting.benefits} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.customers}
      />

      <ContactUs
        title="Your Success is"
        subTitle="Our Priority"
        description="At GBT, we're dedicated to helping you build a successful online presence. We offer a comprehensive suite of services and resources to empower you every step of the way.  Contact us today to help you craft a website that truly represents you."
        vector={contact}
        subject="Hosting & Domain Services"
      />

      <Footer />
    </div>
  );
}
export default Hosting;
