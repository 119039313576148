import { useEffect } from "react";
import Menu from "../components/Common/Menu";
import Header from "../components/Common/Header";
import Questions from "../components/Contact/FAQ";
import Footer from "../components/Common/Footer";
import ContactForm from "../components/Contact/ContactForm";
import Info from "../components/Contact/Info";
import Address from "../components/Contact/Address";

function Contact() {
  const subject = new URLSearchParams(window.location.search).get("subject");

  useEffect(() => {
    const urlHash = window.location.hash;
    console.log("🚀 ~ useEffect ~ urlHash:", urlHash);

    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1));
      console.log("🚀 ~ useEffect ~ element:", element);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  });

  return (
    <div>
      <Menu />

      <Header
        title="Get in Touch"
        subTitle="We're Here to Help"
        miniTitle="GBT Help Center"
        description=" Welcome to the contact and help page. We are happy to offer you
        a variety of ways to help on this page, which includes
        information to contact us, an instant contact form, information
        about our headquarters, as well as frequently asked questions."
      />

      <Info />

      <ContactForm id="contactForm" pageSubject={subject} />

      <Address />

      <Questions />

      <Footer />
    </div>
  );
}
export default Contact;
