import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import Services from "../../components/Sections/List";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/WebApp-Header.svg";
import contact from "../../images/vectors/WebApp-Contact.svg";
import { webApp } from "../../utils/webApp";
import { logos } from "../../utils/logos";

function WebApp() {
  return (
    <div>
      <Menu />

      <Header
        title="Craft the Perfect Web Application"
        subTitle="for Your Business Needs"
        miniTitle="GBT Services"
        description="Web apps are changing how businesses work! At GBT, we help you build custom web apps that fit your needs perfectly. Our team of experts creates user-friendly and innovative apps to bring your vision to life."
        vector={header}
      />

      <GradientContainer
        className="h-[332px] md:h-[232px]"
        title="What we offer to you?"
        content="GBT offers a complete web app development solution. We work closely with you to understand your needs, then leverage our expert developers and cutting-edge technologies to craft user-friendly and feature-rich applications. We seamlessly integrate APIs, design secure databases, and ensure your web app is deployed, secure, and maintained for ongoing success."
      />

      <Services content={webApp.offers} />

      <GradientContainer
        className="h-[357px] md:h-[257px]"
        title="Why Choose GBT Web App Development?"
        content="In today's increasingly competitive digital environment, a well-crafted web application can be the cornerstone of your online success. But navigating the complexities of web development can be daunting. At GBT, we understand your challenges."
        description="Here's what sets us apart and why you should choose GBT as your trusted web app development partner:"
      />

      <GridItems content={webApp.reasons} />

      <Clients
        title="the Projects we build"
        description="This section highlights a selection of successful projects we've completed in partnership with our valued clients.  Each project showcases our expertise in crafting web solutions that drive results.  Get inspired by the diverse range of projects we tackle and see how we can help you achieve your online goals."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to Take Your"
        subTitle="Business to the Next Level?"
        description="At GBT, we're dedicated to helping you build a successful online presence. We offer a comprehensive suite of services and resources to empower you every step of the way.  Contact us today to help you craft a website that truly represents you."
        vector={contact}
        subject="Web Application Development Services"
      />

      <Footer />
    </div>
  );
}
export default WebApp;
