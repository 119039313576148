import React from "react";
import { Link } from "react-router-dom";
import ParticlesBG from "./ParticlesBG";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectFade } from "swiper";
import CaretDoubleRight from "../../images/icons/caret-double-right-blue.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/swiper-bundle.min.css";
import "../../css/custom-swiper-bullet.css";

const Carousel: React.FC<any> = ({ slides }: { slides: any }) => {
  return (
    <>
      <div className="relative">
        <ParticlesBG
          id="tsparticles-carousel"
          className="h-[600px] md:h-[400px] lg:h-[700px] xl:h-[800px] bg-gradient-to-r from-primary to-secondary"
        />
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Swiper
            modules={[Autoplay, Navigation, EffectFade]}
            slidesPerView={1}
            spaceBetween={30}
            centeredSlides={true}
            fadeEffect={{ crossFade: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            effect="fade"
            speed={2500}
          >
            {slides.map((slide: any, index: number) => (
              <SwiperSlide key={index}>
                <div className="h-full hidden lg:flex justify-center items-center mt-px xl:mt-24">
                  <div className="flex justify-between items-center gap-24">
                    <div className="flex flex-col">
                      <div className="text-lightGray w-[400px] xl:w-[580px] text-3xl xl:text-5xl font-bold uppercase leading-[48px] xl:leading-[58px] drop-shadow-blackSm">
                        {slide.title}
                      </div>
                      <div className="text-primary text-base font-bold uppercase drop-shadow-blackSm">
                        GBT Services
                      </div>
                      <p className="text-lightGray/75 w-[400px] xl:w-[540px] text-lg font-normal text-justify mt-2 drop-shadow-blackXs">
                        {slide.description}
                      </p>

                      <Link
                        to={slide.link}
                        className="w-[400px] xl:w-[540px] mt-4 px-6 py-4 bg-sky hover:bg-white rounded-md shadow flex justify-center items-center"
                      >
                        <span className="text-darkGray text-base font-semibold uppercase">
                          learn more
                        </span>
                        <span className="relative">
                          <img
                            src={CaretDoubleRight}
                            alt="CaretDoubleRight"
                            className="w-4 h-4 ml-2"
                          />
                        </span>
                      </Link>
                    </div>

                    <img
                      src={slide.image}
                      alt={slide.title}
                      className="w-full md:w-[300px] lg:w-[480px]"
                    />
                  </div>
                </div>

                {/* Small screen */}
                <div className="block lg:hidden">
                  <div className="w-full flex justify-center items-center">
                    <div className="w-[290px] md:w-[500px] flex flex-col mt-8">
                      <div className="text-lightGray text-2xl font-bold uppercase leading-tight drop-shadow-blackSm">
                        {slide.title}
                      </div>
                      <div className="text-primary text-sm font-bold uppercase mt-2">
                        GBT Services
                      </div>
                      <p className="text-lightGray/75 text-base font-normal text-justify mt-2 p-2 drop-shadow-blackXs">
                        {slide.description}
                      </p>

                      <Link
                        to={slide.link}
                        className="my-4 p-2 bg-sky hover:bg-white rounded-md shadow flex justify-center items-center"
                      >
                        <span className="text-darkGray text-xs font-semibold uppercase">
                          learn more
                        </span>
                        <div className="relative">
                          <img
                            src={CaretDoubleRight}
                            alt="CaretDoubleRight"
                            className="w-4 h-4 ml-2"
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default Carousel;
