import { Link } from "react-router-dom";

const Offers: React.FC<any> = ({
  className,
  data,
  title,
  subTitle,
  itemBgColor,
  description,
  descriptionHeight,
  intro,
}: {
  className: string;
  data: any;
  title: string;
  itemBgColor: string;
  subTitle: string;
  description: string;
  descriptionHeight: string;
  intro: string;
}) => {
  return (
    <div className={`w-full cursor-default ${className}`}>
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col">
          {title && (
            <div className="flex flex-col mb-4">
              <div className="text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlueSm">
                <span className="text-primary">{title}</span>{" "}
                <span className="text-dark">{subTitle}</span>
              </div>

              <div className="text-justify text-dark/50 text-base lg:text-lg mt-4">
                <span className="leading-tight font-normal">{description}</span>
                <br />
                <span className="font-bold leading-loose">{intro}</span>
              </div>
            </div>
          )}

          <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-10">
            {data.map((item: any, index: number) => (
              <li
                key={index}
                className={`bg-${itemBgColor} h-auto p-6 rounded-tr-xl rounded-bl-xl drop-shadow-darkBlue transition ease-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300 ${
                  index < data.length - 1
                    ? "col-span-1"
                    : index === data.length - 1 && data.length % 3 === 0
                    ? "md:col-span-3 xl:col-span-1"
                    : index === data.length - 1 && data.length % 3 === 1
                    ? "md:col-span-2 xl:col-span-3"
                    : index === data.length - 1 && data.length % 3 === 2
                    ? "md:col-span-1 xl:col-span-2"
                    : ""
                }`}
              >
                <div className="w-7 -ml-8 -mt-8 bg-primary rounded-t-md rounded-bl-md flex justify-center items-center">
                  <div className="text-lightGray text-lg lg:text-xl font-semibold">
                    {item.number}
                  </div>
                </div>

                <div className="w-full flex flex-row items-center gap-4">
                  <div className="flex justify-center items-center w-20 h-20 bg-primary/25 hover:bg-primary/35 rounded-t-xl rounded-bl-xl">
                    <img
                      src={item.icon}
                      alt=""
                      className="w-12 h-12 brightness-100 hover:brightness-125"
                    />
                  </div>
                  <div className="w-2/3 text-dark text-lg lg:text-xl font-bold capitalize leading-relaxed">
                    {item.title}
                  </div>
                </div>

                <div
                  className={`text-justify text-black/50 text-sm lg:text-base font-normal leading-6 my-4 ${descriptionHeight}`}
                >
                  {item.description}
                </div>

                {item.link && (
                  <Link
                    to={item.link}
                    className=" w-full py-3 bg-gradient-to-r from-primary to-secondary rounded-md shadow flex justify-center items-center brightness-90 hover:brightness-100"
                  >
                    <span className="text-center text-lightGray hover:text-white text-sm font-semibold uppercase">
                      Learn More
                    </span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Offers;
