const Card: React.FC<any> = ({
  bgColor,
  image,
  title,
  description,
  link,
}: {
  bgColor: any;
  image: string;
  title: string;
  description: string;
  link: string;
}) => {
  return (
    <div
      className={`mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12 cursor-default ${bgColor}`}
    >
      <div className="flex flex-col">
        <div className="flex justify-center">
          <img src={image} alt="icon" className="w-[200px] h-[63.29px]" />
        </div>

        <div className="flex justify-center items-center h-auto lg:h-56 mb-4">
          <p className="text-justify text-sm lg:text-base text-dark/50 leading-5">
            <span className="font-bold">{title}</span>{" "}
            <span className="font-normal">{description}</span>
          </p>
        </div>

        <div className="p-0.5 bg-gradient-to-r from-primary to-secondary rounded-t-md rounded-bl-md">
          <div className={`${bgColor} rounded-t-md rounded-bl-md py-3`}>
            <a href={link} className="flex justify-center">
              <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-xs lg:text-sm font-bold leading-4 uppercase">
                Learn More
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
