import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Solutions from "../../components/Sections/List";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/CMS-Header.svg";
import contact from "../../images/vectors/CMS-Contact.svg";
import { cms } from "../../utils/cms";
import { logos } from "../../utils/logos";

function CMS() {
  return (
    <div>
      <Menu />

      <Header
        title="Unleash Your Content's Power:"
        subTitle="CMS Development Solutions from gbt"
        miniTitle="GBT Solutions"
        description="Creating and managing high-quality content can be a challenge. That's where a robust Content Management System (CMS) comes in.  A user-friendly CMS empowers you to take control of your website's content, streamline workflows, and deliver exceptional experiences for your audience."
        vector={header}
      />

      <GradientContainer
        className="h-[431px] md:h-[331px]"
        title="how GBT empowers your content management success"
        content="GBT is a leading CMS development agency dedicated to empowering businesses of all sizes with user-friendly and feature-rich CMS solutions. We understand your unique content management needs and craft custom-built CMS platforms that streamline your content creation, editing, and publishing processes."
        description=" Here's how GBT empowers your content management success:"
      />

      <Solutions content={cms.offers} />

      <GradientContainer
        className="h-[410px] md:h-[310px]"
        title="Why Choose GBT for Your Content management Development Needs?"
        content="In today's digital landscape, a user-friendly and feature-rich CMS is no longer a luxury, it's a necessity. But with numerous CMS development agencies available, why choose GBT?"
        description="Here's what sets us apart:"
      />

      <GridItems content={cms.reasons} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to take your"
        subTitle="online content to the next level?"
        description="Contact GBT today for a free consultation! Let's discuss your specific needs and craft a customized CMS development solution that simplifies content creation, streamlines workflows, and fuels your content marketing success."
        vector={contact}
        subject="Content Management Systems (CMS) Development Solutions"
      />

      <Footer />
    </div>
  );
}
export default CMS;
