//offers
import Storefront from "../images/gradientIcons/Storefront.svg";
import PaintBrush from "../images/gradientIcons/PaintBrush.svg";
import DeviceMobile from "../images/gradientIcons/DeviceMobile.svg";
import Code from "../images/gradientIcons/Code.svg";
import CreditCard from "../images/gradientIcons/CreditCard.svg";
import Rocket from "../images/gradientIcons/Rocket.svg";
import Projector from "../images/gradientIcons/ProjectorScreenChart.svg";
import Lifebuoy from "../images/gradientIcons/Lifebuoy.svg";

//reasons
import users from "../images/grayIcons/UsersFour.svg";
import userFocus from "../images/grayIcons/UserFocus.svg";
import chartBar from "../images/grayIcons/ChartBar.svg";
import chartDonut from "../images/grayIcons/ChartDonut.svg";
import circuitry from "../images/grayIcons/Circuitry.svg";
import headset from "../images/grayIcons/Headset.svg";

export const ecommerce = {
  offers: [
    {
      number: "1",
      icon: Storefront,
      title: "E-Commerce Platform Selection & implementation",
      description:
        "GBT's e-commerce specialists collaborate with you to understand your business needs, target audience, and budget. They'll recommend the best platform (Magento, Shopify, WooCommerce, BigCommerce) to meet your requirements and handle the entire implementation process.",
    },
    {
      number: "2",
      icon: PaintBrush,
      title: "Custom Design & User Experience (UX) Optimization",
      description:
        "We believe a visually appealing and user-friendly online store is paramount for driving sales and conversions. Our design team creates a captivating storefront that reflects your brand identity while prioritizing user experience through intuitive navigation, clear product information, and a seamless checkout process.",
    },
    {
      number: "3",
      icon: DeviceMobile,
      title: "Mobile Responsiveness & Optimization",
      description:
        "In today's mobile-first world, it's crucial for your online store to be accessible and provide a smooth experience on all devices. Our developers ensure your store is fully responsive and optimized for various screen sizes.",
    },
    {
      number: "4",
      icon: Code,
      title: "E-Commerce Development & Customization",
      description:
        "GBT's developers will customize your e-commerce platform to match your specific needs. This may involve developing custom features, functionalities, and integrations to enhance your store's capabilities and give you a competitive edge.",
    },
    {
      number: "5",
      icon: CreditCard,
      title: "Payment Gateway Integration",
      description:
        "A secure and hassle-free checkout process is essential.  We integrate trusted and secure payment gateways to provide your customers with a variety of convenient payment methods at checkout, such as credit cards, PayPal, Stripe and digital wallets.",
    },
    {
      number: "6",
      icon: Rocket,
      title: "Marketing & SEO Optimization",
      description:
        "Attract, Engage, and Retain Customers: We employ a comprehensive marketing strategy to attract new customers through SEO and targeted PPC campaigns. We then nurture leads with personalized email marketing and optimize your store based on customer behavior data to drive sales and repeat business.",
    },
    {
      number: "7",
      icon: Projector,
      title: "Analytics & Reporting",
      description:
        "We provide comprehensive analytics and reporting dashboards to help you visualize key performance indicators (KPIs) such as sales figures, traffic sources, customer demographics, and product performance. These insights empower you to make data-driven decisions to optimize your online store and marketing strategies.",
    },
    {
      number: "8",
      icon: Lifebuoy,
      title: "Ongoing Support & Maintenance",
      description:
        "We understand that your e-commerce platform requires ongoing maintenance and support. Our team provides comprehensive support plans to ensure your online store stays up-to-date, secure, and functioning smoothly.",
    },
  ],

  reasons: [
    {
      icon: users,
      title: "Experienced & Skilled Developers",
      description:
        "We possess a wealth of experience in e-commerce platform development and customization. Our team has a proven track record of crafting successful online stores across diverse industries, ensuring we bring the necessary knowledge and expertise to tackle your unique business challenges.",
    },
    {
      icon: userFocus,
      title: "Focus on User Experience (UX)",
      description:
        "Our UX team designs intuitive navigation for easy product discovery, clear product information for informed decisions, and a streamlined checkout process to minimize cart abandonment. This focus on user experience not only benefits immediate sales but also fosters customer loyalty and repeat business.",
    },
    {
      icon: chartBar,
      title: "Future-Proof Solutions",
      description:
        "Our development solutions are designed to adapt and grow alongside your expanding business needs. Whether you're a startup launching your first online store or a seasoned retailer looking to scale your online presence, we have the expertise to create a platform that can accommodate your evolving requirements.",
    },
    {
      icon: chartDonut,
      title: "Data-Driven Approach",
      description:
        "We leverage data to optimize your e-commerce platform and maximize your ROI. We track user behavior, website traffic, and key performance indicators (KPIs) to make data-driven decisions about platform optimization, marketing strategies, and customer experience, ensuring your online store delivers the results you desire.",
    },
    {
      icon: circuitry,
      title: "Cutting-Edge Technology Integration",
      description:
        "We stay ahead of the curve by incorporating the latest e-commerce technologies. This includes features like AI-powered product recommendations, augmented reality (AR) product visualization, chatbots or live chat support and much more, all with the aim of improving customer experience and boosting conversions.",
    },
    {
      icon: headset,
      title: "Dedicated Customer Support",
      description:
        "We're your partner in e-commerce success.  From initial consultation and platform selection to ongoing support and maintenance, our dedicated team is committed to providing you with exceptional support, clear communication, and a collaborative approach to ensure your online store reflects your vision and exceeds your expectations.",
    },
  ],
};
