import { Link } from "react-router-dom";

const ContactUs: React.FC<any> = ({
  title,
  subTitle,
  description,
  vector,
  subject,
}: {
  title: string;
  subTitle: string;
  description: string;
  vector: any;
  subject: string;
}) => {
  return (
    <div className="w-full bg-lightGray cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="w-full flex flex-col-reverse md:flex-row md:items-center gap-8 xl:gap-32">
          <div className="w-full lg:w-[520px]">
            <div className="font-extrabold uppercase leading-8 lg:leading-[48.76px] drop-shadow-lightBlue">
              <div className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-3xl lg:text-4xl">
                {title}
              </div>
              <div className="text-dark text-2xl lg:text-4xl">{subTitle}</div>
            </div>
            <p className="text-dark/50 text-sm lg:text-base text-justify font-normal my-4">
              {description}
            </p>

            <Link
              to={`/contact?subject=${subject}#contactForm`}
              className="w-full lg:w-[520px] h-[54px] my-4 p-4 bg-gradient-to-r from-dark to-secondary rounded-t-md rounded-bl-md drop-shadow-blackSm flex justify-center items-center brightness-90 hover:brightness-100"
            >
              <span className="text-lightGray hover:text-white text-base lg:text-lg font-bold uppercase">
                Contact Us Now!
              </span>
            </Link>
          </div>

          <img
            className="w-auto md:w-[340px] xl:w-[540px] transition ease-out delay-150 hover:translate-x-5 hover:scale-110  duration-300"
            src={vector}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
