import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoSlider: React.FC<any> = ({ slides }: { slides: any }) => {
  const settings = {
    className: "w-auto",
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",

    // pauseOnHover: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed: 500,
          autoplaySpeed: 2000,
          pauseOnHover: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          speed: 500,
          autoplaySpeed: 2000,
          pauseOnHover: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          speed: 500,
          autoplaySpeed: 2000,
          pauseOnHover: false,
        },
      },
    ],
  };

  return (
    <div className="w-full cursor-default pt-12">
      <div className="mx-auto max-w-7xl px-6 sm:px-8 lg:px-10">
        <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-3xl lg:text-4xl font-extrabold drop-shadow-lightBlue uppercase">
          Our Partners
        </span>
        <p className="w-full text-dark/50 text-base lg:text-lg text-justify font-normal leading-6 my-6">
          At GBT Solutions, we don't see limitations, we see potential. Our
          partners are as diverse as the tech landscape itself, spanning from
          budding startups to established giants. Each story, each challenge,
          fuels our passion to craft solutions that empower, regardless of your
          size, industry, or ambition.
        </p>
      </div>
      <div className="overflow-hidden">
        <Slider {...settings}>
          {slides.map((item: any, index: number) => (
            <div
              key={index}
              className={`h-auto px-10 py-4 xl:py-8 ${
                index % 2 === 0 ? "bg-sky" : "bg-gray"
              }`}
            >
              <div className="p-4 flex justify-center items-center">
                <img src={item.logo} alt="LOGO" />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default LogoSlider;
