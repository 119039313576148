//Services
import services from "../images/vectors/DataCenter/Images/DataCenter.svg";
import network from "../images/vectors/DataCenter/Images/Network.svg";
import telecom from "../images/vectors/DataCenter/Images/Telecom.svg";

//Data Center Services
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";
import Wrench from "../images/gradientIcons/Wrench.svg";
import Planet from "../images/gradientIcons/Planet.svg";
import CirclesThreePlus from "../images/gradientIcons/CirclesThreePlus.svg";

//IT pn
import Graph from "../images/gradientIcons/Graph.svg";
import PlugsConnected from "../images/gradientIcons/PlugsConnected.svg";
import Headset from "../images/gradientIcons/Headset.svg";
import Link from "../images/gradientIcons/Link.svg";
import WifiHigh from "../images/gradientIcons/WifiHigh.svg";

//Telecom png
import CellSignalFull from "../images/gradientIcons/CellSignalFull.svg";
import Handshake from "../images/gradientIcons/Handshake.svg";
import ShieldCheck from "../images/gradientIcons/ShieldCheck.svg";
import ShieldCheckered from "../images/gradientIcons/ShieldCheckered.svg";
import CloudCheck from "../images/gradientIcons/CloudCheck.svg";

//benefits
import certificate from "../images/grayIcons/Certificate.svg";
import sliders from "../images/grayIcons/Sliders.svg";
import infinity from "../images/grayIcons/Infinity.svg";
import shieldCheck from "../images/grayIcons/ShieldCheck.svg";
import headset from "../images/grayIcons/Headset.svg";
import piggy from "../images/grayIcons/PiggyBank.svg";

export const dataCenter = {
  services: [
    {
      image: services,
      title: "Data Center Services",
      description:
        "Data centers are vital for modern businesses, storing critical applications, data, and enabling employee productivity.  However, building and maintaining a reliable, secure, and efficient data center can be a complex.",
      buttonDesc: "learn more",
      link: "#dataCenterService",
    },
    {
      image: network,
      title: "IT Network Services",
      description:
        "Reliable and secure IT networks are essential for businesses today. They allow employees to collaborate, access applications, and be productive. However, managing complex network infrastructure can be difficult.",
      buttonDesc: "learn more",
      link: "#itService",
    },
    {
      image: telecom,
      title: "Telecom Network Services",
      description:
        "Businesses need reliable and affordable telecom networks to connect with everyone.  However, choosing the right telecom provider, service, and network design can be complex.",
      buttonDesc: "learn more",
      link: "#telecomServices",
    },
  ],
  dataCenterServices: [
    {
      number: "1",
      icon: Lightbulb,
      title: "Data Center Design and Consulting",
      description:
        "Our experienced data center consultants work closely with you to understand your specific business needs, growth plans, and budget constraints. We take into account factors like power and cooling requirements, security needs, and physical layout optimization. Through this collaboration, we design a customized data center solution that aligns with your unique requirements and maximizes space utilization.",
      buttonDesc: "learn more",
    },
    {
      number: "2",
      icon: Wrench,
      title: "Data Center Construction and Fit-Out",
      description:
        "GBT has the expertise and experience to handle all aspects of data center construction and fit-out, from the ground up. Our team manages the entire process, ensuring your data center is built to meet the highest standards for reliability, security, and efficiency. This includes electrical and mechanical infrastructure installation, cabling, and racking, all executed with strict adherence to safety protocols.",
    },
    {
      number: "3",
      icon: Planet,
      title: "Data Center Migration and Relocation",
      description:
        "Moving your data center can be a complex and disruptive process. We take the burden off your shoulders. Our team develops a comprehensive migration plan to ensure a smooth and efficient transition with minimal downtime. This includes data migration, cutover activities, and post-migration support to guarantee a successful relocation.",
    },
    {
      number: "4",
      icon: CirclesThreePlus,
      title: "Data Center Colocation",
      description:
        "Not everyone requires a dedicated data center facility. If you're looking for a cost-effective and scalable solution, consider our colocation services. We offer secure, high-performance colocation facilities that provide the space, power, cooling, and network connectivity you need to house your IT infrastructure. This allows you to focus on your core business while leaving the data center management to us.",
    },
    {
      number: "5",
      icon: ShieldCheck,
      title: "Data Center Management Services",
      description:
        "Focus on your core business priorities while we take care of your day-to-day data center operations. GBT offers comprehensive data center management services, including 24/7 monitoring, preventative maintenance, incident response, and power and cooling management. Our certified technicians proactively address potential issues and ensure your data center runs smoothly and efficiently.",
    },
  ],

  itServices: [
    {
      number: "1",
      icon: Graph,
      title: "Network Design & Consulting",
      description:
        "Our network design consultants are experts in analyzing your current network usage patterns, bandwidth requirements, and future growth plans. We work closely with you to understand your specific business needs and security concerns. Through this collaboration, we design a scalable and secure network infrastructure that meets your unique requirements and ensures optimal user access control.",
    },
    {
      number: "2",
      icon: PlugsConnected,
      title: "Network Installation & Cabling",
      description:
        "Our experienced network engineers handle all aspects of network installation and cabling, ensuring reliable and secure network connectivity throughout your organization. We utilize high-quality cabling materials and adhere to industry standards for network infrastructure installation. This guarantees a robust and efficient network foundation for your IT environment.",
    },
    {
      number: "3",
      icon: Headset,
      title: "Network Management & Support",
      description:
        "Maintaining a healthy and secure network requires ongoing monitoring and management. Our team offers a comprehensive suite of network management and support services, including 24/7 proactive monitoring. We constantly monitor your network for performance issues, security threats, and potential bottlenecks. Our team also provides performance optimization, troubleshooting services, and is always available to address any network issues that may arise, ensuring your network operates at peak performance.",
    },
    {
      number: "4",
      icon: ShieldCheckered,
      title: "Network Security Solutions",
      description:
        "Cybersecurity threats are constantly evolving. Our team stays up-to-date on the latest threats and vulnerabilities. We offer a range of network security solutions, including firewalls, intrusion detection and prevention systems (IDS/IPS), and data encryption, to safeguard your network and data from cyberattacks. We help you implement a layered security approach to protect your critical assets and ensure business continuity.",
    },
    {
      number: "5",
      icon: WifiHigh,
      title: "Wireless Network Solutions",
      description:
        "Enable seamless connectivity for your users with our wireless network solutions. We design, install, and manage secure and reliable wireless networks, ensuring your employees and guests have access to the network from anywhere within your facility. This empowers a more mobile workforce and fosters collaboration.",
    },
  ],

  telecomServices: [
    {
      number: "1",
      icon: CellSignalFull,
      title: "Telecom Network Design & Consulting",
      description:
        "Our telecom network consultants are experts in analyzing your voice and data communication requirements, call volume patterns, and geographic distribution of your locations. We consider factors like bandwidth requirements, budget constraints, and available carrier options. Through this collaboration, we design an optimized telecom network that ensures clear communication channels and minimizes costs.",
    },
    {
      number: "2",
      icon: Handshake,
      title: "Telecom Carrier Services",
      description:
        "GBT has established relationships with major telecommunications carriers. We leverage this network to negotiate the best possible deals on voice and data services, ensuring you get the right features and bandwidth at the most competitive rates. We handle the entire procurement process, saving you time and resources.",
    },
    {
      number: "3",
      icon: ShieldCheck,
      title: "MPLS Network Solutions",
      description:
        "Multiprotocol Label Switching (MPLS) offers a reliable and secure way to connect geographically dispersed locations. Our team designs, implements, and manages MPLS networks, providing high-performance connectivity for your voice and data traffic across your entire organization. This ensures seamless communication and collaboration between your remote offices and headquarters.",
    },
    {
      number: "4",
      icon: Link,
      title: "Unified Communications (UC) Solutions",
      description:
        "Improve collaboration and communication within your organization with our Unified Communications (UC) solutions. We integrate voice, video, data, and instant messaging into a single platform, fostering seamless communication and collaboration between your employees, regardless of their location. This empowers a more productive and efficient workforce.",
    },
    {
      number: "5",
      icon: CloudCheck,
      title: "Cloud Connectivity Solutions",
      description:
        "Leverage the scalability and agility of cloud computing with our cloud connectivity solutions. We design and implement secure and reliable connections between your on-premises network and cloud environments, enabling you to seamlessly integrate cloud services into your IT infrastructure. This allows you to access critical applications and data from anywhere, anytime.",
    },
  ],

  benefits: [
    {
      icon: certificate,
      title: "Expertise & Experience",
      description:
        "Our team of certified engineers possesses extensive experience in designing, building, managing, and optimizing data centers and network infrastructure. We stay up-to-date on the latest technologies and best practices to ensure your infrastructure is reliable, secure, and scalable.",
    },
    {
      icon: sliders,
      title: "Vendor-Neutral Approach",
      description:
        "We are not tied to any specific vendors or technologies. We take a vendor-neutral approach, recommending the best solutions for your specific needs and budget. This ensures you get the most cost-effective and efficient data center and network infrastructure for your organization.",
    },
    {
      icon: infinity,
      title: "Scalability & Flexibility",
      description:
        "We understand that your business needs are constantly evolving. Our solutions are designed to be scalable and flexible, allowing you to easily adapt your data center and network infrastructure to meet your growing requirements.",
    },
    {
      icon: headset,
      title: "Security & Compliance",
      description:
        "Data security and compliance are paramount. We prioritize security in all aspects of our services, ensuring your data is protected from cyber threats and your infrastructure adheres to industry regulations.",
    },
    {
      icon: shieldCheck,
      title: "24/7 Support & Monitoring",
      description:
        "We offer 24/7 support and monitoring for your data center and network infrastructure. Our team is always available to address any issues and ensure your IT operations run smoothly.",
    },
    {
      icon: piggy,
      title: "Reduced Costs & Increased Efficiency",
      description:
        "By optimizing your data center and network infrastructure, we can help you reduce costs associated with energy consumption, hardware maintenance, and downtime.",
    },
  ],
};
