import React, { useState, useEffect } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const getBackgroundClass = (itemIdx: any, screenWidth: number) => {
  if (screenWidth >= 768 && screenWidth < 1024) {
    // md breakpoint
    return itemIdx % 4 === 0 || itemIdx % 4 === 3 ? "bg-sky" : "bg-gray";
  } else {
    // sm and xl
    return itemIdx % 2 === 0
      ? "bg-sky hover:bg-lightGray"
      : "bg-gray hover:bg-[#B3CDE5]/80";
  }
};

const Clients: React.FC<any> = ({
  title,
  description,
  logos,
}: {
  title: string;
  description: string;
  logos: any;
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-full mt-8 cursor-default">
      <div className="mx-auto max-w-7xl px-6 sm:px-8 lg:px-10">
        <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-3xl lg:text-4xl font-extrabold drop-shadow-lightBlue uppercase">
          {title}
        </span>
        <p className="w-full text-dark/50 text-base lg:text-lg text-justify font-normal leading-6 my-6">
          {description}
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5">
        {logos.map((item: any, index: number) => (
          <div
            key={index}
            className={classNames(
              getBackgroundClass(index, screenWidth),
              "w-full h-auto px-10 py-8"
            )}
          >
            <div className="py-2 xl:py-4 flex justify-center items-center gap-2.5">
              <img src={item.logo} alt="LOGO" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Clients;
