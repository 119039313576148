import { Link } from "react-router-dom";

const Vector: React.FC<any> = ({
  id,
  className,
  vector,
  vecLocation,
  title,
  description,
  pdf,
}: {
  id: string;
  className: string;
  vector: any;
  vecLocation: string;
  title: string;
  description: string;
  pdf: string;
}) => {
  return (
    <div id={id} className={`w-full cursor-default ${className}`}>
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div>
          <div className="w-full flex flex-col md:flex-row items-center gap-16 mb-6">
            {vecLocation === "start" && (
              <img className="w-1/3" src={vector} alt="" />
            )}

            <div className="w-2/3 flex flex-col">
              <div className="text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlue">
                <div className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                  {title}
                </div>
              </div>

              <div className="text-justify text-dark/50 text-lg my-4">
                <span className="text-lg font-normal">{description}</span>
              </div>

              <div className="flex flex-row gap-4">
                <Link
                  to={`/contact?subject=${title}#contactForm`}
                  className="w-full lg:w-[400px] h-[54px] my-4 p-4 bg-primary rounded-t-md rounded-bl-md drop-shadow-blackSm flex justify-center items-center brightness-90 hover:brightness-100"
                >
                  <span className="text-lightGray hover:text-white text-base lg:text-lg font-normal uppercase">
                    Request a Quote
                  </span>
                </Link>

                <a
                  href={`/Sheets/${pdf}`}
                  download={pdf}
                  className="w-full lg:w-[400px] h-[54px] my-4 p-4 bg-secondary rounded-t-md rounded-bl-md drop-shadow-blackSm flex justify-center items-center brightness-90 hover:brightness-100"
                >
                  <button className="text-lightGray hover:text-white text-base lg:text-lg font-normal uppercase">
                    Download PDF
                  </button>
                </a>
              </div>
            </div>

            {vecLocation === "end" && (
              <img className="w-1/3" src={vector} alt="" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Vector;
