import React from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { faqs } from "../../utils/questions";
import plus from "../../images/icons/plus.svg";
import minus from "../../images/icons/minus.svg";

const Questions: React.FC = () => {
  return (
    <div className="w-full bg-sky">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="w-full">
          <h2 className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-3xl lg:text-4xl font-bold uppercase leading-10 lg:leading-[48.76px] drop-shadow-lightBlue">
            FAQ
          </h2>
          <p className="text-justify text-dark/50 text-base lg:text-lg font-normal my-4 leading-6">
            Minds like yours, questions alike: Let our most asked guide your
            journey, revealing the essence of what we do.
          </p>

          <dl className="space-y-2">
            {faqs.map((faq, index) => (
              <Disclosure as="div" key={index} className="pt-4">
                {({ open }) => (
                  <>
                    <dt>
                      <DisclosureButton className="w-full bg-gray flex items-center p-5 rounded-t-xl rounded-bl-xl drop-shadow-darkBlue">
                        <div className="w-5/6 text-dark text-sm lg:text-base text-left font-bold leading-5 uppercase">
                          {faq.question}
                        </div>
                        <div className="w-1/6 flex justify-end items-center">
                          {open ? (
                            <img
                              className="w-4 lg:w-6 h-4 lg:h-6"
                              src={minus}
                              alt="minus-svg"
                            />
                          ) : (
                            <img
                              className="w-4 lg:w-6 h-4 lg:h-6"
                              src={plus}
                              alt="plus-svg"
                            />
                          )}
                        </div>
                      </DisclosureButton>
                    </dt>
                    <DisclosurePanel
                      as="dd"
                      className="bg-white flex items-center gap-2.5 p-5 rounded-bl-xl"
                    >
                      <p className="text-dark/75 text-sm lg:text-base font-normal leading-5">
                        {faq.answer?.split("\n").map(
                          (line: any, lineIndex: number) => (
                            <p key={lineIndex}>
                              {lineIndex > 0 && "- "}
                              {line
                                .split("terms of service")
                                .map(
                                  (part: any, partIndex: number, arr: any) => (
                                    <React.Fragment key={partIndex}>
                                      {part}
                                      {partIndex < arr.length - 1 && (
                                        <a
                                          href="/terms"
                                          className="underline hover:no-underline text-primary"
                                        >
                                          terms of service
                                        </a>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </p>
                          )
                          // index === 0 ? (
                          //   <p key={index}>{text}</p>
                          // ) : (
                          //   <p key={index}>- {text}</p>
                          // )
                        )}
                      </p>
                    </DisclosurePanel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
export default Questions;
