//offers
import ChartLine from "../images/gradientIcons/ChartLine.svg";
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";
import Speaker from "../images/gradientIcons/Speaker.svg";
import UsersThree from "../images/gradientIcons/UsersThree.svg";
import Rocket from "../images/gradientIcons/Rocket.svg";
import Headset from "../images/gradientIcons/Headset.svg";

//reasons
import magnet from "../images/grayIcons/Magnet.svg";
import handshake from "../images/grayIcons/Handshake.svg";
import robot from "../images/grayIcons/Robot.svg";
import addressBook from "../images/grayIcons/AddressBook.svg";
import buildingOffice from "../images/grayIcons/BuildingOffice.svg";
import chartPie from "../images/grayIcons/ChartPie.svg";
import shoppingCart from "../images/grayIcons/ShoppingCartSimple.svg";
import gauge from "../images/grayIcons/Gauge.svg";

export const crm = {
  offers: [
    {
      number: "1",
      icon: ChartLine,
      title: "Boost Sales & Pipeline Management",
      description:
        "CentraHub CRM isn't just a static address book. It's a dynamic sales engine that helps you capture leads, nurture them through the sales funnel with targeted campaigns, and track deals from initial contact to closed won.",
    },
    {
      number: "2",
      icon: Lightbulb,
      title: "Unlock Data-Driven Insights",
      description:
        "CentraHub CRM goes beyond contact management by providing powerful analytics tools. Gain valuable insights into customer behavior, identify trends, and make data-driven decisions to optimize your sales and marketing strategies.",
    },
    {
      number: "3",
      icon: Speaker,
      title: "Maximize Marketing ROI",
      description:
        "CentraHub CRM integrates seamlessly with your existing marketing automation tools. This allows you to personalize marketing campaigns based on customer data, track results in real-time, and optimize your marketing efforts for maximum return on investment.",
    },
    {
      number: "4",
      icon: UsersThree,
      title: "Break Down Silos & Foster Collaboration",
      description:
        "CentraHub CRM isn't just for the sales team. It fosters seamless collaboration by providing shared access to customer data and communication tools for sales, marketing, and customer support teams, ensuring everyone is on the same page.",
    },
    {
      number: "5",
      icon: Rocket,
      title: "Increase Efficiency & Productivity",
      description:
        "CentraHub CRM automates repetitive tasks like data entry and sending follow-up emails, freeing up your team's valuable time to focus on strategic initiatives and building stronger customer relationships.",
    },
    {
      number: "6",
      icon: Headset,
      title: "Proactive Customer Service",
      description:
        "CentraHub CRM equips your customer support team with the information they need to deliver exceptional service. With a 360-degree view of customer interactions and past communication history, your team can anticipate customer needs, resolve issues efficiently, and build stronger customer loyalty.",
    },
  ],

  reasons: [
    {
      icon: magnet,
      title: "Lead Management",
      description:
        "Don't let leads slip through the cracks. CentraHub CRM empowers you to capture leads from various sources, nurture them through targeted campaigns at every stage of the sales funnel, and qualify them efficiently to identify the most promising opportunities.",
    },
    {
      icon: handshake,
      title: "Opportunity Management",
      description:
        "Track deals seamlessly from initial contact to closed won. CentraHub CRM provides insightful data on sales opportunities, allowing you to forecast sales with greater accuracy, identify potential roadblocks proactively, and adjust your approach to maximize conversion rates.",
    },
    {
      icon: robot,
      title: "Sales Automation",
      description:
        "Free up your sales team's valuable time by automating repetitive tasks. CentraHub CRM automates tasks like sending follow-up emails, creating new customer accounts, or assigning leads to sales reps, ensuring a smooth and efficient sales workflow.",
    },
    {
      icon: addressBook,
      title: "Contact Management",
      description:
        "Say goodbye to scattered spreadsheets and fragmented data. CentraHub CRM centralizes all your customer information, including contact details, communication history, interaction notes, and preferences, providing a complete 360-degree view of your customer base.",
    },
    {
      icon: buildingOffice,
      title: "Customer Account Management",
      description:
        "Organize company or division information effectively. CentraHub CRM allows you to manage accounts strategically, fostering stronger and more personalized relationships with your customers.",
    },
    {
      icon: chartPie,
      title: "Customer Segmentation",
      description:
        "Target the right audience with the right message. CentraHub CRM supports market segmentation, helping you identify key customer groups based on specific criteria. This allows you to tailor your marketing efforts for maximum impact and reach the most receptive customers.",
    },
    {
      icon: shoppingCart,
      title: "Order Management ",
      description:
        "For businesses with product sales, CentraHub CRM (with optional add-ons) can streamline your order processing. Manage inventory, track pick-and-pack operations, and ensure efficient order fulfillment, enhancing customer satisfaction.",
    },
    {
      icon: gauge,
      title: "Reports & Dashboards",
      description:
        "Gain valuable insights in real-time. CentraHub CRM boasts intuitive dashboards that provide a clear visualization of key metrics and KPIs (Key Performance Indicators). This empowers you to make data-driven decisions and optimize your sales and marketing strategies for improved performance.",
    },
    {
      title: "",
      description: "",
    },
  ],
};
