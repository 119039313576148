import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import Solutions from "../../components/Sections/List";
import Card from "../../components/Sections/Card";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/QMS-Header.svg";
import contact from "../../images/vectors/QMS-Contact.svg";
import Qmatic from "../../images/vectors/Qmatic.svg";
import Orchestra from "../../images/vectors/Orchestra.svg";
import { qms } from "../../utils/qms";
import { logos } from "../../utils/logos";

function QMS() {
  return (
    <div>
      <Menu />

      <Header
        title="Elevate Customer Experiences with"
        subTitle="Seamless Queue Management"
        miniTitle="GBT Services"
        description="In today's fast-paced world, customer experience is paramount. Long queues and inefficient waiting times can damage your brand reputation and deter customers from returning.  Qmatic by GBT offers innovative queue management system solutions designed to revolutionize the way you manage customer flow, optimize operations, and elevate customer satisfaction."
        vector={header}
      />

      <GradientContainer
        className="h-[421px] md:h-[361px]"
        title="Transform Your Business with the Power of Qmatic Queue Management from GBT"
        content="This section explores how Qmatic empowers businesses to transform their customer experience, optimize operations, and achieve significant business improvements. We'll delve into the specific features and benefits of Qmatic, showcasing how it can help you streamline processes, gain valuable customer insights, and ultimately drive business success."
        description="Here's how Qmatic can transform your business:"
      />
      <GridItems content={qms.features} />

      <GradientContainer
        className="h-[378px] md:h-[278px]"
        title="Qmatic is Tailored Solutions for Every Business Need"
        content="When it comes to queue management, a one-size-fits-all approach simply doesn't work.  GBT offers a range of Qmatic solutions designed to cater to the specific needs of your business, whether you're a single-location operation or a multi-branch enterprise."
      />

      <div className="flex flex-col lg:flex-row">
        <Card
          bgColor="bg-offWite"
          image={Qmatic}
          title="Qmatic Solo System: The Perfect Solution for Standalone Needs."
          description="It is a self-contained customer flow management solution ideal for businesses with a single service location. It provides a streamlined and efficient way to organize your customer flow, ensuring a smooth and positive experience for everyone. it is ideal for businesses with a single service location seeking a simple and effective queue management solution."
          link="#Solo"
        />

        <Card
          bgColor="bg-gray"
          image={Orchestra}
          title="Qmatic ORCHESTRA 7 Solution for SMEs and Enterprises: Scale Up Your Customer Flow Management."
          description="For businesses with multiple locations or complex service offerings, GBT offers a comprehensive suite of Qmatic solutions tailored to the needs of SMEs (Small and Medium Enterprises) and Enterprises. These scalable solutions integrate seamlessly with your existing systems, providing a centralized platform for managing customer flow across your entire organization."
          link="#ORCHESTRA"
        />
      </div>

      <GradientContainer
        id="Solo"
        className="h-[392px] md:h-[300px]"
        title="Qmatic Solo Solution: The Perfect Solution for Standalone Needs"
        content="Qmatic Solo is a self-contained queue management system designed to streamline customer flow and enhance the overall experience at your single-location business."
        description="Here's a closer look at Qmatic Solo's key features and benefits:"
      />
      <Solutions content={qms.benefits} />

      <GradientContainer
        id="ORCHESTRA"
        className="h-[442px] md:h-[382px]"
        title="Qmatic ORCHESTRA 7 Solution: Scale Up Your Customer Flow Management"
        content="Qmatic Solutions for SMEs (Small and Medium Enterprises) and Enterprises go beyond basic queue management, offering a comprehensive suite of features designed to empower businesses with multiple locations or complex service needs. Imagine a centralized platform that provides real-time visibility, streamlines operations across all your branches, and empowers data-driven decision making."
        description="Here's how Qmatic SME and Enterprise solutions can transform your business:"
      />
      <Solutions content={qms.offers} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to Upgrade"
        subTitle="your customer flow management?"
        description="Contact GBT today to schedule a free consultation and discover how Qmatic solutions can help you achieve your customer experience goals."
        vector={contact}
        subject="Queue Management Solutions"
      />

      <Footer />
    </div>
  );
}
export default QMS;
