import microsoft from "../images/logos/microsoft.png";
import veritas from "../images/logos/veritas.png";
import vmware from "../images/logos/vmware.png";
import nutanix from "../images/logos/nutanix.png";
import fortinet from "../images/logos/fortinet.png";
import redhat from "../images/logos/redhat.png";

export const partners = [
  { name: "Nutanix", logo: nutanix },
  { name: "Microsoft", logo: microsoft },
  { name: "Veritas", logo: veritas },
  { name: "Vmware", logo: vmware },
  { name: "Fortinet", logo: fortinet },
  { name: "Redhat", logo: redhat },
];
