//Uses
import star from "../images/grayIcons/ShootingStar.svg";
import piggy from "../images/grayIcons/PiggyBank.svg";
import shieldCheck from "../images/grayIcons/ShieldCheck.svg";
import sliders from "../images/grayIcons/Sliders.svg";
import Gauge from "../images/grayIcons/Gauge.svg";
import handshake from "../images/grayIcons/Handshake.svg";

//Benefits
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";
import AppWindow from "../images/gradientIcons/AppWindow.svg";
import CodeBlock from "../images/gradientIcons/CodeBlock.svg";
import GoogleLogo from "../images/gradientIcons/GoogleLogo.svg";

import NotePencil from "../images/gradientIcons/NotePencil.svg";
import Speaker from "../images/gradientIcons/Speaker.svg";

export const hosting = {
  tools: [
    {
      number: "1",
      icon: Lightbulb,
      title: "Start with a Memorable Domain",
      contact: "Domain Registration",
      buttonText: "Contact us for Domain Registration",
      description:
        "At GBT, we understand the importance of a strong domain name.  Our experts can assist you in finding the perfect match to your online identity - no complex search tools needed, just a conversation about your brand goals!",
    },
    {
      number: "2",
      icon: AppWindow,
      title: "Choose the Right Hosting Plan",
      buttonText: "contact us for Hosting Service",
      contact: "Hosting Service",
      description:
        "Whether you're a budding entrepreneur or a well-established company, GBT offers a range of hosting plans to meet your specific needs.",
    },
  ],

  services: [
    {
      number: "3",
      icon: CodeBlock,
      title: "Design and Develop your website",
      link: "/services/web-app",
      buttonText: "Learn about Web development service",
      description:
        "Our expert web designers and developers create beautiful and user-friendly websites that showcase your brand and resonate with your target audience.",
    },
    {
      number: "4",
      icon: GoogleLogo,
      title: "Search Engine Optimization",
      link: "/services/web-app",
      buttonText: "Learn about Web development service",
      description:
        "We also specialize in Search Engine Optimization (SEO) to ensure your website ranks high in search engine results pages, driving organic traffic and increasing visibility.",
    },

    {
      number: "5",
      icon: NotePencil,
      title: "Content is King",
      link: "/solutions/CMS",
      buttonText: "Learn about Content creation service",
      description:
        "Once your website is up and running, the key to success is engaging content. So create valuable content that resonates with your target audience. Our experts provide valuable content creation tips and insights to get you started.",
    },
    {
      number: "6",
      icon: Speaker,
      title: "Embrace Social Media Marketing",
      link: "/services/digital-marketing",
      buttonText: "Learn about Marketing service",
      description:
        "Connect with your audience on popular social media platforms. Share your content, participate in conversations, and build a community around your brand. All this and more we offer you in the marketing management service we provide!",
    },
  ],

  benefits: [
    {
      icon: star,
      title: "Unforgettable Domain Names",
      description:
        "Stand out from the crowd with a domain name that perfectly reflects your brand. Our extensive search tool makes finding the perfect fit a breeze, and we offer a variety of extensions (e.g., .com, .net, .org) to suit your needs.",
    },
    {
      icon: piggy,
      title: "Unmatched Value for Your Business",
      description:
        "We offer competitive pricing plans to fit any budget, with bundled features like free SSL certificates and email accounts saving you even more.  Regular promotions and discounts ensure you're getting the best possible value for your investment.",
    },
    {
      icon: shieldCheck,
      title: "Reliable & Secure Hosting",
      description:
        "Experience industry-leading uptime for a website that's always accessible to your audience. Sleep soundly knowing your website is protected with advanced security features.  Our scalable plans ensure your website infrastructure can grow alongside your business.",
    },
    {
      icon: sliders,
      title: "Effortless Website Management:",
      description:
        "We prioritize user-friendliness.  Our control panels make managing your website easy, even for beginners. One-click installations for popular applications like WordPress streamline your workflow, and our 24/7 expert support team is always available to answer any questions you may have.",
    },

    {
      icon: Gauge,
      title: "Performance You Can Count On",
      description:
        "Keep visitors engaged with our lightning-fast loading times powered by high-performance servers. Leverage our global Content Delivery Network (CDN) for exceptional speed worldwide. Our auto-scaling feature handles traffic spikes with ease, ensuring smooth performance no matter how many visitors you attract.",
    },
    {
      icon: handshake,
      title: "Trusted Partner for Your Success",
      description:
        "With years of experience helping businesses like yours thrive online, we are dedicated to your success.  Our customer-centric approach ensures you receive the support you need, and our positive testimonials and industry recognition solidify our commitment to quality.",
    },
  ],
};
