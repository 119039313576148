import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import VectorList from "../../components/Sections/VectorList";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/IOT-Header.svg";
import contact from "../../images/vectors/IOT-Contact.svg";
import StrategicPlan from "../../images/vectors/StrategicPlan.svg";
import CustomSolution from "../../images/vectors/CustomSolution.svg";
import Support from "../../images/vectors/Support.svg";
import { iot } from "../../utils/iot";
import { logos } from "../../utils/logos";

function IOT() {
  return (
    <div>
      <Menu />

      <Header
        title="Unleashing the power of"
        subTitle="connectivity: GBT's IOT Solutions"
        miniTitle="GBT Solutions"
        description="'The Internet of Things (IoT) is transforming our world by connecting everyday objects to the internet, creating a vast network of intelligent devices that collect, analyze, and transmit data. This opens up a world of possibilities for businesses in all industries."
        vector={header}
      />

      <GradientContainer
        className="h-[400px] md:h-[300px]"
        title="how GBT empowers you to harness the power of IoT"
        content="At GBT, we're not just observers of this technological revolution; we're active participants, shaping the future of IoT with innovative and custom-designed solutions. We understand the immense potential of IoT and its ability to transform industries, optimize processes, and deliver real-world results."
        description="Here's how GBT empowers you to harness the power of IoT:"
      />

      <VectorList
        className="bg-sky"
        itemBackground="lightGray"
        vector={StrategicPlan}
        vecLocation="start"
        title="Strategic Planning &"
        subTitle="Needs Assessment"
        description="The foundation of any successful IoT implementation is a well-defined strategy and a thorough understanding of your unique needs. At GBT, we don't take a one-size-fits-all approach. Our Strategic Planning & Needs Assessment process ensures your custom IoT solution is perfectly tailored to address your specific challenges and propel your business forward."
        content={iot.phases}
        intro="Here's how we navigate this crucial initial phase:"
      />

      <VectorList
        className="bg-lightGray"
        itemBackground="sky"
        vector={CustomSolution}
        vecLocation="end"
        title="Custom IoT Solution"
        subTitle="esign & Development"
        description="At GBT, we understand that a successful IoT implementation goes beyond simply deploying sensors and connecting devices. It's about crafting a custom solution that seamlessly integrates with your existing infrastructure, addresses your specific needs, and unlocks the true potential of IOT."
        content={iot.solutions}
        intro="Here's a closer look at how GBT tackles Custom IoT Solution Design & Development:"
      />

      <VectorList
        className="bg-sky"
        itemBackground="lightGray"
        vector={Support}
        vecLocation="start"
        title="Seamless Integration &"
        subTitle="Ongoing Support"
        description="The journey doesn't end after we design and develop your custom IoT solution. At GBT, we understand the importance of seamless integration and ongoing support to ensure your system functions optimally and delivers long-term value."
        content={iot.support}
        intro="Here's how we ensure a smooth transition and provide the support you need:"
      />

      <GradientContainer
        className="h-[460px] md:h-[360px]"
        title="Unleash the Power of IoT Across Industries: How GBT Can Help You Thrive"
        content="The Internet of Things (IoT) is revolutionizing industries, transforming how businesses operate and interact with their customers. At GBT, we're not just tech enthusiasts; we're industry experts who understand the unique challenges and opportunities each sector presents. We leverage our IoT expertise to craft custom solutions that unlock real-world value for your business, regardless of your industry."
        description="Here are some specific industry applications where GBT's IoT expertise can empower your business:"
      />
      <GridItems content={iot.benefits} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Let's unlock the potential of a"
        subTitle="connected future for your business"
        description="Don't wait to unlock the competitive advantage that IoT can offer. Contact GBT today and let's discuss your specific needs today and craft a custom solution that propels your business towards greater efficiency, productivity, and profitability."
        vector={contact}
        subject="Internet of Things (IoT) Solutions"
      />
      <Footer />
    </div>
  );
}
export default IOT;
