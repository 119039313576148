//offers
import Receipt from "../images/gradientIcons/Receipt.svg";
import UsersFour from "../images/gradientIcons/UsersFour.svg";
import Warehouse from "../images/gradientIcons/Warehouse.svg";
import Handshake from "../images/gradientIcons/Handshake.svg";
import CalendarCheck from "../images/gradientIcons/CalendarCheck.svg";
import Package from "../images/gradientIcons/Package.svg";

//apparts
import Robot from "../images/gradientIcons/Robot.svg";
import ChartBar from "../images/gradientIcons/ChartBar.svg";
import Wrench from "../images/gradientIcons/Wrench.svg";
import Rocket from "../images/gradientIcons/Rocket.svg";

//reasons
import trendUp from "../images/grayIcons/TrendUp.svg";
import piggyBank from "../images/grayIcons/PiggyBank.svg";
import lightbulb from "../images/grayIcons/Lightbulb.svg";
import smiley from "../images/grayIcons/Smiley.svg";
import infinity from "../images/grayIcons/Infinity.svg";
import shieldCheck from "../images/grayIcons/ShieldCheck.svg";

export const erp = {
  offers: [
    {
      number: "1",
      icon: Receipt,
      title: "Financial Management",
      description:
        "Streamline financial processes, automate accounting tasks, and gain real-time insights into your financial health with FocusX's comprehensive financial management tools.",
    },
    {
      number: "2",
      icon: UsersFour,
      title: "Human Resources",
      description:
        "FocusX equips you with features for recruitment, onboarding, payroll, and performance management, empowering you to effectively manage your human resources.",
    },
    {
      number: "3",
      icon: Warehouse,
      title: "Inventory & Warehouse Management",
      description:
        "Control your inventory levels, optimize warehouse operations, and gain real-time visibility into stock levels with FocusX's inventory and warehouse management features.",
    },
    {
      number: "4",
      icon: Handshake,
      title: "Sales & Customer Relationship Management",
      description:
        "FocusX's CRM features help you manage your sales pipeline, nurture customer relationships, and close deals faster, leading to improved customer satisfaction and sales growth.",
    },
    {
      number: "5",
      icon: CalendarCheck,
      title: "Project Management",
      description:
        "Plan, track, and manage projects efficiently with FocusX's project management tools, enabling effective task assignment, resource allocation, and progress monitoring.",
    },
    {
      number: "6",
      icon: Package,
      title: "Manufacturing & Production",
      description:
        "Optimize production processes, manage production schedules, and ensure quality control with FocusX's AI-powered insights and manufacturing & production features.",
    },
  ],

  aparts: [
    {
      number: "1",
      icon: Robot,
      title: "Intelligent Automation",
      description:
        "FocusX leverages AI and machine learning to automate repetitive tasks across various departments, freeing up your valuable human resources to focus on strategic initiatives. Imagine automated data entry, order processing, or report generation – FocusX takes care of it.",
    },
    {
      number: "2",
      icon: ChartBar,
      title: "Enhanced Data Analytics",
      description:
        "Gain deeper insights into your business performance with FocusX's robust analytics tools. Turn raw data into actionable intelligence, identify trends, and make data-driven decisions that optimize your operations and drive growth.",
    },
    {
      number: "3",
      icon: Wrench,
      title: "Predictive Maintenance",
      description:
        "FocusX employs AI-powered predictive maintenance capabilities to anticipate equipment failures before they occur. This minimizes downtime, reduces maintenance costs, and ensures smooth production processes.",
    },
    {
      number: "4",
      icon: Rocket,
      title: "Improved Supply Chain Management",
      description:
        "Optimize your supply chain with FocusX's AI-driven forecasting and inventory management tools. Ensure you have the right materials in the right place at the right time, reducing stockouts and improving overall supply chain efficiency.",
    },
  ],

  reasons: [
    {
      icon: trendUp,
      title: "Increased Efficiency & Productivity",
      description:
        "Don't let leads slip through the cracks. CentraHub CRM empowers you to capture leads from various sources, nurture them through targeted campaigns at every stage of the sales funnel, and qualify them efficiently to identify the most promising opportunities.",
    },
    {
      icon: piggyBank,
      title: "Reduced Costs",
      description:
        "Track deals seamlessly from initial contact to closed won. CentraHub CRM provides insightful data on sales opportunities, allowing you to forecast sales with greater accuracy, identify potential roadblocks proactively, and adjust your approach to maximize conversion rates.",
    },
    {
      icon: lightbulb,
      title: "Improved Decision Making",
      description:
        "Free up your sales team's valuable time by automating repetitive tasks. CentraHub CRM automates tasks like sending follow-up emails, creating new customer accounts, or assigning leads to sales reps, ensuring a smooth and efficient sales workflow.",
    },
    {
      icon: smiley,
      title: "Enhanced Customer Satisfaction",
      description:
        "Say goodbye to scattered spreadsheets and fragmented data. CentraHub CRM centralizes all your customer information, including contact details, communication history, interaction notes, and preferences, providing a complete 360-degree view of your customer base.",
    },
    {
      icon: infinity,
      title: "Scalability & Security",
      description:
        "Organize company or division information effectively. CentraHub CRM allows you to manage accounts strategically, fostering stronger and more personalized relationships with your customers.",
    },
    {
      icon: shieldCheck,
      title: "Improved Risk Management",
      description:
        "FocusX leverages AI and machine learning to identify potential risks proactively.  For example, it can analyze financial data to detect fraud patterns or predict equipment failures before they occur.  This enables you to take preventive measures, minimize disruptions, and ensure the smooth operation of your business.",
    },
  ],
};
