export const footerNavigation = {
  services: [
    { name: "- Web Hosting & Domain Registering​", href: "/services/hosting" },
    { name: "- Cloud Services", href: "/services/cloud" },
    { name: "- Data Center & Infrastructure​", href: "/services/data-center" },
    { name: "- Consultation Services", href: "/services/consultancy" },
    { name: "- Web Development", href: "/services/web-app" },
    { name: "- Mobile App Development", href: "/services/mobile-app" },
    { name: "- Digital Marketing", href: "/services/digital-marketing" },
  ],
  solutions: [
    {
      name: "- Software Licensing Solutions",
      href: "/solutions/software-license",
    },
    { name: "- ERP Solutions", href: "/solutions/ERP" },
    { name: "- CRM Solutions", href: "/solutions/CRM" },
    { name: "- E-commerce Solutions", href: "/solutions/e-commerce" },
    {
      name: "- Content Management System (CMS) Solutions",
      href: "/solutions/CMS",
    },
    { name: "- Internet of things (IOT)", href: "/solutions/IOT" },
    { name: "- Queue Management Solution", href: "/solutions/QMS" },
    { name: "- VAS Solution", href: "/solutions/VAS" },
  ],
  products: [
    { name: "- DataBox", href: "/products/data-box" },
    { name: "- Network Essentials", href: "/products/network" },
    { name: "- Wireless Solutions", href: "/products/wireless" },
  ],

  pages: [
    { name: "- Training and hiring staff​", href: "/training" },
    { name: "- Terms of Services", href: "/terms" },
    { name: "- Contact and help", href: "/contact" },
    { name: "- About GBT", href: "/about" },
  ],
};
