//PTP
import Gauge from "../images/gradientIcons/Gauge.svg";
import WifiHigh from "../images/gradientIcons/WifiHigh.svg";
import Satellite from "../images/gradientIcons/Satellite-dish.svg";
import Broadcast from "../images/gradientIcons/Broadcast.svg";
import ShieldCheck from "../images/gradientIcons/ShieldCheck.svg";
import Lego from "../images/gradientIcons/Lego.svg";
import Router from "../images/gradientIcons/Router.svg";
import Rocket from "../images/gradientIcons/Rocket.svg";
import Antenna from "../images/gradientIcons/Antenna.svg";

//Aparts
import certificate from "../images/grayIcons/Certificate.svg";
import lifebuoy from "../images/grayIcons/Lifebuoy.svg";
import infinity from "../images/grayIcons/Infinity.svg";

export const wireless = {
  pointToPoint: [
    {
      number: "1",
      icon: Gauge,
      title: "Real Throughput Up to 1 Gbps",
      description:
        "Experience lightning-fast data transfer speeds, ensuring smooth operation for bandwidth-intensive applications like video conferencing, cloud storage access, and real-time data processing.",
    },
    {
      number: "2",
      icon: WifiHigh,
      title: "Single Hop Distance of 200+ Km",
      description:
        "Eliminate the need for repeaters or intermediate access points. Our PTP solutions can reliably bridge vast distances, making them perfect for connecting remote sites or establishing backhaul links in rural or challenging terrains.",
    },
    {
      number: "3",
      icon: Satellite,
      title: "High-Gain External Antennas",
      description:
        "Our solutions come equipped with high-gain external antennas, maximizing signal strength and ensuring a robust connection even over long distances or in areas with potential signal interference.",
    },
    {
      number: "4",
      icon: Broadcast,
      title: "Unlimited Number of Hops (Optional)",
      description:
        "While PTP solutions typically involve a single hop, GBT offers the flexibility to create multi-hop connections in specific scenarios, further extending your reach if necessary. (Note: Throughput and latency may be impacted with additional hops.)",
    },
    {
      number: "5",
      icon: ShieldCheck,
      title: "Full Quality of Service (QoS) Support",
      description:
        "Prioritize critical traffic like voice calls and video conferencing, ensuring smooth operation even during peak network usage.",
    },
    {
      number: "6",
      icon: Lego,
      title: "Flexible Frequency Planning",
      description:
        "Our solutions offer a variety of frequency options, allowing you to select the ideal band for your specific needs and location, avoiding potential interference from other wireless networks.",
    },
  ],

  pointToMultiple: [
    {
      number: "1",
      icon: WifiHigh,
      title: "Base Station (BS) Sector Coverage Up to 40 Km",
      description:
        "Our high-performance base stations boast extensive coverage, ensuring a strong signal across a wide sector, ideal for connecting users spread over a large area.",
    },
    {
      number: "2",
      icon: Gauge,
      title: "Sector Capacity Up to 800 Mbps",
      description:
        "Experience exceptional bandwidth capabilities, allowing you to seamlessly connect multiple devices and users without compromising on speed.",
    },
    {
      number: "3",
      icon: Rocket,
      title: "Subscriber Terminal Capacity In Excess of 670 Mbps",
      description:
        "Each connected device on your network enjoys exceptional bandwidth, ensuring a smooth and lag-free experience for all users.",
    },

    {
      number: "4",
      icon: Broadcast,
      title: "TDD Synchronization and Frequency Reuse",
      description:
        "These advanced technologies allow for efficient spectrum utilization and minimize interference, ensuring a stable and reliable connection for all connected devices.",
    },

    {
      number: "5",
      icon: ShieldCheck,
      title: "Full Quality of Service (QoS) Support",
      description:
        "Prioritize critical traffic for applications like VoIP and video conferencing, guaranteeing smooth operation even during periods of high network demand.",
    },

    {
      number: "6",
      icon: Router,
      title: "Sophisticated L2/L3/L4 Networking Functionality",
      description:
        "GBT's PTMP solutions offer advanced networking capabilities, allowing you to create secure and flexible network configurations tailored to your specific needs.",
    },

    {
      number: "7",
      icon: Antenna,
      title: "Interference Mitigation Tools",
      description:
        "Our solutions are equipped with advanced tools to combat signal interference, ensuring a reliable connection even in congested environments.",
    },
  ],

  aparts: [
    {
      icon: certificate,
      title: "Experience and Expertise",
      description:
        "GBT has a proven track record of delivering high-performance wireless solutions to businesses across diverse industries. Our team of experts can recommend the ideal solution to meet your specific needs and budget.",
    },
    {
      icon: lifebuoy,
      title: "Reliable Support",
      description:
        "Our dedicated support team is available to assist you with installation, configuration, and ongoing maintenance, ensuring your wireless network operates at peak performance.",
    },
    {
      icon: infinity,
      title: "Scalable & Customizable Solutions",
      description:
        "GBT's wireless solutions are flexible and scalable to fit your unique needs. Our solutions are also designed to grow alongside your business. Easily expand your network by adding additional base stations or subscriber terminals as your connectivity needs evolve.",
    },
  ],
};
