import phone from "../images/icons/phone.svg";
import envelope from "../images/icons/envelope.svg";
import linkedin from "../images/socialMediaIcons/linkedin.svg";
import facebook from "../images/socialMediaIcons/facebook.svg";
import instagram from "../images/socialMediaIcons/instagram.svg";

export const contact = {
  phone: [{ name: "phone", content: "+971 4 259 9967​", icon: phone }],
  email: [{ name: "email", content: "info@gbt.ae", icon: envelope }],
  soical: [
    {
      name: "linkedin",
      description: "gbt-llc",
      link: "https://www.linkedin.com/company/gbt-llc/",
      icon: linkedin,
    },
    {
      name: "facebook",
      description: "gbtllcco",
      link: "https://www.facebook.com/gbtllcco/",
      icon: facebook,
    },
    {
      name: "instagram",
      description: "gbtllc​",
      link: "https://www.instagram.com/gbtllc/",
      icon: instagram,
    },
  ],
  address: [
    {
      location: "Dubai",
      street: "Dubai AL Garhoud Street 59​",
      officeDetails: "Al Garhoud Business Center, M floor, Office # M-03",
    },
  ],
  workingTime: [{ days: "Sunday - Thursday,", time: "8:00 AM - 4:00 PM​" }],
};
