import BookOpen from "../images/about/BookOpen.svg";
import Target from "../images/about/Target.svg";
import Eye from "../images/about/Eye.svg";
import UsersThree from "../images/about/UsersThree.svg";

export const items = [
  {
    title: "Our Story",
    icon: BookOpen,
    description:
      "How can we empower businesses to break free from technological limitations and unlock their full potential? The answer became GBT Solutions, a haven for innovative minds where cutting-edge technology meets personalized service.",
  },
  {
    title: "Our Mission",
    icon: Target,
    description:
      "To craft customized ICT solutions that propel your business forward. We're not here to sell one-size-fits-all packages; we tailor our services to your unique needs, challenges, and aspirations. Whether you're a startup disrupting an industry or a seasoned player seeking to optimize your operations, we're your trusted partner in the digital revolution.",
  },
  {
    title: "Our Vision",
    icon: Eye,
    description:
      "Being a pioneer, we will continue to grow as the preferred business partner on an era where major changes affecting both our lives and the way business is being done. As we mark our existence we are heading to this new era with confidence and competence, keeping our promises towards our society for the future generations",
  },
  {
    title: "Our Team",
    icon: UsersThree,
    description:
      "From cloud computing and network infrastructure to security solutions and software integration, our team of experts is your one-stop shop for all things tech. We speak the language of innovation, but we also understand the human side of business. We're not just tech gurus; we're your collaborators, your advisors, your cheerleaders.",
  },
];
