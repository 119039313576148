//Uses
import users from "../images/grayIcons/UsersFour.svg";
import chartDonut from "../images/grayIcons/ChartDonut.svg";
import intersect from "../images/grayIcons/IntersectThree.svg";
import chatsCircle from "../images/grayIcons/ChatsCircle.svg";
import trendUp from "../images/grayIcons/TrendUp.svg";
import chartBar from "../images/grayIcons/ChartBar.svg";

//Benefits
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";
import GoogleLogo from "../images/gradientIcons/GoogleLogo.svg";
import CursorClick from "../images/gradientIcons/CursorClick.svg";
import ThumbsUp from "../images/gradientIcons/ThumbsUp.svg";
import NotePencil from "../images/gradientIcons/NotePencil.svg";
import ProjectorScreenChart from "../images/gradientIcons/ProjectorScreenChart.svg";

export const digitalMarketing = {
  offers: [
    {
      number: "1",
      icon: Lightbulb,
      title: "Strategic Planning & Consulting",
      description:
        "We begin by partnering with you to understand your unique business goals, target audience, and competitive landscape. Through in-depth consultations, we develop a data-driven digital marketing strategy tailored to your specific needs and objectives.",
    },
    {
      number: "2",
      icon: GoogleLogo,
      title: "Search Engine Optimization (SEO)",
      description:
        "We improve your website's ranking in search results for relevant keywords, attracting more organic traffic. Our SEO experts use proven techniques to boost your website's search visibility.",
    },
    {
      number: "3",
      icon: CursorClick,
      title: "Online Ads (Pay-Per-Click Advertising)",
      description:
        "Reach your target audience quickly and effectively through targeted Pay-Per-Click Advertising (PPC) campaigns. We manage your PPC advertising across platforms like Google Ads and social media, ensuring your ads reach the right people at the right time.",
    },
    {
      number: "4",
      icon: ThumbsUp,
      title: "Social Media Marketing",
      description:
        "Engage your audience and build brand loyalty through strategic social media marketing campaigns. Our team creates compelling content tailored to each platform, fostering meaningful interactions and driving brand awareness.",
    },
    {
      number: "5",
      icon: NotePencil,
      title: "Content Marketing",
      description:
        "We craft high-quality, engaging content that informs, educates, and resonates with your target audience. Our content marketing strategies include blog posts, articles, infographics, videos, and more, establishing you as a thought leader in your industry.",
    },
    {
      number: "6",
      icon: ProjectorScreenChart,
      title: "Marketing Analytics & Reporting",
      description:
        "Stay informed and measure success with comprehensive marketing analytics and reporting. We track key metrics, analyze data insights, and provide actionable recommendations to continuously optimize your digital marketing strategy..",
    },
  ],

  reasons: [
    {
      icon: users,
      title: "Experienced & Skilled Team",
      description:
        "Our team comprises passionate and experienced digital marketing specialists who stay at the forefront of industry trends and best practices. They bring a wealth of knowledge and expertise to the table, ensuring your campaigns are executed strategically and effectively.",
    },
    {
      icon: chartDonut,
      title: "Data-Driven Approach",
      description:
        "We don't believe in guesswork. Our strategies are fueled by data and insights, ensuring your digital marketing efforts are measurable and deliver tangible results that contribute to your overall business objectives.",
    },
    {
      icon: intersect,
      title: "Holistic Marketing Strategy",
      description:
        "We take a comprehensive approach, integrating various digital marketing channels to create a cohesive and impactful online presence. We don't operate in silos – our team works together seamlessly to ensure your brand message is consistent and resonates across all platforms.",
    },
    {
      icon: chatsCircle,
      title: "Transparent Communication",
      description:
        "Open communication is paramount. You'll be kept informed of progress, results, and any adjustments we make to optimize your campaigns. We believe in a collaborative approach and value your feedback throughout the process.",
    },
    {
      icon: trendUp,
      title: "Focus on Return on Investment (ROI)",
      description:
        "Your success is our success. Our primary objective is to deliver a positive return on investment (ROI) for your digital marketing efforts. We track key metrics, analyze data insights, and constantly optimize campaigns to ensure you see results that matter to your business – increased website traffic, lead generation, conversions, and ultimately, growth.",
    },
    {
      icon: chartBar,
      title: "Scalable Solutions & Adaptability",
      description:
        "The digital marketing landscape is constantly evolving. At GBT, we understand the need for flexibility and adaptability. Our scalable solutions grow alongside your business, and our strategies adjust to keep pace with industry trends.. We're committed to ensuring your digital marketing efforts remain effective and deliver long-term results.",
    },
  ],
};
