import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Subscribe } from "../../interfaces/Subscribe";
import { footerNavigation } from "../../utils/footer";
import { contact } from "../../utils/contact";
import * as subscribe from "../../services/subscribe.services";
import ButtonLoader from "../../Common/Loader/ButtonLoader";
import ParticlesBG from "../Home/ParticlesBG";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);

  const initialValues: Subscribe = {
    email: "",
  };

  const FormSchema = Yup.object().shape({
    email: Yup.string(),
  });

  const handleForm = async (values: Subscribe, { resetForm }: any) => {
    console.log("🚀 ~ handleForm ~ values:", values);
    setSubmitting(true);
    subscribe
      .create(values)
      .then((response: any) => {
        if (response.status === 200) {
          console.log("response", response);
          setSubmitting(false);
          resetForm();
          setAlert(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlert(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [alert]);

  return (
    <div className="relative drop-shadow-footer">
      <ParticlesBG
        id="tsparticles-footer"
        className="h-[1200px] md:h-[750px] lg:h-[600px] xl:h-[500px] bg-gradient-to-r from-dark to-secondary"
      />

      <div className="absolute top-0 left-0 w-full h-full">
        <div className="pt-4 flex flex-col">
          {/* section 1 */}
          <div className="border-b-2 border-lightGray/20">
            <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12">
              <div className="flex flex-col md:flex-row md:justify-between items-center md:gap-2">
                <div className="w-full">
                  <p className="text-lightGray text-lg lg:text-xl font-bold">
                    SUBSCRIBE TO OUR NEWSLETTER
                  </p>
                  <p className="text-lightGray/50 text-sm lg:text-base">
                    The Latest News, Offers, And Solutions, Sent To Your Inbox
                    Weekly.
                  </p>
                </div>

                <div className="w-full py-4">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    onSubmit={handleForm}
                  >
                    {({ errors, touched }) => (
                      <Form className="flex">
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>

                        <Field
                          name="email"
                          type="email"
                          autoComplete="email"
                          placeholder="Enter your email here..."
                          required
                          className={`w-full lg:w-[440px] min-w-0 appearance-none rounded-tl-md rounded-bl-md bg-white p-3 lg:p-4 text-sm lg:text-base text-dark placeholder-dark/50 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary 
                          ${
                            touched.email && errors.email
                              ? "border border-danger focus:border-danger focus:ring-danger"
                              : "border-none"
                          }`}
                          disabled={isSubmitting}
                        />
                        {touched.email && errors.email && (
                          <p className="text-danger">{errors.email}</p>
                        )}

                        <div className="flex-shrink-0">
                          <button
                            type="submit"
                            className="w-full lg:w-[132px] flex items-center justify-center rounded-tr-md rounded-br-md  bg-primary p-3 lg:p-4 text-sm lg:text-base font-medium text-white shadow-sm hover:bg-dark"
                          >
                            {isSubmitting ? (
                              <span className="flex justify-center  gap-1">
                                <ButtonLoader /> {t("Subscribing..")}
                              </span>
                            ) : (
                              <span className="text-lightGray text-base font-bold uppercase">
                                {t("Subscribe")}
                              </span>
                            )}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

          <div className="border-b-2 border-lightGray/20">
            <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12">
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-5 py-8">
                <div>
                  <h3 className="text-base font-semibold text-lightGray drop-shadow-lightBlue">
                    GBT SERVICES
                  </h3>
                  <ul className="mt-2 space-y-2">
                    {footerNavigation.services.map((item) => (
                      <li key={item.name} className="text-sm">
                        <a
                          href={item.href}
                          className="text-lightGray/75 font-normal hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h3 className="text-base font-semibold text-lightGray drop-shadow-lightBlue">
                    GBT SOLUTIONS
                  </h3>
                  <ul className="mt-2 space-y-2">
                    {footerNavigation.solutions.map((item) => (
                      <li key={item.name} className="text-sm">
                        <a
                          href={item.href}
                          className="text-lightGray/75 font-normal hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h3 className="text-base font-semibold text-lightGray drop-shadow-lightBlue">
                    GBT Products
                  </h3>
                  <ul className="mt-2 space-y-2">
                    {footerNavigation.products.map((item) => (
                      <li key={item.name} className="text-sm">
                        <a
                          href={item.href}
                          className="text-lightGray/75 font-normal hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h3 className="text-base font-semibold text-lightGray drop-shadow-lightBlue">
                    GBT Pages
                  </h3>
                  <ul className="mt-2 space-y-2">
                    {footerNavigation.pages.map((item) => (
                      <li key={item.name} className="text-sm">
                        <a
                          href={item.href}
                          className="text-lightGray/75 font-normal hover:text-white"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h3 className="text-base font-semibold text-lightGray drop-shadow-lightBlue">
                    Contact Info
                  </h3>
                  <ul className="mt-2">
                    {contact.phone.map((item) => (
                      <li key={item.name} className="text-sm">
                        <span className="text-lightGray/75 font-normal hover:text-white">
                          <div className="flex gap-1.5">
                            <img
                              src={item.icon}
                              alt="icon"
                              className="h-5 w-5"
                            />
                            {item.content}
                          </div>
                        </span>
                      </li>
                    ))}
                  </ul>
                  <ul className="mt-2">
                    {contact.email.map((item) => (
                      <li key={item.name} className="text-sm">
                        <span className="text-lightGray/75 font-normal hover:text-white">
                          <div className="flex gap-1.5">
                            <img
                              src={item.icon}
                              alt="icon"
                              className="h-5 w-5"
                            />
                            {item.content}
                          </div>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12">
              <div className="h-12 flex justify-between items-center">
                <p className="w-full text-center sm:text-left text-xs sm:text-sm text-lightGray hover:text-white font-semibold capitalize">
                  &copy; 2024 GBT, Inc. All rights reserved.
                </p>
                <div className="hidden sm:flex sm:flex-row sm:items-center">
                  <p className="w-full text-xs sm:text-sm text-lightGray hover:text-white font-semibold">
                    Follow us:
                  </p>
                  <ul className="flex gap-4">
                    {contact.soical.map((item) => (
                      <li key={item.name} className="text-sm">
                        <Link to={item.link}>
                          <img
                            src={item.icon}
                            alt="icon"
                            className="w-10 h-10 opacity-50 hover:opacity-90"
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
