import Map from "../../components/Contact/Map";
import { contact } from "../../utils/contact";

const Address: React.FC = () => {
  return (
    <div className="w-full flex flex-col lg:flex-row cursor-default">
      <div className="w-full lg:w-1/2 h-auto xl:h-[450px] flex bg-background">
        <div className="ml-auto w-full lg:w-[650px] p-12">
          <h2 className="text-lightGray text-3xl lg:text-4xl font-bold uppercase leading-[48.76px] drop-shadow-blue">
            Our Office
          </h2>
          <p className="w-auto xl:w-[502px] text-justify text-lightGray/75 text-lg font-normal py-4 leading-6">
            Beyond the virtual walls: Discover the beating heart of GBT at
            headquarter
          </p>

          <div>
            <span className="text-white text-lg font-bold capitalize drop-shadow-lightBlue">
              address
            </span>
            <ul className="mt-2">
              {contact.address.map((item, index) => (
                <li key={index} className="text-sm">
                  <div>
                    <div className="flex flex-col">
                      <div className="text-lightGray/75 text-lg font-light hover:text-gray-200">
                        {item.location}
                      </div>

                      <div className="text-lightGray/75 text-lg font-light hover:text-gray-200">
                        {item.street}
                      </div>

                      <div className="text-lightGray/75 text-lg font-light hover:text-gray-200">
                        {item.officeDetails}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="py-4">
            <span className="text-white text-lg font-bold capitalize drop-shadow-lightBlue">
              Working Time
            </span>
            <ul className="mt-2">
              {contact.workingTime.map((item, index) => (
                <li key={index} className="text-sm">
                  <div className="flex flex-col">
                    <div className="text-lightGray/75 text-lg font-light hover:text-gray-200">
                      {item.days}
                      {item.time}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 h-[450px] ">
        <Map />
      </div>
    </div>
  );
};
export default Address;
