import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import Services from "../../components/Sections/List";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/DM-Header.svg";
import contact from "../../images/vectors/DM-Contact.svg";
import { digitalMarketing } from "../../utils/digitalMarketing";
import { logos } from "../../utils/logos";

function DigitalMarketing() {
  return (
    <div>
      <Menu />

      <Header
        title="Unleash Growth: Powerful Digital"
        subTitle="Marketing Solutions from GBT"
        miniTitle="GBT Services"
        description="The digital landscape is complex and ever-evolving. GBT's experienced digital marketing team helps you navigate it with a data-driven strategy tailored to your business goals and target audience."
        vector={header}
      />

      <GradientContainer
        className="h-[382px] md:h-[282px]"
        title="What we offer to you?"
        content="GBT offers a full spectrum of digital marketing services, customized to fit your unique business goals and target audience. Through collaboration, we develop a data-driven strategy that integrates various channels to maximize your online presence and Return on Investment (ROI)."
        description="Here's a glimpse into the core services we offer:"
      />

      <Services content={digitalMarketing.offers} />

      <GradientContainer
        className="h-[410px] md:h-[310px]"
        title="Why Partner with GBT for Your Digital Marketing Needs?"
        content="The digital marketing landscape is vast and ever-changing. Navigating it effectively requires a team that understands not only the latest trends but also how to tailor strategies to your unique business goals."
        description="Here's what sets GBT apart and why you should choose us as your trusted digital marketing partner:"
      />

      <GridItems content={digitalMarketing.reasons} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to Take Your"
        subTitle="Digital Marketing to the Next Level?"
        description="Contact GBT today and discuss your digital marketing goals with our expert team. We'll work collaboratively to develop a customized strategy that drives brand awareness, increases lead generation, and fuels consistent business growth."
        vector={contact}
        subject="Digital Marketing Services"
      />

      <Footer />
    </div>
  );
}
export default DigitalMarketing;
