import ParticlesBG from "../Home/ParticlesBG";

const GradientContainer: React.FC<any> = ({
  id,
  title,
  content,
  description,
  className,
}: {
  id: string;
  title: string;
  content: string;
  description: string;
  className: string;
}) => {
  return (
    <div id={id} className="w-full h-auto relative">
      <ParticlesBG
        id={title}
        className={`bg-gradient-to-r from-primary to-secondary ${className}`}
      />

      <div className="absolute top-0 left-0 w-full h-auto">
        <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
          <div className="flex flex-col gap-6">
            <h2 className="w-full xl:w-[1100px] text-lightGray text-2xl md:text-3xl xl:text-4xl font-semibold lg:font-bold uppercase leading-8 lg:leading-[50px] drop-shadow-blue">
              {title}
            </h2>

            <p className="text-justify text-lightGray/75 text-base lg:text-lg font-normal leading-5 lg:leading-6">
              {content}
            </p>

            {description && (
              <span className="text-white text-base lg:text-lg font-semibold lg:font-bold leading-4 lg:leading-8">
                {description}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GradientContainer;
