//offers
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";
import PaintBrush from "../images/gradientIcons/PaintBrush.svg";
import DeviceMobile from "../images/gradientIcons/DeviceMobile.svg";
import Rocket from "../images/gradientIcons/Rocket.svg";
import Translate from "../images/gradientIcons/Translate.svg";
import Lifebuoy from "../images/gradientIcons/Lifebuoy.svg";

//reasons
import users from "../images/grayIcons/UsersFour.svg";
import userFocus from "../images/grayIcons/UserFocus.svg";
import chartBar from "../images/grayIcons/ChartBar.svg";
import chartDonut from "../images/grayIcons/ChartDonut.svg";
import handshake from "../images/grayIcons/Handshake.svg";
import headset from "../images/grayIcons/Headset.svg";

export const cms = {
  offers: [
    {
      number: "1",
      icon: Lightbulb,
      title: "Strategic CMS Selection & Customization",
      description:
        "GBT helps you choose the ideal CMS platform (WordPress, Drupal, Joomla!, etc.) based on your specific content needs, website goals, and future growth plans. We then customize the platform with features, functionalities, and integrations that perfectly match your workflow and content management requirements.",
    },
    {
      number: "2",
      icon: PaintBrush,
      title: "Custom CMS Theme Design & template Development",
      description:
        "Our team of web experts can build dynamic website templates as a complete reflection of your ideas. We build aesthetic web designs that appeal to your target audience. From wireframes and prototyping to custom CMS template, we’re your one-stop-shop for all your web application development requirements.",
    },
    {
      number: "3",
      icon: DeviceMobile,
      title: "Responsive Design for All Devices",
      description:
        "It's crucial for your website and all content to display flawlessly across all devices. Our development team utilizes responsive design principles to ensure your website and content adapt seamlessly to different screen sizes and touch interactions. This delivers a consistent and engaging user experience for your audience regardless of how they choose to access your website.",
    },
    {
      number: "4",
      icon: Rocket,
      title: "SEO Optimization is Essential",
      description:
        "Organic traffic is key to long-term content marketing success. Our CMS development incorporates best practices for search engine optimization (SEO), helping your content rank higher in relevant search results and attract organic traffic from potential customers actively searching for the information and solutions you offer.",
    },
    {
      number: "5",
      icon: Translate,
      title: "Multilingual Support for Global Expansion",
      description:
        "The world is your market. We can extend the reach of your content by enabling multilingual capabilities on your CMS. This allows you to deliver content in multiple languages, catering to a wider audience and expanding your global presence.",
    },
    {
      number: "6",
      icon: Lifebuoy,
      title: "Ongoing Support & Maintenance",
      description:
        "We understand that your Content Management System requires ongoing maintenance and support. Our team provides comprehensive support plans to ensure your online store stays up-to-date, secure, and functioning smoothly.",
    },
  ],

  reasons: [
    {
      icon: users,
      title: "Experienced & Skilled Developers",
      description:
        "Our team boasts extensive experience in CMS development and customization across various platforms like WordPress, Drupal, Joomla!, and headless CMS solutions. We understand the nuances of each platform and can recommend the ideal fit for your specific needs.",
    },
    {
      icon: userFocus,
      title: "Focus on User Experience (UX)",
      description:
        "Our CMS solutions feature intuitive interfaces that streamline content creation and management for authorized users, regardless of their technical background. Additionally, we offer custom template design services to create visually appealing websites that capture your brand essence and deliver a captivating user experience for your audience.",
    },
    {
      icon: chartBar,
      title: "Future-Proof Solutions",
      description:
        "We understand that businesses evolve. Our CMS solutions are designed to scale alongside your growing content needs and website traffic. We leverage future-proof technologies and ensure your CMS can adapt to accommodate your evolving requirements.",
    },
    {
      icon: chartDonut,
      title: "Data-Driven Approach",
      description:
        "We believe in data-driven decision making. We can integrate analytics tools with your CMS to provide valuable insights into user behavior and content performance. This data allows you to optimize your content strategy and maximize the impact of your content marketing efforts.",
    },
    {
      icon: handshake,
      title: "Strategic Partnership",
      description:
        "We don't just build a CMS; we become your trusted partner. We take the time to understand your unique content management challenges, website goals, and long-term vision. This collaborative approach ensures a CMS solution that aligns perfectly with your business strategy and empowers your content marketing success.",
    },
    {
      icon: headset,
      title: "Dedicated Customer Support",
      description:
        "Our commitment extends beyond initial development. We offer comprehensive ongoing support plans to ensure your CMS remains secure, up-to-date, and functioning smoothly. Our dedicated team is available 24/7 to address any technical issues or answer your questions promptly.",
    },
  ],
};
