import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import VectorList from "../../components/Sections/VectorList";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Network-Header.svg";
import contact from "../../images/vectors/Network-Contact.svg";
import switchDevice from "../../images/vectors/Switch.svg";
import wifi from "../../images/vectors/Wifi.svg";
import { network } from "../../utils/network";
import { logos } from "../../utils/logos";

function Network() {
  return (
    <div>
      <Menu />

      <Header
        title="GBT Network Essentials:"
        subTitle="Powering Your Connected World"
        miniTitle="GBT Products"
        description="In today's digital age, a robust and reliable network is the backbone of any successful business. GBT understands this critical need and offers a comprehensive portfolio of Network Essentials, including high-performance network switches and secure Wi-Fi routers."
        vector={header}
      />

      <GradientContainer
        className="h-[421px] md:h-[321px]"
        title="Unveiling the Power of GBT Network Essentials:"
        content="The foundation of any successful business in today's digital world is a robust and reliable network. GBT's Network Essentials portfolio goes beyond just switches and routers; it offers a comprehensive solution designed to empower your business with exceptional connectivity, security, manageability, and the ability to scale effortlessly alongside your growth."
        description="Let's delve deeper into the specific strengths of our Network Essentials:"
      />

      <VectorList
        className="bg-sky"
        itemBackground="lightGray"
        vector={switchDevice}
        vecLocation="start"
        title="Network Switches:"
        subTitle="The Backbone of Your Network"
        description="Network switches may not be the flashiest piece of equipment, but they play a critical role in ensuring your network runs smoothly and efficiently. Think of them as the silent conductors of your digital orchestra, directing data traffic seamlessly behind the scenes."
        content={network.switches}
        intro="Here's how GBT network switches elevate your network performance:"
      />

      <VectorList
        className="bg-lightGray"
        itemBackground="sky"
        vector={wifi}
        vecLocation="end"
        title="Wi-Fi Routers:"
        subTitle="Blazing-Fast & Secure Wi-Fi"
        description="In today's digital workplace, reliable and secure Wi-Fi is no longer a luxury, it's a necessity.  Your employees, point-of-sale systems, and even customer devices depend on a strong wireless connection to operate efficiently."
        content={network.routers}
        intro="GBT Wi-Fi routers are engineered to deliver on this crucial need, offering 3 key benefits:"
      />

      <GradientContainer
        className="h-[368px] md:h-[268px]"
        title="Why Choose GBT Network Essentials?"
        content="When it comes to your network infrastructure, choosing the right partner is critical. GBT Network Essentials goes beyond just offering high-quality network switches and Wi-Fi routers. We provide a comprehensive solution backed by experience, expertise, and a commitment to your success."
        description="Here's what sets GBT Network Essentials apart:"
      />

      <GridItems content={network.aparts} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Secure Your"
        subTitle="Network & Elevate Your Business"
        description="Contact GBT today to explore how our Network Essentials can empower your business with a robust, secure, and scalable network foundation."
        vector={contact}
        subject="Network Items Product"
      />

      <Footer />
    </div>
  );
}
export default Network;
