import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Solutions from "../../components/Sections/List";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/CRM-Header.svg";
import contact from "../../images/vectors/CRM-Contact.svg";
import { crm } from "../../utils/crm";
import { logos } from "../../utils/logos";

function CRM() {
  return (
    <div>
      <Menu />

      <Header
        title="Unleash Your CRM Potential:"
        subTitle="Introducing CentraHub CRM"
        miniTitle="GBT Solutions"
        description="CentraHub CRM is a user-friendly customer relationship management (CRM) solution designed to help businesses manage customer relationships, streamline sales processes, and gain valuable customer insights."
        vector={header}
      />

      <GradientContainer
        className="h-[442px] md:h-[342px]"
        title="how CentraHub CRM empowers customer relationships in your Business"
        content="CentraHub CRM goes beyond contact management, acting as a strategic partner to cultivate lasting customer relationships. It centralizes all customer interactions, tracks communication history, and personalizes outreach efforts throughout the customer lifecycle. This fosters deeper connections and allows you to understand customer needs for a more engaging and personalized experience, ultimately driving higher satisfaction and loyalty."
        description="it's a strategic partner that helps you in:"
      />

      <Solutions content={crm.offers} />

      <GradientContainer
        className="h-[396px] md:h-[296px]"
        title="Why CentraHub CRM is the Perfect Solution for Your Business"
        content="CentraHub CRM goes beyond the basics of contact management, offering a powerful suite of features designed to streamline your operations, boost sales, and cultivate stronger customer relationships."
        description="Here's why CentraHub CRM might be the ideal fit for your business:"
      />

      <GridItems content={crm.reasons} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to Boost"
        subTitle="Your Customer Relationships?"
        description="Contact GBT today for a free consultation and discover how CentraHub CRM can empower your business to achieve its full potential."
        vector={contact}
        subject="Customer Relationship Management (CRM) Solutions"
      />

      <Footer />
    </div>
  );
}
export default CRM;
