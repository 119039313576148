import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import Vector from "../../components/Sections/VectorSwitch";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Switch-Header.png";
import S2982G from "../../images/Switches/S2982G.png";
import S2985G from "../../images/Switches/S2985G.png";
import S2989G from "../../images/Switches/S2989G.png";
import S2995G from "../../images/Switches/S2995G.png";
import S300X from "../../images/Switches/S300X.png";
import S5210X from "../../images/Switches/S5210X.png";
import { switches } from "../../utils/switch";

function Switch() {
  return (
    <div>
      <Menu />

      <Header
        title="Managed Ethernet Switches"
        subTitle="SNR Series"
        miniTitle="GBT Products"
        description="Explore our latest range of high-performance SNR Managed Ethernet Switches, designed to meet the networking needs of businesses, enterprises and individuals."
        vector={header}
      />

      <GradientContainer
        className="h-[421px] md:h-[321px]"
        title="Managed Ethernet Switches – SNR Series:"
        content="The SNR Series of managed Ethernet switches provides robust networking solutions designed to enhance performance and control in both enterprise and industrial environments."
        description="Featuring advanced Layer 2 and Layer 3 management capabilities:"
      />

      <Vector
        className="bg-sky"
        itemBackground="lightGray"
        vector={S2982G}
        vecLocation="start"
        title="SNR Series S2982G"
        description="The SNR S2982G series are Layer 2 managed Gigabit Ethernet switches designed for ISPs and enterprises. Key features include PoE+ support, power backup, lightning protection, QoS management, multicast control, and advanced security protocols. Models vary from 8 to 24 ports with options for PoE, DC, UPS, and RPS power supplies. They ensure high performance with non-blocking switching, multiple VLAN options, and IPv6 support."
        content={switches.S2982G}
        pdf="S2982G.pdf"
      />

      <Vector
        className="bg-lightGray"
        itemBackground="sky"
        vector={S2985G}
        vecLocation="end"
        title="SNR Series S2985G"
        description="The SNR-S2985G series are Layer 2 managed Gigabit Ethernet switches designed for ISPs and enterprise networks. They feature non-blocking architecture, PoE support, advanced security (ACLs, 802.1x), multicast management, QoS with 8 queues per port, and redundancy protocols (STP, ERPS). Models vary with up to 48 ports, combo options, and backup power supplies, ensuring high performance, easy management (CLI, Web, SNMP), and enhanced resilience."
        content={switches.S2985G}
        pdf="S2985G.pdf"
      />

      <Vector
        className="bg-sky"
        itemBackground="lightGray"
        vector={S2989G}
        vecLocation="start"
        title="SNR Series S2989G"
        description="These switches offer high performance with non-blocking switching, advanced security features (ACLs, MAC binding, 802.1x), multicast management, QoS with 8 hardware queues per port, and IPv4/IPv6 support. They also support stacking via VSF for scalability, and management through CLI, Web, SNMP, and more. Various models provide different port configurations to fit diverse network needs."
        content={switches.S2989G}
        pdf="S2989G.pdf"
      />

      <Vector
        className="bg-lightGray"
        itemBackground="sky"
        vector={S2995G}
        vecLocation="end"
        title="SNR Series S2995G"
        description="The SNR-S2995G series consists of advanced L3 managed switches designed for both internet service providers and enterprise networks, featuring a combination of optical and copper interfaces, 10GE uplink ports, and robust L2 and L3 functionalities. Key features include dynamic routing protocols (OSPF, BGP, PIM), comprehensive security measures, and powerful quality of service (QoS) management. The switches offer various models with multiple port configurations, supporting PoE standards, IPv4/IPv6 hardware routing, multicast management, and stacking capabilities for enhanced network reliability. With a modern chipset, they ensure high performance and low latency, while also supporting extensive security features such as Access Control Lists (ACLs), MAC-IP-Port binding, and 802.1x authentication, making them suitable for high-quality services like IPTV and efficient network management."
        content={switches.S2995G}
        pdf="S2995G.pdf"
      />

      <Vector
        className="bg-sky"
        itemBackground="lightGray"
        vector={S300X}
        vecLocation="start"
        title="SNR Series S300X"
        description="The SNR-S300X-24FQ is a stackable L3 managed switch designed for high-load networks, featuring advanced hardware and software capabilities. It supports dynamic routing protocols such as OSPF, BGP, and PIM, with a routing table capacity of 16K IPv4 routes and 16K ARP entries. The switch includes 24 1/10GE SFP+ ports and 2 40GbE QSFP+ ports, providing a total switching capacity of 656 Gbps. Key features include robust multicast management, quality of service (QoS) capabilities, extensive security options, and stacking support for up to 4 switches. It operates under the SNR NOS, ensuring comprehensive management via CLI, SNMP, and web interfaces, making it ideal for enterprise and service provider networks. The switch is designed for operational resilience, offering power supply backup options and a variety of VLAN and multicast functionalities, all within a compact form factor."
        content={switches.S300X}
        pdf="S300X.pdf"
      />

      <Vector
        className="bg-lightGray"
        itemBackground="sky"
        vector={S5210X}
        vecLocation="end"
        title="SNR Series S5210X"
        description="The SNR S5210 series is a line of Layer 2+ managed switches designed for access-level deployment in enterprise and ISP networks. Key features include 1/10GE SFP+ uplink ports, a non-blocking switching matrix for high performance, and comprehensive security options such as built-in lightning protection and ACLs. The series offers various models with configurations ranging from 8 to 24 ports, supporting Power over Ethernet (PoE) and backup power solutions. Enhanced multicast management, Quality of Service (QoS), and resilience through standard protocols ensure efficient network operations. The intuitive CLI allows for straightforward configuration and management."
        content={switches.S5210X}
        pdf="S5210X.pdf"
      />

      <Footer />
    </div>
  );
}
export default Switch;
