import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import Solutions from "../../components/Sections/List";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Ecommerce-Header.svg";
import contact from "../../images/vectors/Ecommerce-Contact.svg";
import { ecommerce } from "../../utils/ecommerce";
import { logos } from "../../utils/logos";

function ECommerce() {
  return (
    <div>
      <Menu />

      <Header
        title="Elevate Your Online Store: E-commerce"
        subTitle="Development Solutions"
        miniTitle="GBT Solutions"
        description="In today's digital age, a robust e-commerce platform is no longer a luxury, it's a necessity.  Whether you're a seasoned online retailer or just starting your journey, GBT is here to empower your success with comprehensive e-commerce development solutions."
        vector={header}
      />

      <GradientContainer
        className="h-[357px] md:h-[257px]"
        title="how GBT empowers your e-commerce success"
        content="GBT offers comprehensive E-Commerce Development Solutions to help businesses of all sizes build and manage successful online stores."
        description="Here's how GBT empowers your e-commerce success:"
      />

      <Solutions content={ecommerce.offers} />

      <GradientContainer
        className="h-[410px] md:h-[310px]"
        title="Why Choose GBT for Your E-commerce Development Needs?"
        content="IIn the ever-evolving world of e-commerce, choosing the right development partner can be the difference between thriving online and simply existing."
        description="Here's why GBT stands out as the ideal partner to elevate your online store and propel your sales:"
      />

      <GridItems content={ecommerce.reasons} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to take your"
        subTitle="online store to the next level?"
        description="Contact us today for a free consultation. Let's discuss your e-commerce goals and explore how our comprehensive development solutions can help you build a thriving online business."
        vector={contact}
        subject="E-Commerce Development Solutions"
      />

      <Footer />
    </div>
  );
}
export default ECommerce;
