import { Link } from "react-router-dom";

const ContactUsNow: React.FC<any> = ({
  title,
  description,
  vector,
  subject,
}: {
  title: string;
  description: string;
  vector: any;
  subject: string;
}) => {
  return (
    <div className="w-full bg-lightGray cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col-reverse md:flex-row items-center gap-8 xl:gap-32">
          <div className="w-auto md:w-[500px] my-6">
            <div className="font-extrabold uppercase leading-7 xl:leading-[56px] drop-shadow-lightBlue">
              <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-3xl xl:text-5xl">
                {title}
                <br />
              </span>
              <span className="text-dark text-3xl xl:text-4xl">
                {description}
              </span>
            </div>

            <Link
              to={`/contact?subject=${subject}#contactForm`}
              className="w-full md:w-[340px] xl:w-[480px] my-4 p-4 bg-gradient-to-r from-dark to-secondary rounded-t-md rounded-bl-md drop-shadow-blackSm flex justify-center items-center brightness-90 hover:brightness-100"
            >
              <span className="text-lightGray hover:text-white text-base lg:text-lg font-bold uppercase">
                Contact Us Now!
              </span>
            </Link>
          </div>

          <img
            className="w-auto md:w-[340px] xl:w-[540px] transition ease-out delay-150 hover:translate-x-5 hover:scale-110 duration-300"
            src={vector}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default ContactUsNow;
