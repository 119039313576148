//tools
import microsoft from "../images/logos/microsoft.png";
import veritas from "../images/logos/veritas.png";
import vmware from "../images/logos/vmware.png";
import nutanix from "../images/logos/nutanix.png";
import fortinet from "../images/logos/fortinet.png";
import redhat from "../images/logos/redhat.png";

//offers
import CursorClick from "../images/gradientIcons/CursorClick.svg";
import Lifebuoy from "../images/gradientIcons/Lifebuoy.svg";
import GraduationCap from "../images/gradientIcons/GraduationCap.svg";

//choices
import cart from "../images/grayIcons/ShoppingCartSimple.svg";
import piggy from "../images/grayIcons/PiggyBank.svg";
import shield from "../images/grayIcons/ShieldCheck.svg";
import lifebuoy from "../images/grayIcons/Lifebuoy.svg";
import rocket from "../images/grayIcons/Rocket.svg";

export const licenses = {
  tools: [
    {
      icon: microsoft,
      description:
        "Maximize your productivity and collaboration with Microsoft software licenses. Our team assists you in acquiring the right Microsoft licenses, including Windows Server, Microsoft Office 365, and Azure cloud solutions, to empower your workforce.",
    },
    {
      icon: veritas,
      description:
        "Ensure data protection and availability with Veritas software licenses. We offer expertise in Veritas Backup Exec, NetBackup, and other solutions, helping you implement robust data backup and recovery strategies.",
    },
    {
      icon: vmware,
      description:
        "Boost your IT agility and efficiency with VMware software licenses. Our team helps you select the ideal VMware solutions, including VMware vSphere, VMware Workstation, and more, to enable virtualization and streamline your IT infrastructure.",
    },
    {
      icon: nutanix,
      description:
        "Harness the power of enterprise database management with Oracle software licenses. We provide guidance on acquiring the right Oracle licenses, including Oracle Database, Oracle Cloud Infrastructure, and other solutions, to optimize your database management processes.",
    },
    {
      icon: fortinet,
      description:
        "Fortify your network security with Fortinet software licenses. We help you choose the most appropriate Fortinet solutions, including firewalls, intrusion prevention systems, and endpoint security software, to safeguard your critical assets.",
    },
    {
      icon: redhat,
      description:
        "Unlock the power of open-source enterprise solutions with Red Hat licenses. We help you navigate Red Hat's comprehensive software portfolio, including Red Hat Enterprise Linux, Red Hat OpenShift, and more, to find the ideal solutions for your specific needs.",
    },
  ],

  offers: [
    {
      number: "1",
      icon: CursorClick,
      title: "Software selection and implementation",
      description:
        "We can help you choose the right software solutions and guide you through the implementation process.",
    },
    {
      number: "2",
      icon: Lifebuoy,
      title: "Technical support",
      description:
        "Our team is available to provide ongoing technical support for the software you license through GBT.",
    },
    {
      number: "3",
      icon: GraduationCap,
      title: "Training and education",
      description:
        "We offer training programs to help your users get the most out of the software they are using.",
    },
  ],

  choices: [
    {
      icon: cart,
      title: "Simplified Procurement and Management",
      description:
        "Say goodbye to the complexities of software license procurement and management. Our team handles everything from understanding licensing options and negotiating pricing to streamlining acquisition and maintaining a centralized license inventory. Focus on your business, not software licenses.",
    },
    {
      icon: piggy,
      title: "Cost Optimization & Savings",
      description:
        "Don't overpay for software licenses. Our software licensing experts analyze your current licenses to identify opportunities for cost savings. We may recommend alternative licensing editions, consolidation of licenses, or subscription models that better suit your needs, helping you optimize your software budget.",
    },
    {
      icon: shield,
      title: "Compliance Assurance",
      description:
        "Stay on the right side of software licensing regulations and avoid potential risks. Our team's deep understanding of licensing agreements ensures you are using software compliantly. We help you navigate complex licensing terms and conditions, providing peace of mind and avoiding costly non-compliance penalties.",
    },
    {
      icon: lifebuoy,
      title: "Expert Guidance & Support",
      description:
        "Benefit from the knowledge and experience of our dedicated software licensing specialists. We stay up-to-date on the latest licensing trends and regulations, ensuring you receive the most accurate and relevant advice. Our team is here to answer your questions, address your concerns, and provide ongoing support throughout the entire software licensing process.",
    },
    {
      icon: rocket,
      title: "Focus on Your Business",
      description:
        "Delegate time-consuming software licensing tasks to our experts. We handle everything from procurement to management, allowing you to focus on your core business priorities. With GBT as your software licensing partner, you can free up your team's valuable time and resources while ensuring your software needs are met efficiently and compliantly.",
    },
    {
      title: "",
      description: "",
    },
  ],
};
