//Phases
import Eye from "../images/gradientIcons/Eye.svg";
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";
import ProjectorScreenChart from "../images/gradientIcons/ProjectorScreenChart.svg";

//Solutions
import PlugsConnected from "../images/gradientIcons/PlugsConnected.svg";
import ShieldCheck from "../images/gradientIcons/ShieldCheck.svg";
import PaintBrush from "../images/gradientIcons/PaintBrush.svg";

//Support
import Gears from "../images/gradientIcons/Gears.svg";
import Lifebuoy from "../images/gradientIcons/Lifebuoy.svg";

//benefits
import Factory from "../images/grayIcons/Factory.svg";
import City from "../images/grayIcons/City.svg";
import Package from "../images/grayIcons/Package.svg";
import Truck from "../images/grayIcons/Truck.svg";
import Heartbeat from "../images/grayIcons/Heartbeat.svg";
import Grains from "../images/grayIcons/Grains.svg";

export const iot = {
  phases: [
    {
      number: "1",
      icon: Eye,
      title: "Understanding Your Vision",
      description:
        "We begin by collaborating closely with you to gain a deep understanding of your vision for leveraging IoT technology. This involves discussions about your business goals, operational challenges, and desired outcomes. By actively listening and asking insightful questions, we uncover the opportunities where IoT can create the most significant impact for your organization.",
    },
    {
      number: "2",
      icon: Lightbulb,
      title: "Identifying Applications & Use Cases",
      description:
        "Through brainstorming sessions and industry expertise, we work together to identify the most impactful applications of IoT for your specific industry and business model. This might involve optimizing production lines in a manufacturing facility, creating a more data-driven approach to energy management in a commercial building, or developing connected products that enhance customer experience in the retail sector.",
    },
    {
      number: "3",
      icon: ProjectorScreenChart,
      title: "Feasibility Assessment & ROI Analysis",
      description:
        "We don't just propose ideas; we believe in data-driven decision making. Our team conducts a thorough feasibility assessment to analyze the technical and financial viability of your desired IoT solution. We provide a clear ROI (Return on Investment) analysis, outlining the potential cost savings, increased efficiency, and revenue generation opportunities associated with your IoT implementation.",
    },
  ],

  solutions: [
    {
      number: "1",
      icon: PlugsConnected,
      title: "Sensor Integration & Device Connectivity",
      description:
        "Our experienced engineers are experts in integrating various sensors and devices with the chosen network infrastructure. This may involve temperature sensors for environmental monitoring, RFID tags for asset tracking, or smart meters for energy management. We ensure seamless connectivity and reliable data transmission for optimal performance.",
    },
    {
      number: "2",
      icon: ShieldCheck,
      title: "Secure Data Management & Analytics",
      description:
        "Data is the lifeblood of any IoT solution. We design secure data management platforms that collect, store, and analyze data from your connected devices. Our team utilizes advanced analytics tools to extract valuable insights and generate actionable reports that inform decision-making and optimize your operations.",
    },
    {
      number: "3",
      icon: PaintBrush,
      title: "Customizable Dashboards & User Interfaces",
      description:
        "We prioritize user experience. Our development team creates intuitive and user-friendly dashboards that provide real-time data visualization and actionable insights. These dashboards can be customized to fit your specific needs and ensure your team has the information they need at their fingertips.",
    },
  ],

  support: [
    {
      number: "1",
      icon: Gears,
      title: "System Integration & Legacy Infrastructure Considerations",
      description:
        "Many businesses have existing infrastructure and software. Our team of experts are adept at integrating your IoT solution seamlessly with your current systems and platforms. We ensure smooth data exchange and operation, allowing your new IoT solution to work cohesively with your existing technology stack.",
    },
    {
      number: "2",
      icon: Lifebuoy,
      title: "Deployment & Ongoing Maintenance",
      description:
        "We don't abandon you after implementation. Our team provides comprehensive deployment services to ensure your IoT solution is up and running smoothly. We also offer ongoing maintenance plans to keep your system secure, updated, and functioning optimally.",
    },
  ],

  benefits: [
    {
      icon: Factory,
      title: "Manufacturing",
      description:
        "Optimize production lines, monitor equipment health, and predict maintenance needs for improved efficiency and reduced downtime.",
    },
    {
      icon: City,
      title: "Smart Cities",
      description:
        "Create intelligent infrastructure that manages traffic flow, optimizes energy use in buildings, and enhances public safety.",
    },
    {
      icon: Package,
      title: "Retail",
      description:
        "Enhance customer experience with connected products, personalized recommendations, and real-time inventory tracking.",
    },
    {
      icon: Truck,
      title: "Supply Chain & Logistics",
      description:
        "Track assets in real-time, optimize delivery routes, and ensure product quality throughout the supply chain.",
    },
    {
      icon: Heartbeat,
      title: "Healthcare",
      description:
        "Remote patient monitoring, preventative maintenance of medical equipment, and improved data collection for better patient care.",
    },
    {
      icon: Grains,
      title: "Agriculture",
      description:
        "Precision agriculture techniques with real-time monitoring of soil conditions, crop health, and irrigation needs.",
    },
  ],
};
