//Switches
import Gauge from "../images/gradientIcons/Gauge.svg";
import Wrench from "../images/gradientIcons/Wrench.svg";
import Lego from "../images/gradientIcons/Lego.svg";

//Routers
import WifiHigh from "../images/gradientIcons/WifiHigh.svg";
import ShieldCheck from "../images/gradientIcons/ShieldCheck.svg";
import Gear from "../images/gradientIcons/Gear.svg";

//Aparts
import certificate from "../images/grayIcons/Certificate.svg";
import lifebuoy from "../images/grayIcons/Lifebuoy.svg";
import infinity from "../images/grayIcons/Infinity.svg";

export const network = {
  switches: [
    {
      number: "1",
      icon: Gauge,
      title: "High-Performance Connectivity",
      description:
        "GBT network switches are built for speed. They boast high port capacity and low latency, ensuring smooth and uninterrupted data flow even under heavy network loads. This translates to a frustration-free experience for your employees, eliminating lag and ensuring seamless operation for bandwidth-intensive applications like video conferencing, cloud storage access, and real-time data processing.",
    },
    {
      number: "2",
      icon: Lego,
      title: "Enhanced Scalability",
      description:
        "No need to worry about outgrowing your network infrastructure. Our modular switch solutions allow you to start with a configuration that meets your current needs and easily add additional ports as your business expands. This eliminates the need for costly rip-and-replace scenarios and ensures your network can seamlessly adapt to accommodate future growth.",
    },
    {
      number: "3",
      icon: Wrench,
      title: "Advanced Features for Optimal Network Management",
      description:
        "GBT network switches offer a comprehensive suite of advanced features to optimize your network performance and security. For example, Quality of Service (QoS) prioritizes critical traffic, built-in security features safeguard your network, and flexible management options (web-based interfaces and CLI) allow you to configure and monitor your network according to your preferences.",
    },
  ],

  routers: [
    {
      number: "1",
      icon: WifiHigh,
      title: "Unmatched Wi-Fi Coverage",
      description:
        "Say goodbye to dead zones and frustrating signal drops.  GBT Wi-Fi routers are engineered to deliver extensive wireless coverage, ensuring a strong and stable internet connection throughout your entire work environment.  This is especially crucial for businesses with large office spaces, warehouses, or retail stores where reliable Wi-Fi access is vital for employees, point-of-sale systems, and customer devices.",
    },
    {
      number: "2",
      icon: ShieldCheck,
      title: "Enhanced Security",
      description:
        "GBT Wi-Fi routers prioritize data security with a multi-layered approach. WPA3 encryption safeguards your network from unauthorized access, guest network access provides isolation for visitors, and parental controls enable you to manage internet access for connected devices.",
    },
    {
      number: "3",
      icon: Gear,
      title: "Seamless Management",
      description:
        "Managing your network shouldn't be a hassle. Our user-friendly Wi-Fi routers offer simple configuration and intuitive network management tools accessible from any device through a web-based interface. Monitor network performance, manage connected devices, and configure security settings with ease, saving you valuable time and resources.",
    },
  ],

  aparts: [
    {
      icon: certificate,
      title: "Experience and Expertise",
      description:
        "GBT has a proven track record of delivering high-quality networking solutions. Our team of experts can assist you in selecting the ideal network switches and Wi-Fi routers to perfectly match your specific business needs.",
    },
    {
      icon: lifebuoy,
      title: "Comprehensive Support",
      description:
        "We offer comprehensive pre-sales and post-sales support, ensuring a smooth implementation process and ongoing technical assistance whenever you need it.",
    },
    {
      icon: infinity,
      title: "Scalable Solutions",
      description:
        "GBT Network Essentials are designed to grow alongside your business. Our solutions are modular and offer a variety of upgrade paths, allowing you to seamlessly adapt your network infrastructure as your needs evolve.",
    },
  ],
};
