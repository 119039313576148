//benefits
import lifebuoy from "../images/grayIcons/Lifebuoy.svg";
import circuitry from "../images/grayIcons/Circuitry.svg";
import globe from "../images/grayIcons/Globe.svg";

//services
import OperatingSystem from "../images/training/OperatingSystem.svg";
import ChatBot from "../images/training/ChatBot.svg";
import AppDevelopment from "../images/training/AppDevelopment.svg";
import ComputerTroubleshoot from "../images/training/ComputerTroubleshoot.svg";
import DataReport from "../images/training/DataReport.svg";
import Server from "../images/training/Server.svg";

//features
import Circuitry from "../images/gradientIcons/Circuitry.svg";
import GraduationCap from "../images/gradientIcons/GraduationCap.svg";
import ChartLine from "../images/gradientIcons/ChartLine.svg";
import UsersFour from "../images/gradientIcons/UsersFour.svg";
import Heart from "../images/gradientIcons/Heart.svg";
import Tree from "../images/gradientIcons/Tree.svg";

export const training = {
  benefits: [
    {
      icon: lifebuoy,
      title: "Support from Experts",
      description:
        "Learn from the best and brightest in the industry. Our experts share their wisdom, not just their code, guiding you with personalized support and real-world insights.",
    },
    {
      icon: circuitry,
      title: "Cutting-Edge Tools",
      description:
        "Master the latest technologies through immersive programs and industry-leading certifications. Think AI, cloud computing, cybersecurity – the sky's (and the network) the limit.",
    },
    {
      icon: globe,
      title: "Real-World Projects",
      description:
        "Experience isn't just a word on your resume; it's woven into everything we do. Get your hands dirty on real-world projects, collaborating with seasoned professionals and feeling the thrill of innovation firsthand.",
    },
  ],

  services: [
    {
      number: "1",
      vector: OperatingSystem,
      title: "Customer Software Training (Microsoft, Oracle, Redhat, ...)",
      description:
        "GBT provides comprehensive training on various software platforms, including Microsoft, Oracle, and Redhat. This training covers everything from basic to advanced topics like system administration, database management, and software development. Participants can gain proficiency in these technologies to enhance their career prospects and productivity.",
    },
    {
      number: "2",
      vector: DataReport,
      title: "BI & Data Analytics Tools Training (Tableau & Power BI)",
      description:
        "The company offers training in popular business intelligence (BI) and data analytics tools like Tableau and Power BI. Participants can learn to create insightful data visualizations, perform data analysis, and generate reports and dashboards. This training is valuable for professionals seeking to harness the power of data for decision-making.",
    },
    {
      number: "3",
      vector: AppDevelopment,
      title: "Mobile Application Development Tools Training",
      description:
        "Grand Block Technologies provides training in various mobile application development tools and platforms, such as Android Studio, iOS development with Swift, and cross-platform development with tools like Flutter or React Native. Participants can acquire the skills needed to build mobile apps for Android and iOS platforms.",
    },
    {
      number: "4",
      vector: ComputerTroubleshoot,
      title: "Hardware Products Training (HP, Dell, Lenovo, etc.)",
      description:
        "The company offers training on hardware products from leading manufacturers like HP, Dell, Lenovo, and others. This training may include hardware maintenance, troubleshooting, and system configuration. IT professionals and technicians can benefit from these courses to effectively manage and support hardware infrastructure.",
    },
    {
      number: "5",
      vector: ChatBot,
      title: "AI Development Tools Training",
      description:
        "Grand Block Technologies offers training in AI development tools and frameworks like TensorFlow, PyTorch, and scikit-learn. Participants can learn how to develop machine learning models, natural language processing applications, and computer vision solutions. AI development skills are in high demand across various industries.",
    },
    {
      number: "6",
      vector: Server,
      title: "Big Data & Data Warehousing Solutions Training",
      description:
        "The company provides training in big data technologies like Hadoop, Spark, and data warehousing solutions like Amazon Redshift or Microsoft Azure SQL Data Warehouse. Participants can gain expertise in managing and analyzing large datasets, which is critical for data-driven decision-making in today's business landscape.",
    },
  ],

  features: [
    {
      number: "1",
      icon: Circuitry,
      title: "Master the future with cutting-edge tech tools",
      description:
        "Dive into the hottest tech trends like AI, cloud computing, and cybersecurity through immersive programs and industry-leading certifications. No dusty textbooks here, just practical skills that fuel real-world impact.",
    },
    {
      number: "2",
      icon: GraduationCap,
      title: "Mentorship and Guidance",
      description:
        "Our team is a constellation of tech wizards and seasoned veterans, eager to share their wisdom and guide you on your tech ascent. Forget bosses; you'll find mentors who celebrate your every step and push you to reach even higher.",
    },
    {
      number: "3",
      icon: ChartLine,
      title: "Real-world Experience, not just theory",
      description:
        "Get out of the classroom and into the cockpit! Collaborate on cutting-edge projects, tackle real-world challenges, and feel the adrenaline rush of innovation firsthand. Your resume won't just list skills; it'll showcase achievements that speak volumes.",
    },
    {
      number: "4",
      icon: UsersFour,
      title: "Diversity and community are our strength",
      description:
        "We celebrate every unique mind, from code whisperers to creative storytellers. Whether you're a seasoned pro or a tech rookie, you'll find your place in our inclusive tribe, where collaboration sparks creativity and ideas take flight.",
    },
    {
      number: "5",
      icon: Heart,
      title: "Work-life harmony and well-being, not just a buzzword",
      description:
        "We believe in happy lives, not just productive ones. Enjoy flexible schedules, generous benefits, and an office environment that feels more like a playground for the mind. Leave the stress at the door and embrace a work-life balance that fuels your passion.",
    },
    {
      number: "6",
      icon: Tree,
      title: "Make a difference, beyond the bottom line",
      description:
        "Technology isn't just about algorithms; it's about shaping a better future. At GBT, we're passionate about sustainability, community initiatives, and using tech for good. Your work with us will have a ripple effect, leaving a positive mark on the world.",
    },
  ],
};
