import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridBackground from "../../components/Sections/GridItems";
import Solutions from "../../components/Sections/List";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/VAS-Header.svg";
import contact from "../../images/vectors/VAS-Contact.svg";
import { vas } from "../../utils/vas";
import { logos } from "../../utils/logos";

function VAS() {
  return (
    <div>
      <Menu />

      <Header
        title="Unleash the Power of VAS: gbt's Wide-"
        subTitle="Ranging Service Delivery Platform"
        miniTitle="GBT Solutions"
        description="In today's dynamic business landscape, staying ahead of the curve requires agility and innovation.  GBT's VAS Solution empowers you with a robust and versatile service delivery platform, designed to seamlessly manage and deliver a wide range of value-added services (VAS) to your customers."
        vector={header}
      />

      <GradientContainer
        className="h-[421px] md:h-[321px]"
        title="gbt's Wide-Ranging Service Delivery Platform Solution is Beyond Traditional Services"
        content="VAS (Value-Added Services) encompasses a diverse range of services that extend beyond your core offerings, enhancing customer experience, driving loyalty, and unlocking new revenue streams."
        description="With GBT's VAS Solution, you can:"
      />

      <Solutions content={vas.offers} />

      <GradientContainer
        className="h-[442px] md:h-[342px]"
        title="Benefits of GBT's VAS Solution: A Multi-Faceted Advantage for Your Business"
        content="This section delves into the tangible advantages your business can reap by implementing this versatile platform. and how GBT’s VAS solution provides a comprehensive solution that can enhance customer experience, unlock new revenue streams, improve operational efficiency, and empower you to make smarter business decisions based on valuable customer data."
        description="Here's a breakdown of the key benefits highlighted:"
      />

      <GridBackground content={vas.benefits} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to unlock"
        subTitle="the full potential of VAS by GBT?"
        description="Contact GBT today to schedule a free consultation and discover how our VAS Solution can empower you to deliver exceptional customer experiences, explore new revenue opportunities, and achieve business success."
        vector={contact}
        subject="Value-Added Services (VAS) Solutions"
      />

      <Footer />
    </div>
  );
}
export default VAS;
