import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import Offers from "../../components/Home/Offers";
import Solutions from "../../components/Sections/List";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/ERP-Header.svg";
import contact from "../../images/vectors/ERP-Contact.svg";
import { erp } from "../../utils/erp";
import { logos } from "../../utils/logos";

function ERP() {
  return (
    <div>
      <Menu />

      <Header
        title="Empower your business with"
        subTitle="FocusX: AI-Powered ERP solution"
        miniTitle="GBT Solutions"
        description="In today's dynamic business landscape, staying ahead of the curve requires leveraging cutting-edge technology. FocusX goes beyond traditional ERP solutions, leveraging the power of Artificial Intelligence (AI) to automate tasks, streamline processes, and provide real-time decision-making capabilities."
        vector={header}
      />

      <GradientContainer
        className="h-[374px] md:h-[274px]"
        title="how Focusx erp empowers your Business"
        content="FocusX goes beyond traditional ERP solutions, offering a robust suite of features designed to manage every aspect of your business. Imagine a single platform that streamlines operations, boosts efficiency, and empowers data-driven decision making across your entire organization."
        description="Here's what FocusX brings to the table:"
      />

      <Offers
        className="bg-sky"
        itemBgColor="lightGray"
        descriptionHeight="20"
        data={erp.offers}
      />

      <GradientContainer
        className="h-[349px] md:h-[249px]"
        title="What Sets FocusX ERP Solution Apart?"
        content="FocusX isn't just another ERP solution. It's the future of enterprise resource planning, leveraging the power of AI to empower your business."
        description="Here's what truly sets FocusX apart:"
      />

      <Solutions content={erp.aparts} />

      <GradientContainer
        className="h-[371px] md:h-[271px]"
        title="Why Focusx ERP is the Perfect Solution for Your Business"
        content="FocusX isn't just about features; it's about empowering your business to achieve next-level success."
        description="Here's how FocusX can transform your operations and deliver significant benefits:"
      />

      <GridItems content={erp.reasons} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to Boost"
        subTitle="Your Customer Relationships?"
        description="Contact GBT today for a free consultation and discover how CentraHub CRM can empower your business to achieve its full potential."
        vector={contact}
        subject="Enterprise Resource Planning (ERP) Development Solutions"
      />

      <Footer />
    </div>
  );
}
export default ERP;
