//offers
import DeviceMobile from "../images/gradientIcons/DeviceMobile.svg";
import Headset from "../images/gradientIcons/Headset.svg";
import Info from "../images/gradientIcons/Info.svg";
import Trophy from "../images/gradientIcons/Trophy.svg";
import PhoneCall from "../images/gradientIcons/PhoneCall.svg";
import ShootingStar from "../images/gradientIcons/ShootingStar.svg";

//reasons
import smiley from "../images/grayIcons/Smiley.svg";
import trendUp from "../images/grayIcons/TrendUp.svg";
import gear from "../images/grayIcons/Gear.svg";
import handshake from "../images/grayIcons/Handshake.svg";
import chartPie from "../images/grayIcons/ChartPie.svg";
import infinity from "../images/grayIcons/Infinity.svg";

export const vas = {
  offers: [
    {
      number: "1",
      icon: DeviceMobile,
      title: "Deliver Mobile Services",
      description:
        "Facilitate convenient access to services like mobile top-up, bill payments, mobile ticketing, and more, directly through customers' mobile devices.",
    },
    {
      number: "2",
      icon: Headset,
      title: "Content & Entertainment",
      description:
        "Offer engaging content downloads, ringtones, wallpapers, and other digital media directly to your customers' devices.",
    },
    {
      number: "3",
      icon: Info,
      title: "Information Services",
      description:
        "Provide real-time updates, news, stock market data, or location-based services, keeping your customers informed and engaged.",
    },
    {
      number: "4",
      icon: Trophy,
      title: "Loyalty Programs",
      description:
        "Design and manage loyalty programs that reward customer engagement, incentivize repeat business, and foster stronger brand relationships.",
    },
    {
      number: "5",
      icon: PhoneCall,
      title: "Interactive Voice Response (IVR)",
      description:
        "Implement user-friendly IVR systems to automate customer interactions, streamline service delivery, and reduce call center workload.",
    },
    {
      number: "6",
      icon: ShootingStar,
      title: "And More!",
      description:
        "The possibilities are virtually limitless. With GBT's VAS Solution, you can tailor the platform to meet your specific needs and deliver innovative services that differentiate your brand.",
    },
  ],

  benefits: [
    {
      icon: smiley,
      title: "Enhanced Customer Experience",
      description:
        "By offering a broader range of convenient and personalized services, you can significantly improve customer satisfaction and loyalty. Imagine a bank offering mobile top-up, bill payments, and loyalty rewards alongside their traditional banking services. This creates a one-stop shop for customers, saving them time and effort.",
    },
    {
      icon: trendUp,
      title: "Increased Revenue Streams",
      description:
        "The VAS platform unlocks new revenue opportunities beyond your core business by allowing you to charge for specific VAS services (e.g., premium content downloads), offer subscription models for ongoing access to certain services, and partner with other companies to generate revenue through co-branded VAS offerings.",
    },
    {
      icon: gear,
      title: "Improved Operational Efficiency",
      description:
        "GBT's VAS Solution automates service delivery tasks such as mobile top-up or bill payments. This streamlines processes, reduces manual work, and frees up your staff to focus on higher-value activities.",
    },
    {
      icon: handshake,
      title: "Deeper Customer Engagement",
      description:
        "Interactive services like loyalty programs and personalized content recommendations foster stronger customer relationships. This can lead to increased customer loyalty and advocacy for your brand.",
    },
    {
      icon: chartPie,
      title: "Data-Driven Insights",
      description:
        "The platform collects valuable customer data on how they use and interact with VAS offerings. This data can be used to understand your customers better, identify what VAS services are most popular, and inform decisions about improving your VAS offerings and marketing them more effectively.",
    },
    {
      icon: infinity,
      title: "Scalability and Flexibility",
      description:
        "The platform is designed to grow with your business. It can accommodate an increasing user base and seamlessly integrate new VAS offerings as your needs evolve.",
    },
  ],
};
