export const terms = [
  {
    title: "Acceptance of Terms",
    description:
      "By accessing or using the Services, you acknowledge that you have read, understood, and agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Services.",
  },
  {
    title: "Age Restriction",
    description:
      "The Services are only available to individuals aged 18 or older. By accessing or using the Services, you represent and warrant that you are at least 18 years old.",
  },
  {
    title: "User Content",
    description:
      "You are solely responsible for the content you submit, upload, or post to the Services (User Content). You retain all ownership rights to your User Content. However, by submitting User Content, you grant GBT a non-exclusive, worldwide, royalty-free license to use, modify, reproduce, publish, distribute, and display your User Content for the purpose of providing and operating the Services. You represent and warrant that your User Content does not violate the rights of any third party and that you have the necessary permissions to grant GBT the licenses described above.",
  },
  {
    title: "Prohibited Content and Activities",
    description:
      "You agree not to use the Services for any purpose that is unlawful, harmful, or prohibited by these Terms. This includes, but is not limited to:\n Uploading, posting, or transmitting any content that is illegal, obscene, defamatory, threatening, harassing, or offensive.\n Infringing on the intellectual property rights of others.\n Impersonating any person or entity.\n Interfering with or disrupting the Services or any GBT servers or networks connected to the Services.\n Using the Services to send unsolicited commercial emails or spam.\n Attempting to gain unauthorized access to the Services or any other GBT systems. ",
  },
  {
    title: "Disclaimer of Warranties",
    description:
      "The Services are provided as is and as available without any warranties of any kind, express or implied. GBT disclaims all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement. GBT does not warrant that the Services will be uninterrupted, error-free, or secure.",
  },
  {
    title: "Indemnity",
    description:
      "You agree to indemnify and hold harmless GBT and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of the Services or your violation of these Terms.",
  },
  {
    title: "Termination",
    description:
      "We may terminate your access to the Services for any reason, at any time, without notice. We may also remove any User Content that violates these Terms or that we deem inappropriate or offensive.",
  },
  {
    title: "Governing Law and Dispute Resolution",
    description:
      "These Terms will be governed and construed in accordance with the laws of the Emirate of Dubai, without regard to its conflict of laws provisions. Any dispute arising out of or relating to these terms will be subject to the exclusive jurisdiction of the courts of the Emirate of Dubai.",
  },
  {
    title: "Entire Agreement",
    description:
      "These Terms constitute the entire agreement between you and GBT regarding your use of the Services.",
  },
  {
    title: "Changes to Terms",
    description:
      "We may modify these Terms at any time by posting the revised Terms on this website. Your continued use of the Services after the posting of revised Terms constitutes your acceptance of the revised Terms.",
  },
  {
    title: "Governing Law and Dispute Resolution",
    description:
      "If you have any questions about these Terms, please visit contact us page.",
  },
];
