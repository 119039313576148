import React, { useState, useEffect } from "react";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const getBackgroundClass = (itemIdx: any, screenWidth: number) => {
  if (screenWidth >= 768 && screenWidth < 1280) {
    // md breakpoint
    return itemIdx % 4 === 0 || itemIdx % 4 === 3 ? "bg-sky" : "bg-gray";
  } else {
    // sm and xl
    return itemIdx % 2 === 0 ? "bg-sky" : "bg-gray";
  }
};

const GridItems: React.FC<any> = ({ content }: { content: any }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 cursor-default">
      {content.map((item: any, itemIdx: any) => (
        <div
          key={itemIdx}
          className={classNames(
            getBackgroundClass(itemIdx, screenWidth),
            "w-full h-auto px-10 py-8 hover:bg-[#B3CDE5]"
          )}
        >
          <div className="flex justify-center">
            <img className="w-auto h-auto" src={item.icon} alt="" />
          </div>
          <div className="mt-2">
            {item.title && (
              <h2 className="text-center text-black text-lg lg:text-xl font-semibold capitalize">
                <span className="absolute inset-0" aria-hidden="true" />
                {item.title}
              </h2>
            )}

            <p className="mt-3 text-justify text-dark/50 text-base font-normal leading-normal">
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default GridItems;
