export const faqs = [
  {
    question: "Q1: What does GBT stand for?",
    answer: "GBT is stand for Grand Block Technologies.",
  },
  {
    question: "Q2: What kind of company is GBT Solutions?",
    answer:
      "GBT Solutions is an end-to-end solutions and integrated service management organization. We offer comprehensive services across the entire spectrum of ICT products and software solutions.",
  },
  {
    question: "Q3: What types of services do you offer?",
    answer:
      "We offer a wide range of services, including:\n ICT solutions design and implementation.\n Managed service provider (MSP) services.\n Software licensing and deployment.\n Software Development.\n Security solutions.\n Cloud computing services.\n Network and infrastructure management Data center services.\n IT consulting.",
  },
  {
    question: "Q4: What industries do you serve?",
    answer:
      "We serve a diverse range of industries, including:\n Finance\n Healthcare\n Government\n Education\n Retail\n Manufacturing\n Technology",
  },
  {
    question: "Q5: What are the benefits of using GBT Solutions?",
    answer:
      "We offer a number of benefits,including:\n Increased productivity and efficiency\n Enhanced reliability and security\n Reduced costs\n Improved scalability and flexibility\n Access to expert IT professionals\n 24/7 support",
  },
  {
    question:
      "Q6: How do you achieve higher levels of productivity and efficiency?",
    answer:
      "We implement strategic solutions and follow proven global delivery standards. We also focus on automation and streamlining processes.",
  },
  {
    question: "Q7: How do you ensure reliability and security?",
    answer:
      "We have a robust infrastructure and implement industry-leading security measures to protect your data and systems.",
  },
  {
    question: "Q8: How can you help me reduce my IT costs?",
    answer:
      "We can help you optimize your IT spend by offering cost-effective solutions and services. We can also help you avoid unnecessary technology investments.",
  },
  {
    question: "Q9: Do you have any partners?",
    answer:
      " Yes, we partner with a number of leading technology vendors and service providers. This allows us to offer you the best possible solutions and services.",
  },
  {
    question: "Q10: Do you offer training and support?",
    answer:
      "Yes, we offer comprehensive training and support services to ensure you get the most out of our solutions.",
  },
  {
    question: "Q11: What are your terms of service?",
    answer: "Please visit our terms of service Page.",
  },
];
