import React from "react";
import Header from "../components/Common/Header";
import GradientContainer from "../components/Sections/GradientContainer";
import GridList from "../components/Sections/GridItems";
import Footer from "../components/Common/Footer";
import List from "../components/Sections/List";
import contact from "../images/vectors/Training-Contact.svg";
import ThreeColList from "../components/Sections/ThreeItems";
import ContactUs from "../components/Sections/ContactUs";
import { training } from "../utils/training";
import Menu from "../components/Common/Menu";

function Training() {
  return (
    <div>
      <Menu />
      <Header
        title="GBT Solutions"
        subTitle="Where Tech Talent Takes Flight"
        miniTitle="GBT Training and hiring"
        description="Forget dull cubicles and rigid hierarchies. At GBT Solutions, we're building a launchpad for the next generation of tech pioneers, a vibrant hive where minds buzz with ideas and passion fuels progress. This isn't just a training and hiring page; it's a portal to a world where skills take flight, dreams become reality, and your tech journey soars to exhilarating heights."
      />

      <GradientContainer
        className="h-[332px] md:h-[232px]"
        title="Training benefits IN GBT Solutions"
        content="Ditch the dust, grasp the future! Master in-demand tech like AI and cybersecurity with cutting-edge programs and expert mentors. Beyond textbooks, GBT puts you in the pilot's seat of real projects. Soar your career with hands-on training that ignites innovation."
      />
      <GridList content={training.benefits} />

      <GradientContainer
        className="h-[336px] md:h-[236px]"
        title="Trainings Services"
        content="Grand Block Technologies offers a wide range of training services in various software, BI & data analytics tools, mobile application development tools, hardware products, AI development tools, and big data & data warehousing solutions. Let's explore each of these training services they provide:"
      />
      <ThreeColList content={training.services} />

      <GradientContainer
        className="h-[332px] md:h-[232px]"
        title="Job Features IN GBT Solutions"
        content="At GBT Solutions, we don't just build technology; we build careers. Partnering with us isn't just a job, it's an exhilarating adventure into the heart of innovation, where your skills take flight and your potential unlocks like a hidden superpower. Here's why GBT is the perfect launchpad for your tech journey."
      />
      <List content={training.features} />

      <ContactUs
        title="Are You Ready to"
        subTitle="join GBT Family?"
        description="Looking for a job opportunity? Explore our current openings: /gbt-llc Want to join our training? We want to hear your passion! Contact us on social media or simply send us an email with your passion and story. We're always eager to welcome new members to the tribe."
        vector={contact}
        subject="Training and Hiring staff"
      />

      <Footer />
    </div>
  );
}
export default Training;
