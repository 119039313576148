import { items } from "../../utils/about";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
const Goals: React.FC = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      {items.map((item, itemIdx) => (
        <div
          key={itemIdx}
          className={classNames(
            itemIdx % 4 === 0 || itemIdx % 4 === 3 ? "bg-[#D7E4F4]" : "bg-sky",
            "h-auto xl:h-[406px] group relative px-20 py-10"
          )}
        >
          <div className="flex justify-center lg:justify-start">
            <img className="w-auto" src={item.icon} alt="" />
          </div>

          <div className="w-auto mt-4">
            <span className="text-dark text-3xl lg:text-4xl font-bold uppercase drop-shadow-blue">
              <h2 className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                {item.title}
              </h2>
            </span>
            <p className="mt-3 text-justify text-dark/50 text-sm lg:text-base font-normal leading-6">
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Goals;
