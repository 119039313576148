import cloud from "../images/vectors/Cloud.svg";
import Software from "../images/vectors/Software-Header.svg";

export const slides = [
  {
    link: "/services/cloud",
    image: cloud,
    title: "Unlock Efficiency & Scalability with GBT's Cloud Services",
    description:
      "GBT's cloud services empower businesses to thrive in the digital age. Our solutions provide the agility, reliability, and scalability you need to transform operations and unlock growth.",
  },
  {
    link: "/solutions/software-license",
    image: Software,

    title: "Streamline Your Operations Simplified Software Licensing Solutions",
    description:
      "software licenses can be complex and time-consuming. GBT's software licensing services team simplifies the process, helping you choose the right licenses, manage them effectively, and ensure compliance.",
  },
];
