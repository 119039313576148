import zain from "../images/clients/zain.svg";
import champion from "../images/clients/champion.svg";
import tadawi from "../images/clients/altadawi.svg";
import dal from "../images/clients/dal-group.svg";
import police from "../images/clients/dubai-police.svg";
import hyproca from "../images/clients/hyproca-nutrition.svg";
import kics from "../images/clients/kics.svg";
import oryx from "../images/clients/oryx.svg";
import ucp from "../images/clients/ucp.svg";
import pp from "../images/clients/public-prosecution.svg";
import darwish from "../images/clients/darwish-brothers.svg";

export const logos = {
  customers: [
    { name: "Zain", logo: zain },
    { name: "Tadawi", logo: tadawi },
    { name: "Dal", logo: dal },
    { name: "Police", logo: police },
    { name: "Hyproca", logo: hyproca },
    { name: "KICS", logo: kics },
    { name: "ORYX", logo: oryx },
    { name: "UCB", logo: ucp },
    { name: "PP", logo: pp },
    { name: "darwish-brothers", logo: darwish },
  ],

  projects: [
    { name: "champion", logo: champion },
    { name: "Tadawi", logo: tadawi },
    { name: "Dal", logo: dal },
    { name: "Police", logo: police },
    { name: "Hyproca", logo: hyproca },
    { name: "KICS", logo: kics },
    { name: "ORYX", logo: oryx },
    { name: "UCB", logo: ucp },
    { name: "PP", logo: pp },
    { name: "darwish-brothers", logo: darwish },
  ],
};
