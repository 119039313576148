import { Link } from "react-router-dom";

const List: React.FC<any> = ({
  title,
  subTitle,
  description,
  intro,
  content,
}: {
  title: string;
  subTitle: string;
  description: string;
  intro: string;
  content: any;
}) => {
  return (
    <div className="w-full bg-sky cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col">
          {title && (
            <div className="flex flex-col mb-4">
              <div className="text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlue">
                <span className="text-primary">{title}</span>{" "}
                <span className="text-dark">{subTitle}</span>
              </div>

              <div className="w-full xl:w-[1100px] text-base lg:text-lg my-4">
                <span className=" text-dark/50 font-normal">{description}</span>
                <br />
                <div className=" text-dark font-bold mt-4">{intro}</div>
              </div>
            </div>
          )}

          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
            {content.map((item: any, index: number) => (
              <li
                key={index}
                className="h-auto bg-white p-6 rounded-t-xl rounded-bl-xl drop-shadow-darkBlue transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-105 duration-300"
              >
                <div className="w-7 -ml-8 -mt-8 bg-primary rounded-t-md rounded-bl-md flex justify-center items-center">
                  <div className="text-lightGray text-lg lg:text-xl font-semibold">
                    {item.number}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex justify-center items-center w-20 h-20 bg-primary/25 hover:bg-primary/35 rounded-t-xl rounded-bl-xl">
                    <img
                      src={item.icon}
                      alt=""
                      className="w-12 h-12 brightness-100 hover:brightness-125"
                    />
                  </div>
                  <div className="w-2/3 text-dark text-lg lg:text-xl font-bold capitalize leading-relaxed">
                    {item.title}
                  </div>
                </div>
                <div className="h-auto xl:h-32 text-justify text-dark/50 text-sm lg:text-base font-normal mt-4 mb-2 leading-5">
                  {item.description}
                </div>

                {item.buttonText && (
                  <Link
                    to={item.link}
                    className="w-full py-3 bg-gradient-to-r from-primary to-secondary rounded-md shadow flex justify-center items-center"
                  >
                    <span className="text-center text-lightGray  text-xs lg:text-sm font-semibold uppercase">
                      {item.buttonText}
                    </span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default List;
