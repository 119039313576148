import React from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";

const MapContainer: React.FC = () => {
  const mapStyles: React.CSSProperties = {
    height: "100%",
    width: "100%",
  };

  const defaultCenter = {
    lat: 25.251547757667506,
    lng: 55.33980146762364,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCZfiSEMCmT--AIbpR1joUh3o_t9o1OoKQ">
      <GoogleMap mapContainerStyle={mapStyles} zoom={16} center={defaultCenter}>
        <MarkerF position={defaultCenter} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
