import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import GirdList from "../../components/Sections/GirdList";
import VectorList from "../../components/Sections/VectorList";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Server-Status.svg";
import contact from "../../images/vectors/Helping-Apartner.svg";
import itService from "../../images/vectors/DataCenter/Connections.svg";
import dataCenterService from "../../images/vectors/DataCenter/Data-Center.svg";
import telecomService from "../../images/vectors/DataCenter/Telecom.svg";
import { dataCenter } from "../../utils/dataCenter";
import { logos } from "../../utils/logos";

function DataCenter() {
  return (
    <div>
      <Menu />

      <Header
        title="Building Your Digital Foundation:"
        subTitle="Data Center & Infrastructure Services from GBT"
        miniTitle="GBT Services"
        description="GBT offers a comprehensive suite of Data Center & Infrastructure Services to help organizations design, build, manage, and optimize their data center infrastructure for maximum uptime, performance, and efficiency."
        vector={header}
      />

      <GirdList
        className="xl:mx-40 max-w-8xl"
        title="Our services"
        description="Here's a closer look at the main services we provide:"
        content={dataCenter.services}
      />

      <VectorList
        id="dataCenterService"
        className="bg-sky"
        itemBackground="lightGray"
        vector={dataCenterService}
        vecLocation="start"
        title="Data Center"
        subTitle="Services"
        description="GBT offers a comprehensive suite of Data Center Services to help you design, build, manage, and optimize your data center, ensuring a reliable, secure, and efficient IT foundation."
        content={dataCenter.dataCenterServices}
        intro="Here's a closer look at the data center services we provide:"
      />
      <VectorList
        id="itService"
        className="bg-lightGray"
        itemBackground="sky"
        vector={itService}
        vecLocation="end"
        title="IT network"
        subTitle="Services"
        description="At GBT, we understand the complexities of modern networks.  That's why we offer a comprehensive suite of IT Network Services designed to help you design, build, manage, and optimize your network for optimal performance, security, and scalability."
        content={dataCenter.itServices}
        intro=" Here's how we can help:"
      />

      <VectorList
        id="telecomServices"
        className="bg-sky"
        itemBackground="lightGray"
        vector={telecomService}
        vecLocation="start"
        title="Telecom network"
        subTitle="Services"
        description="GBT takes the complexity out of telecom network solutions. We offer a comprehensive suite of Telecom Network Services designed to help you design, implement, and manage a reliable and cost-effective telecom network that meets your specific communication needs."
        content={dataCenter.telecomServices}
        intro="Here's how we can help:"
      />

      <GradientContainer
        className="h-[435px] md:h-[335px]"
        title="Benefits of Partnering with GBT for Your Data Center & Infrastructure Needs:"
        content="Building and maintaining a robust data center and network infrastructure is critical for any organization's success in today's digital age. However, navigating the complexities of data center design, network optimization, and telecom solutions can be a daunting task."
        description="Partnering with GBT offers a multitude of benefits that can empower your organization to achieve its IT goals:"
      />
      <GridItems content={dataCenter.benefits} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Let GBT Be Your Trusted"
        subTitle="Data Center and Infrastructure Partner"
        description="Partnering with GBT goes beyond just services – we become an extension of your IT team. We are dedicated to understanding your unique needs and developing a comprehensive data center and infrastructure strategy. Contact us today to discuss your specific needs and explore how our services can help you achieve your IT goals."
        vector={contact}
        subject="Data Center & Infrastructure Services"
      />
      <Footer />
    </div>
  );
}
export default DataCenter;
