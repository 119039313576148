import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import Offers from "../../components/Home/Offers";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Software-Header.svg";
import contact from "../../images/vectors/Helping-Apartner.svg";
import { licenses } from "../../utils/licenses";
import { logos } from "../../utils/logos";

function SoftwareLicense() {
  return (
    <div>
      <Menu />

      <Header
        title="Streamline Your Operations:"
        subTitle="Simplified Software Licensing Solutions"
        miniTitle="GBT Solutions"
        description="Managing software licenses can be complex and time-consuming. GBT's software licensing services team simplifies the process, helping you choose the right licenses, manage them effectively, and ensure compliance."
        vector={header}
      />

      <GradientContainer
        className="h-[339px] md:h-[229px]"
        title="Software We Provide Licenses For"
        content="At GBT, we understand that navigating the vast software landscape and choosing the right solutions can be a challenge.  That's why we offer a comprehensive portfolio of software licenses from industry-leading vendors, empowering you to select the most suitable tools to optimize your operations and achieve your business goals. "
      />

      <GridItems content={licenses.tools} />

      <GradientContainer
        className="h-[356px] md:h-[256px]"
        title="Beyond Software Licensing: Expert Guidance and Support"
        content="We are committed to providing more than just software licenses.  Our team of specialists has in-depth knowledge of the software products we offer and can assist you with:"
      />

      <Offers
        className="bg-sky"
        itemBgColor="lightGray"
        descriptionHeight="auto"
        data={licenses.offers}
      />

      <GradientContainer
        className="h-[410px] md:h-[310px]"
        title="Benefits of Choosing GBT for Your Software Licensing Needs:"
        content="Navigating the complexities of software licensing can be a daunting task. Choosing the right licenses, managing them effectively, and ensuring compliance requires expertise and dedication."
        description="Here's why partnering with GBT for your software licensing needs is the smart choice:"
      />

      <GridItems content={licenses.choices} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Let GBT Be Your"
        subTitle="Trusted Software Licensing Partner"
        description="Contact GBT today and discuss your software licensing requirements with our team. We'll work collaboratively to understand your specific needs and recommend a customized solution that simplifies license management, optimizes costs, and ensures compliance."
        vector={contact}
        subject="Software Licensing Solutions"
      />

      <Footer />
    </div>
  );
}
export default SoftwareLicense;
