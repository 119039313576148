//Uses
import users from "../images/grayIcons/UsersFour.svg";
import arrows from "../images/grayIcons/ArrowsCounterClockwise.svg";
import lightbulb from "../images/grayIcons/Lightbulb.svg";
import shieldCheck from "../images/grayIcons/ShieldCheck.svg";
import piggy from "../images/grayIcons/PiggyBank.svg";
import calendar from "../images/grayIcons/CalendarCheck.svg";

//Benefits
import Handshake from "../images/gradientIcons/Handshake.svg";
import UserFocus from "../images/gradientIcons/UsersFour.svg";
import Code from "../images/gradientIcons/Code.svg";
import HardDrives from "../images/gradientIcons/HardDrives.svg";
import ChartLineUp from "../images/gradientIcons/ChartLine.svg"; //*********** No ChartLineUp.svg */
import Lifebuoy from "../images/gradientIcons/Lifebuoy.svg";

export const mobileApp = {
  offers: [
    {
      number: "1",
      icon: Handshake,
      title: "Collaborative Partnership",
      description:
        "We work closely with you to understand your specific needs, target audience, and desired functionalities. Through open communication and a collaborative approach, we define your project scope and outline the ideal web application solution.",
    },
    {
      number: "2",
      icon: UserFocus,
      title: "User-Centric Design Principles",
      description:
        "We prioritize user experience by designing intuitive and engaging interfaces that are easy to navigate. Our development process revolves around creating applications that cater to your target audience's needs, ensuring a smooth and enjoyable user journey.",
    },
    {
      number: "3",
      icon: Code,
      title: "Cross-Platform App Development",
      description:
        "We offer cross-platform development using frameworks like React Native or Flutter, allowing you to create a single app codebase that functions flawlessly on both iOS and Android devices, streamlining development time and cost.",
    },
    {
      number: "4",
      icon: HardDrives,
      title: "Robust Backend Development",
      description:
        "A strong backend is the backbone of any successful app. Our developers ensure seamless data management, secure user authentication, and reliable API integrations, creating a robust foundation for your mobile app.",
    },
    {
      number: "5",
      icon: ChartLineUp,
      title: "App Store Optimization (ASO)",
      description:
        "Get your app discovered! We employ ASO strategies to optimize your app listing in the App Store and Google Play, increasing visibility and organic downloads.",
    },
    {
      number: "6",
      icon: Lifebuoy,
      title: "Ongoing Maintenance & Support",
      description:
        "We don't just build - we support! We offer comprehensive maintenance and support services to ensure your app continues to function smoothly, receive necessary updates, and adapt to evolving user needs and industry trends.",
    },
  ],

  reasons: [
    {
      icon: users,
      title: "Experienced Development Team",
      description:
        "Our team comprises talented developers with a proven track record of crafting innovative, user-friendly, and feature-rich mobile apps across various industries. You can trust their expertise to translate your vision into a powerful mobile solution.",
    },
    {
      icon: arrows,
      title: "Agile Development Methodology",
      description:
        "We embrace agile development principles, ensuring you're involved in the creation process every step of the way. We leverage agile principles for iterative development and continuous feedback. This allows you to see your mobile app come to life in stages, providing opportunities to provide input and ensure the final product aligns perfectly with your goals.",
    },
    {
      icon: lightbulb,
      title: "Focus on Innovation & Creativity",
      description:
        "Our innovative developers are passionate about using the latest tech to build future-proof solutions. We take pride in crafting innovative solutions that are not only functional but also visually stunning and user-friendly. We believe that a well-designed mobile app should be a delight to use, fostering positive user experiences and interactions with your brand.",
    },
    {
      icon: shieldCheck,
      title: "Scalable & Secure Solutions",
      description:
        "Our mobile apps are built to grow with your business, ensuring scalability to accommodate increasing user bases and data demands. Security is paramount, with robust measures implemented to protect user privacy and data integrity.",
    },
    {
      icon: piggy,
      title: "Cost-Effective Development",
      description:
        "We understand that budget is a crucial consideration.  Our development process is streamlined and efficient, ensuring you receive a high-quality web application without breaking the bank. We offer flexible engagement models to suit your specific needs and budget, allowing you to choose the level of involvement that best fits your project.",
    },
    {
      icon: calendar,
      title: "Dedicated Project Management",
      description:
        "You will benefit from a dedicated project manager who acts as your single point of contact throughout the development process.  This ensures clear communication, keeps you informed on project progress, and allows you to address any questions or concerns.  Our project managers are highly skilled in keeping projects on schedule and goals met.",
    },
  ],
};
