const ThreeItems: React.FC<any> = ({ content }: { content: any }) => {
  return (
    <div className="w-full bg-sky cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col">
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {content.map((item: any, index: number) => (
              <li
                key={index}
                className="w-full xl:w-[353.33px] h-ato bg-white p-6 rounded-t-xl rounded-bl-xl drop-shadow-darkBlue transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300"
              >
                <div className="w-7 -ml-8 -mt-8 bg-primary rounded-t-md rounded-bl-md flex justify-center items-center">
                  <div className="text-lightGray text-lg lg:text-xl font-semibold">
                    {item.number}
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="w-full h-full flex justify-center items-center">
                    <img
                      src={item.vector}
                      alt=""
                      className="brightness-100 hover:brightness-105"
                    />
                  </div>

                  <div className="text-dark text-lg lg:text-xl font-bold capitalize leading-[26px] my-4">
                    {item.title}
                  </div>
                </div>
                <div className="text-justify text-dark/50 text-base font-normal leading-5">
                  {item.description}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ThreeItems;
