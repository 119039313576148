//Uses
import users from "../images/grayIcons/UsersFour.svg";
import arrows from "../images/grayIcons/ArrowsCounterClockwise.svg";
import lightbulb from "../images/grayIcons/Lightbulb.svg";
import shieldCheck from "../images/grayIcons/ShieldCheck.svg";
import piggy from "../images/grayIcons/PiggyBank.svg";
import calendar from "../images/grayIcons/CalendarCheck.svg";

//Benefits
import Handshake from "../images/gradientIcons/Handshake.svg";
import UserFocus from "../images/gradientIcons/UserFocus.svg";
import Code from "../images/gradientIcons/Code.svg";
import Graph from "../images/gradientIcons/Graph.svg";
import Database from "../images/gradientIcons/Database.svg";
import Lifebuoy from "../images/gradientIcons/Lifebuoy.svg";

export const webApp = {
  offers: [
    {
      number: "1",
      icon: Handshake,
      title: "Collaborative Partnership",
      description:
        "We work closely with you to understand your specific needs, target audience, and desired functionalities. Through open communication and a collaborative approach, we define your project scope and outline the ideal web application solution.",
    },
    {
      number: "2",
      icon: UserFocus,
      title: "User-Centric Design Principles",
      description:
        "We prioritize user experience by designing intuitive and engaging interfaces that are easy to navigate. Our development process revolves around creating applications that cater to your target audience's needs, ensuring a smooth and enjoyable user journey.",
    },
    {
      number: "3",
      icon: Code,
      title: "Full-Stack Development Expertise",
      description:
        "Our team possesses expertise across the entire development cycle. From front-end development utilizing the latest frameworks to robust back-end development ensuring seamless data management and functionality, we cover all aspects of web app.",
    },
    {
      number: "4",
      icon: Graph,
      title: "Seamless API Integration",
      description:
        "We understand the power of third-party integrations. Our developers are skilled in API integration, enabling your web app to leverage valuable data and functionalities from existing platforms, enhancing your app's capabilities and user experience.",
    },
    {
      number: "5",
      icon: Database,
      title: "Secure and Scalable Database Solutions",
      description:
        "We design secure databases and deploy your app on our secure infrastructure, ensuring optimal performance and data protection.",
    },
    {
      number: "6",
      icon: Lifebuoy,
      title: "Ongoing Maintenance & Support",
      description:
        "We don't just build – we support!  We offer comprehensive maintenance and support services to ensure your web app continues to function smoothly, adapt to evolving needs, and receive necessary updates to maintain optimal performance.",
    },
  ],

  reasons: [
    {
      icon: users,
      title: "Experienced Development Team",
      description:
        "We combine the power of cutting-edge technical skills with clear and transparent communication to ensure a seamless and collaborative web app development experience. Our developers are not only experts in their fields, but also passionate about understanding your unique business goals and target audience.",
    },
    {
      icon: arrows,
      title: "Agile Development Methodology",
      description:
        "We embrace agile development principles, ensuring you're involved in the creation process every step of the way. We leverage agile principles for iterative development and continuous feedback. This allows you to see your web app come to life in stages, providing opportunities to provide input and ensure the final product aligns perfectly with your goals.",
    },
    {
      icon: lightbulb,
      title: "Focus on Innovation & Creativity",
      description:
        "Our innovative developers are passionate about using the latest tech to build future-proof solutions. We take pride in crafting innovative solutions that are not only functional but also visually stunning and user-friendly. We believe that a well-designed web app should be a delight to use, fostering positive user experiences and interactions with your brand.",
    },
    {
      icon: shieldCheck,
      title: "Scalable & Secure Solutions",
      description:
        "Our web apps are built to grow with your business, ensuring scalability and adaptability to evolving needs. Furthermore, security is paramount, with robust measures implemented to protect your data and user information.",
    },

    {
      icon: piggy,
      title: "Cost-Effective Development",
      description:
        "We understand that budget is a crucial consideration.  Our development process is streamlined and efficient, ensuring you receive a high-quality web application without breaking the bank. We offer flexible engagement models to suit your specific needs and budget, allowing you to choose the level of involvement that best fits your project.",
    },
    {
      icon: calendar,
      title: "Dedicated Project Management",
      description:
        "You will benefit from a dedicated project manager who acts as your single point of contact throughout the development process.  This ensures clear communication, keeps you informed on project progress, and allows you to address any questions or concerns.  Our project managers are highly skilled in keeping projects on schedule and goals met.",
    },
  ],
};
