//Advantages
import sliders from "../images/grayIcons/Sliders.svg";
import piggy from "../images/grayIcons/PiggyBank.svg";
import rocket from "../images/grayIcons/Rocket.svg";
import infinity from "../images/grayIcons/Infinity.svg";
import truck from "../images/grayIcons/Truck.svg";

//Offers
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";
import PiggyBank from "../images/gradientIcons/PiggyBank.svg";
import Clock from "../images/gradientIcons/ClockClockwise.svg";
import HardDrives from "../images/gradientIcons/HardDrives.svg";
import ShieldCheckered from "../images/gradientIcons/ShieldCheckered.svg";

export const dataBox = {
  advantages: [
    {
      icon: sliders,
      title: "Modular and Customizable Design",
      description:
        "Unlike traditional data centers with fixed configurations, the GBT Data Box offers unparalleled customization. You select the specific components (power, cooling, IT racks, etc.) that perfectly suit your current needs.  This modularity extends to the future, allowing you to seamlessly add new modules as your data storage requirements grow.  Your data center infrastructure scales effortlessly alongside your business.",
    },
    {
      icon: piggy,
      title: "Versatile and Cost-Effective Solutions",
      description:
        "The GBT Data Box caters to a broad spectrum of business needs.  Whether you require a small-scale edge computing solution for a remote branch office or a comprehensive data center for enterprise-level operations, our customizable options allow you to create a cost-effective solution that aligns perfectly with your specific needs and budget.  Eliminate unnecessary upfront costs by acquiring only the components you require.",
    },
    {
      icon: rocket,
      title: "Fast Production Time",
      description:
        "GBT understands the urgency of deploying your data center infrastructure.  Our streamlined manufacturing process ensures a significantly faster production time compared to traditional brick-and-mortar data centers.  This translates to minimizing downtime and disruption to your operations.  Get your data center up and running quickly with the GBT Data Box.",
    },
    {
      icon: infinity,
      title: "Scalable and Ready-to-Deploy Infrastructure",
      description:
        "The GBT Data Box adapts effortlessly as your data storage requirements evolve.  The modular design allows for easy expansion by simply adding additional containerized units.  Furthermore, each Data Box arrives pre-configured and factory-tested, eliminating the need for lengthy on-site installation processes.  Simply connect the units, power them on, and your data center is ready to operate.",
    },
    {
      icon: truck,
      title: "Movable & Ready-to-Operate",
      description:
        "The GBT Data Box's containerized design offers exceptional mobility.  These data centers can be easily transported and deployed at any location with the necessary utilities. This flexibility makes them ideal for a variety of applications.",
    },
    {
      title: "",
      description: "",
    },
  ],

  offers: [
    {
      number: "1",
      icon: Lightbulb,
      title: "Unmatched Agility",
      description:
        "The modular design allows you to adapt your data center infrastructure on the fly, easily adding new components or scaling back as your IT needs evolve.",
    },
    {
      number: "2",
      icon: PiggyBank,
      title: "Cost Optimization",
      description:
        "Factory pre-configured units, streamlined manufacturing, and the ability to customize your solution to your specific needs all contribute to significant cost savings in both deployment and ongoing operations.",
    },
    {
      number: "3",
      icon: Clock,
      title: "Rapid Deployment",
      description:
        "Fast production times and pre-configured units minimize downtime.  Get your data center up and running quickly with minimal disruption to your business.",
    },
    {
      number: "4",
      icon: HardDrives,
      title: "Seamless Scalability",
      description:
        "The modular design shines again here.  As your data storage requirements increase, simply add additional Data Box units to seamlessly expand your data center infrastructure.",
    },
    {
      number: "5",
      icon: ShieldCheckered,
      title: "Unwavering Security",
      description:
        "Rest assured that your critical IT operations are protected.  The GBT Data Box is built with robust materials and industry-standard security measures to safeguard your equipment from both physical and digital threats.",
    },
  ],
};
