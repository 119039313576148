import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Header: React.FC<any> = ({
  miniTitle,
  title,
  subTitle,
  description,
}: {
  miniTitle: string;
  title: string;
  subTitle: string;
  description: string;
}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation in milliseconds
    });
  }, []);
  return (
    <div className="w-full bg-sky shadow-inner cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="text-darkGray text-base font-bold uppercase">
          {miniTitle}
        </div>
        <div className="text-3xl xl:text-5xl font-extrabold uppercase leading-8 xl:leading-[58.51px] drop-shadow-lightBlue">
          <div
            data-aos="fade-right"
            className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent"
          >
            {title}
          </div>

          <div data-aos="fade-left" className="text-dark">
            {subTitle}
          </div>
        </div>

        <div className="text-dark/50 text-justify text-lg font-normal mt-4">
          {description}
        </div>
      </div>
    </div>
  );
};
export default Header;
