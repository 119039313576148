import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import Services from "../../components/Sections/List";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/MobApp-Header.svg";
import contact from "../../images/vectors/MobApp-Contact.svg";
import { mobileApp } from "../../utils/mobileApp";
import { logos } from "../../utils/logos";

function MobileApp() {
  return (
    <div>
      <Menu />

      <Header
        title="Transform Your Business with"
        subTitle="Powerful Mobile App Development"
        miniTitle="GBT Services"
        description="Mobile apps are essential in today's world. GBT's skilled developers create custom mobile apps that are user-friendly, innovative, and drive real business results."
        vector={header}
      />

      <GradientContainer
        className="h-[332px] md:h-[232px]"
        title="What we offer to you?"
        content="GBT offers a comprehensive mobile app development solution. We work closely with you to understand your needs, then  craft cross-platform apps that deliver exceptional UX.  Our expertise extends beyond design – robust backends and ASO strategies ensure a successful app launch and ongoing growth."
      />

      <Services content={mobileApp.offers} />

      <GradientContainer
        className="h-[357px] md:h-[257px]"
        title="Why Choose GBT Mobile App Development?"
        content="Developing a successful mobile app requires a team that understands not only the technical aspects but also the strategic vision behind it. At GBT, we offer a unique blend of expertise and dedication that empowers your app to thrive."
        description="Here's what sets us apart and why you should choose GBT as your trusted mobile app development partner:"
      />

      <GridItems content={mobileApp.reasons} />

      <Clients
        title="the Projects we build"
        description="This section highlights a selection of successful projects we've completed in partnership with our valued clients.  Each project showcases our expertise in crafting mobile app solutions that drive results.  Get inspired by the diverse range of projects we tackle and see how we can help you achieve your online goals."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to Take Your"
        subTitle="Business mobile?"
        description="Contact GBT today and discuss your mobile app development project with our expert team. We'll work collaboratively to transform your vision into a powerful mobile app that elevates your brand, engages your audience, and drives real business growth."
        vector={contact}
        subject="Mobile Application Development Services"
      />

      <Footer />
    </div>
  );
}
export default MobileApp;
