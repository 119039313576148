//Features
import smiley from "../images/grayIcons/Smiley.svg";
import calendarCheck from "../images/grayIcons/CalendarCheck.svg";
import chats from "../images/grayIcons/Chats.svg";
import chartPie from "../images/grayIcons/ChartPie.svg";
import handFist from "../images/grayIcons/HandFist.svg";
import ShootingStar from "../images/grayIcons/ShootingStar.svg";

//Benefits
import Ticket from "../images/gradientIcons/Ticket.svg";
import ChartDonut from "../images/gradientIcons/ChartDonut.svg";
import Gears from "../images/gradientIcons/Gears.svg";
import BoxArrowDown from "../images/gradientIcons/BoxArrowDown.svg";

//Offers
import MapPinArea from "../images/gradientIcons/MapPinArea.svg";
import CalendarDots from "../images/gradientIcons/CalendarDots.svg";
import ProjectorScreenChart from "../images/gradientIcons/ProjectorScreenChart.svg";
import Chats from "../images/gradientIcons/Chats.svg";
import MonitorCheck from "../images/gradientIcons/monitor-check.svg";

export const qms = {
  features: [
    {
      icon: smiley,
      title: "Reduced Wait Times & Happy Customers",
      description:
        "Qmatic keeps queues organized and moving efficiently, minimizing wait times and fostering a more positive customer experience.",
    },
    {
      icon: calendarCheck,
      title: "Appointment Scheduling & Convenience",
      description:
        "Empower customers to schedule appointments online or through kiosks, reducing walk-in wait times and offering greater control over their time.",
    },
    {
      icon: chats,
      title: "Informed Customers & Reduced Anxiety",
      description:
        "Enable omnichannel communication with real-time wait times, service updates, and personalized notifications. This reduces anxiety and improves the overall waiting experience.",
    },
    {
      icon: chartPie,
      title: "Data-Driven Decisions & Optimized Operations",
      description:
        "Gain valuable insights into customer traffic patterns, service times, and staff performance. Use this data to optimize staffing, identify areas for improvement, and enhance efficiency.",
    },
    {
      icon: handFist,
      title: "Empowered Staff & Improved Interactions",
      description:
        "Free staff from queue management tasks, allowing them to focus on delivering exceptional customer service. Shorter wait times and a more organized environment also lead to more positive staff-customer interactions.",
    },
    {
      icon: ShootingStar,
      title: "Stronger Brand & Increased Revenue",
      description:
        "Demonstrate your commitment to customer satisfaction with a modern queue management system. Reduced wait times and a positive brand image can lead to increased customer loyalty and potentially, higher revenue.",
    },
  ],

  benefits: [
    {
      number: "1",
      icon: Ticket,
      title: "Electronic Ticketing",
      description:
        "Eliminate the hassle of waiting in line for paper tickets, improving the waiting experience for customers while reducing printing costs for your business. Customers can easily obtain electronic tickets from kiosks or designated staff, reducing perceived wait times.",
    },
    {
      number: "2",
      icon: ChartDonut,
      title: "Enhanced Data Collection",
      description:
        "Qmatic Solo anonymously collects data on customer traffic patterns and service times. This information can be used to improve scheduling and staffing in the future, potentially leading to shorter wait times for everyone.",
    },
    {
      number: "3",
      icon: Gears,
      title: "Highly Configurable",
      description:
        "The system can be adapted to accommodate your specific service offerings, allowing you to set up different ticketing options for various services or prioritize specific customer groups if needed. This flexibility ensures you get the most out of your queue management solution while providing a tailored experience for your customers.",
    },
    {
      number: "4",
      icon: BoxArrowDown,
      title: "Easy to Install and Manage",
      description:
        "Qmatic Solo is a self-contained system, meaning it's easy to set up and manage without complex configurations. This saves you time and resources associated with IT support or complex software installations, allowing you to focus on delivering excellent customer service.",
    },
  ],

  offers: [
    {
      number: "1",
      icon: MapPinArea,
      title: "Multi-location Management",
      description:
        "Gain centralized control over customer flow across all your branches.  Monitor wait times, track service performance, and manage queues remotely, ensuring consistency and efficiency in service delivery at every location.",
    },
    {
      number: "2",
      icon: CalendarDots,
      title: "Appointment Scheduling",
      description:
        "Empower customers to schedule appointments online or through kiosks at individual branches. This reduces walk-in wait times, allows for better planning by both customers and staff, and potentially increases revenue by encouraging customers to schedule service during less busy times.",
    },
    {
      number: "3",
      icon: ProjectorScreenChart,
      title: "Real-time Reporting & Analytics",
      description:
        "Gain in-depth insights into customer traffic patterns, service times, and staff performance across your entire organization.  This data empowers you to identify areas for improvement, optimize staffing levels to match demand, and make data-driven decisions to enhance customer experience and operational efficiency.",
    },
    {
      number: "4",
      icon: Chats,
      title: "Omnichannel Communication",
      description:
        "Integrate Qmatic with your existing communication channels to keep customers informed and engaged.  Send SMS notifications about wait times, provide status updates via email, or allow customers to track their queue position through a mobile app.  This reduces anxiety and improves the overall waiting experience.",
    },
    {
      number: "5",
      icon: MonitorCheck,
      title: "Digital Signage & Kiosk Integration",
      description:
        "Provide real-time information and interactive features for customers through strategically placed digital signage and kiosks.  Customers can view current wait times, service updates, and potentially complete tasks like check-in or information updates without needing to wait in line.",
    },
  ],
};
