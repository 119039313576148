import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Header: React.FC<any> = ({
  miniTitle,
  title,
  subTitle,
  description,
  vector,
}: {
  miniTitle: string;
  title: string;
  subTitle: string;
  description: string;
  vector: any;
}) => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation in milliseconds
    });
  }, []);

  return (
    <div className="w-full bg-sky shadow-inner">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col-reverse md:flex-row md:items-center gap-4 md:gap-16">
          <div className="w-full xl:w-[588px]">
            <div className="text-darkGray text-sm lg:text-base font-bold uppercase">
              {miniTitle}
            </div>
            <div
              data-aos="fade-right"
              className="text-3xl xl:text-5xl font-extrabold uppercase leading-8 xl:leading-[58.51px] drop-shadow-lightBlue"
            >
              <div className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                {title}
              </div>
              <div className="text-dark">{subTitle}</div>
            </div>

            <div className="text-dark/50 text-justify text-sm lg:text-lg font-normal mt-4">
              {description}
            </div>
          </div>
          <div data-aos="fade-left">
            <img
              className="w-auto md:w-[340px] xl:w-[480px]"
              src={vector}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
