//Services
import Infra from "../images/vectors/Cloud/Infrastructure.svg";
import Platform from "../images/vectors/Cloud/Platform.svg";
import Software from "../images/vectors/Cloud/Software.svg";
import Backup from "../images/vectors/Cloud/Backup.svg";

//Uses
import dollar from "../images/grayIcons/Dollar.svg";
import gauge from "../images/grayIcons/Gauge.svg";
import globe from "../images/grayIcons/Globe.svg";
import arrows from "../images/grayIcons/ArrowsCounterClockwise.svg";
import clock from "../images/grayIcons/Clock.svg";
import hardDrives from "../images/grayIcons/HardDrives.svg";
import lock from "../images/grayIcons/Lock.svg";
import trendUp from "../images/grayIcons/TrendUp.svg";
import shieldCheck from "../images/grayIcons/ShieldCheck.svg";

//Benefits
import Cloud from "../images/gradientIcons/Cloud.svg";
import Globe from "../images/gradientIcons/Globe.svg";
import HardDrives from "../images/gradientIcons/HardDrives.svg";
import CodeBlock from "../images/gradientIcons/CodeBlock.svg";
import DeviceMobile from "../images/gradientIcons/DeviceMobile.svg";
import Speaker from "../images/gradientIcons/Speaker.svg";

export const cloud = {
  services: [
    {
      image: Infra,
      title: "Infrastructure as a Service (IaaS)",
      description:
        "Build and deploy your applications on our scalable virtual infrastructure, eliminating the need for physical hardware management.",
      buttonDesc: "contact us",
      link: "/contact?subject=Infrastructure Service#contactForm",
    },
    {
      image: Platform,
      title: "Platform as a Service (PaaS)",
      description:
        "Streamline development and deployment with pre-configured environments and tools for rapid application development.",
      buttonDesc: "contact us",
      link: "/contact?subject=Platform Service#contactForm",
    },
    {
      image: Software,
      title: "Software as a Service (SaaS)",
      description:
        "Access a wide range of business applications on-demand, without installation or maintenance hassles.",
      buttonDesc: "contact us",
      link: "/contact?subject=Software Service#contactForm",
    },
    {
      image: Backup,
      title: "Cloud Backup and Recovery",
      description:
        "Safeguard your cloud infrastructure with advanced threat protection, data encryption, and compliance adherence.",
      buttonDesc: "contact us",
      link: "/contact?subject=Cloud Backup and Recovery #contactForm",
    },
  ],

  uses: [
    {
      number: "1",
      icon: Cloud,
      title: "Create cloud-native applications",
      description:
        "Quickly build, deploy, and scale applications—web, mobile, and API. Take advantage of cloud-native technologies and approaches, such as containers, Kubernetes, microservices architecture, API-driven communication, and DevOps.",
    },
    {
      number: "2",
      icon: Globe,
      title: "Store, back up, and recover data",
      description:
        "Protect your data more cost-efficiently—and at massive scale—by transferring your data over the Internet to an offsite cloud storage system that’s accessible from any location and any device.",
    },
    {
      number: "3",
      icon: HardDrives,
      title: "Analyze data",
      description:
        "Unify your data across teams, divisions, and locations in the cloud. Then use cloud services, such as machine learning and artificial intelligence, to uncover insights for more informed decisions.",
    },
    {
      number: "4",
      icon: CodeBlock,
      title: "Deliver software on demand",
      description:
        "Also known as software as a service (SaaS), on-demand software lets you offer the latest software versions and updates around to customers—anytime they need, anywhere they are.",
    },

    {
      number: "5",
      icon: DeviceMobile,
      title: "Stream audio and video",
      description:
        "Connect with your audience anywhere, anytime, on any device with high-definition video and audio with global distribution.",
    },
    {
      number: "6",
      icon: Speaker,
      title: "Embed intelligence",
      description:
        "Use intelligent models to help engage customers and provide valuable insights from the data captured.",
    },
  ],

  benefits: [
    {
      icon: dollar,
      title: "Reduced Costs",
      description:
        "Cloud computing eliminates the capital expense of buying hardware and software and setting up and running on-site datacenters—the racks of servers, the round-the-clock electricity for power and cooling, and the IT experts for managing the infrastructure. It adds up fast.",
    },
    {
      icon: gauge,
      title: "Speed",
      description:
        "Most cloud computing services are provided self service and on demand, so even vast amounts of computing resources can be provisioned in minutes, typically with just a few mouse clicks, giving businesses a lot of flexibility and taking the pressure off capacity planning.",
    },
    {
      icon: globe,
      title: "Global Scale",
      description:
        "The benefits of cloud computing services include the ability to scale elastically. In cloud speak, that means delivering the right amount of IT resources—for example, more or less computing power, storage, bandwidth—right when they’re needed, and from the right geographic location.",
    },
    {
      icon: trendUp,
      title: "Productivity",
      description:
        "On-site datacenters typically require a lot of “racking and stacking”—hardware setup, software patching, and other time-consuming IT management chores. Cloud computing removes the need for many of these tasks, so IT teams can spend time on achieving more important business goals.",
    },

    {
      icon: hardDrives,
      title: "Performance",
      description:
        "The biggest cloud computing services run on a worldwide network of secure datacenters, which are regularly upgraded to the latest generation of fast and efficient computing hardware. This offers several benefits over a single corporate datacenter, including reduced network latency for applications and greater economies of scale.",
    },
    {
      icon: shieldCheck,
      title: "Reliability",
      description:
        "Cloud computing makes data backup, disaster recovery, and business continuity easier and less expensive because data can be mirrored at multiple redundant sites on the cloud provider’s network.",
    },
    {
      icon: lock,
      title: "CImproved Security",
      description:
        "Many cloud providers offer a broad set of policies, technologies, and controls that strengthen your security posture overall, helping protect your data, apps, and infrastructure from potential threats.",
    },
    {
      icon: arrows,
      title: "Automatic Updates and Maintenance",
      description:
        "Say goodbye to tedious software updates and hardware maintenance. Cloud providers handle everything, ensuring your systems are always up-to-date and running smoothly. Focus on your core business while the cloud takes care of the technical nitty-gritty.",
    },
    {
      icon: clock,
      title: "Disaster Recovery and Data Backup",
      description:
        "Secure your data from natural disasters, power outages, or human error with cloud-based backup and disaster recovery solutions. Minimize downtime and ensure business continuity even in unforeseen circumstances.",
    },
  ],
};
