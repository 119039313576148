const GirdList: React.FC<any> = ({
  className,
  title,
  description,
  content,
}: {
  className: string;
  title: string;
  description: string;
  content: any;
}) => {
  return (
    <div className="w-full h-auto bg-background bg-cover bg-center px-6 sm:px-8 lg:px-10 py-12 cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-6">
        <div className="flex flex-col gap-2">
          <h2 className="text-lightGray text-3xl lg:text-4xl font-bold uppercase drop-shadow-lightBlueSm">
            {title}
          </h2>
          <p className="text-justify text-white/75 text-base lg:text-lg font-semibold leading-normal">
            {description}
          </p>
        </div>
      </div>

      <ul
        className={`${className} w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 lg:gap-8`}
      >
        {content.map((item: any, index: any) => (
          <li
            key={index}
            className="flex flex-col bg-indigo-50/20 rounded-t-xl rounded-bl-xl backdrop-blur-[20px]"
          >
            <img
              className="rounded-t-xl brightness-90 hover:brightness-100"
              src={item.image}
              alt=""
            />
            <div className="flex flex-1 flex-col p-5">
              <h3 className="text-cyan-50 text-xl lg:text-2xl font-bold uppercase">
                {item.title}
              </h3>

              <div className="h-full text-justify text-white/75 text-sm lg:text-base font-semibold my-4">
                {item.description}
              </div>

              <a
                href={item.link}
                className="w-full px-4 py-3 bg-lightGray hover:bg-white rounded-md shadow flex justify-center items-center"
              >
                <span className="text-dark text-sm lg:text-base font-semibold capitalize">
                  {item.buttonDesc}
                </span>
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default GirdList;
