import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import Vector from "../../components/Sections/VectorRouter";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Router-Header.svg";
import AX2 from "../../images/Routers/AX2.png";
import LTE from "../../images/Routers/LTE.png";
import MD2 from "../../images/Routers/MD2.png";
import ME2 from "../../images/Routers/ME2.png";
import W4N from "../../images/Routers/W4N.png";

function Router() {
  return (
    <div>
      <Menu />

      <Header
        title="Wireless Routers"
        subTitle="SNR Series"
        miniTitle="GBT Products"
        description="Explore our latest range of high-performance SNR Wireless Routers, designed to meet the networking needs of businesses, enterprises and individuals."
        vector={header}
      />

      <GradientContainer
        className="h-[421px] md:h-[321px]"
        title="Wireless Routers – SNR Series"
        content="The SNR Series of wireless routers offers high-performance connectivity solutions designed for both home and business environments. With support for the latest Wi-Fi standards, including Wi-Fi 5 and Wi-Fi 6, these routers deliver exceptional speed, stability, and coverage through advanced technologies like MIMO and external antennas."
        description="Let's delve deeper into the specific strengths of our Network Essentials:"
      />

      <Vector
        className="bg-sky"
        itemBackground="lightGray"
        vector={ME2}
        vecLocation="start"
        title="SNR-CPE-ME2-Lite"
        description="The ME2 Lite (rev.B) features dual-band connectivity with 2.4 GHz supporting up to 300 Mbps and 5 GHz up to 867 Mbps, adhering to the 802.11ac Wave 2 standard. It is equipped with four external 5dBi antennas, ensuring extensive coverage, and includes Gigabit Ethernet ports (10/100/1000) for high-speed wired connections. With TR-069 support, the router enables remote diagnostics, firmware updates, and auto-configuration. Additionally, it supports mesh networking for seamless expansion with multiple routers. Customization options are available, including branding and regular software updates."
        pdf="ME2.pdf"
      />

      <Vector
        className="bg-lightGray"
        itemBackground="sky"
        vector={LTE}
        vecLocation="end"
        title="SNR-RT420-F21-LTE"
        description="The SNR LTE Wi-Fi router is a powerful N300 class device that supports 4G LTE Cat.4 connectivity, offering speeds of up to 300 Mbps. It features four removable omnidirectional antennas with a 5dBi gain, ensuring wide coverage and stable mobile signal reception, even in areas with poor coverage. The router includes two LAN ports and one WAN port, each supporting speeds up to 100 Mbps. It supports self-customization through the Easy Custom service, allowing changes to the default configuration, web interface design, and user access rights. Designed for small office and home office use, it can also serve as a backup gateway for internet providers."
        pdf="LTE.pdf"
      />

      <Vector
        className="bg-sky"
        itemBackground="lightGray"
        vector={AX2}
        vecLocation="start"
        title="SNR-RT622-G41"
        description="The SNR AX2 Wi-Fi router is a high-performance gigabit router supporting Wi-Fi 6 technology, designed for optimal connectivity with speeds of up to 2402 Mbps on the 5 GHz band and 574 Mbps on the 2.4 GHz band. It features four external omnidirectional antennas that provide wide coverage, ensuring stable connections even in challenging environments. The router includes one WAN port and four LAN ports, each supporting speeds up to 1000 Mbit/sec. Its EasyCustom service allows for self-configuration, enabling users to modify default settings, web interface design, and access rights without needing to build a firmware image. With support for TR-069 specifications, the router facilitates auto-configuration, firmware updates, and remote diagnostics, enhancing the management capabilities for service providers. The device also supports mesh networking, allowing multiple SNR routers to create a seamless network."
        pdf="AX2.pdf"
      />

      <Vector
        className="bg-lightGray"
        itemBackground="sky"
        vector={MD2}
        vecLocation="end"
        title="SNR-MD2"
        description="The SNR MD2 router features external omnidirectional antennas for wide coverage and supports IEEE 802.11b/g/n and 802.11a/ac Wave 2 standards, enabling simultaneous operation in 2.4 GHz (up to 300 Mbps) and 5 GHz (up to 867 Mbps) bands. With four 5dBi antennas and WAN/LAN ports supporting speeds up to 100 Mbps, it offers reliable connectivity. The router includes TR-069 support for auto-configuration, firmware updates, and remote diagnostics, along with mesh networking capabilities for seamless integration with multiple routers. It is customizable for bulk orders and comes with the SNR-CPE Config app for easy management and setup from mobile devices"
        pdf="MD2.pdf"
      />

      <Vector
        className="bg-sky"
        itemBackground="lightGray"
        vector={W4N}
        vecLocation="start"
        title="SNR-CPE-W4N"
        description="The SNR-CPE-W4N Wi-Fi router is designed to support the IEEE 802.11 b/g/n wireless standard (Wi-Fi 4) with MIMO 2x2 technology, enabling wireless data transmission speeds of up to 300 Mbps on the 2.4 GHz band. Equipped with one WAN port and four LAN ports supporting speeds up to 100 Mbps, this router is ideal for home and small office use."
        pdf="W4N.pdf"
      />

      <Footer />
    </div>
  );
}
export default Router;
