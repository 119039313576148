import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import VectorList from "../../components/Sections/VectorList";
import GridItems from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Wireless-Header.svg";
import contact from "../../images/vectors/Wireless-Contact.svg";
import p2p from "../../images/vectors/P2P.svg";
import p2mp from "../../images/vectors/P2MT.svg";
import { wireless } from "../../utils/wireless";
import { logos } from "../../utils/logos";

function Wireless() {
  return (
    <div>
      <Menu />

      <Header
        title="Unleash Seamless Connectivity with"
        subTitle="GBT's Wireless Solutions"
        miniTitle="GBT Products"
        description="In today's data-driven world, reliable and high-speed connectivity is no longer a luxury, it's a necessity.  Whether you need to connect remote offices, establish backhaul links, or provide internet access in underserved areas, GBT's comprehensive suite of wireless solutions offers the perfect answer."
        vector={header}
      />

      <GradientContainer
        className="h-[360px] md:h-[260px]"
        title="GBT's comprehensive suite of wireless solutions"
        content="GBT understands that one-size-fits-all solutions rarely suffice.  That's why we offer a comprehensive suite of wireless solutions, categorized into two distinct options to cater to your specific needs:"
      />

      <VectorList
        className="bg-sky"
        itemBackground="lightGray"
        vector={p2p}
        vecLocation="start"
        title="Point-to-Point (PTP):"
        subTitle="Bridging Long Distances with Unwavering Performance"
        description="Ideal for establishing robust connections between distant locations, our PTP solutions are engineered to excel over long distances. They offer features specifically designed to bridge vast gaps while maintaining exceptional performance."
        content={wireless.pointToPoint}
        intro="GBT's PTP wireless solutions offer exceptional features:"
      />

      <VectorList
        className="bg-lightGray"
        itemBackground="sky"
        vector={p2mp}
        vecLocation="end"
        title="Point-to-Multipoint (PTMP):"
        subTitle="Empowering Multiple Users with Broad Coverage"
        description="GBT's PTMP solutions are ideal for scenarios where you need to provide reliable internet access or connect multiple devices to a central network."
        content={wireless.pointToMultiple}
        intro="Here's what sets them apart:"
      />

      <GradientContainer
        className="h-[368px] md:h-[268px]"
        title="Why Choose GBT's Wireless Solutions?"
        content="When it comes to establishing reliable and high-speed wireless connectivity, choosing the right partner is crucial. GBT's wireless solutions go beyond simply offering powerful equipment; they provide a comprehensive package backed by experience, expertise, and a commitment to your success."
        description="Here's what sets GBT apart:"
      />

      <GridItems content={wireless.aparts} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Unlock Reliable,"
        subTitle="High-Speed Connectivity Today"
        description="Contact GBT today to discuss your specific needs and explore how our wireless solutions can empower your business with reliable, high-speed connectivity."
        vector={contact}
        subject="Wireless Solutions Product"
      />

      <Footer />
    </div>
  );
}
export default Wireless;
