import { Link } from "react-router-dom";

const TwoItems: React.FC<any> = ({ content }: { content: any }) => {
  return (
    <div className="w-full bg-sky cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col">
          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-8">
            {content.map((item: any, index: number) => (
              <li
                key={index}
                className={`h-auto bg-white p-6 rounded-t-xl rounded-bl-xl drop-shadow-darkBlue transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 ${
                  index === content.length - 1 && index % 2 === 0
                    ? "md:col-span-2"
                    : "col-span-1"
                }`}
              >
                <div className="w-7 -ml-8 -mt-8 bg-primary rounded-t-md rounded-bl-md flex justify-center items-center">
                  <div className="text-lightGray text-lg lg:text-xl font-semibold">
                    {item.number}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex justify-center items-center w-20 h-20 bg-primary/25 hover:bg-primary/35 rounded-t-xl rounded-bl-xl">
                    <img
                      src={item.icon}
                      alt=""
                      className="w-12 h-12 brightness-100 hover:brightness-125"
                    />
                  </div>
                  <div className="w-2/3 text-dark text-lg lg:text-xl font-bold capitalize leading-5">
                    {item.title}
                  </div>
                </div>
                <div className="h-auto sm:h-28 lg:h-24 text-justify text-dark/50 text-base font-normal mt-4 mb-2 leading-5">
                  {item.description}
                </div>

                {item.buttonText && (
                  <Link
                    to={`/contact?subject=${item.contact}#contactForm`}
                    className="w-full py-3 bg-gradient-to-r from-primary to-secondary rounded-md shadow flex justify-center items-center"
                  >
                    <span className="text-center text-lightGray text-xs lg:text-sm font-semibold uppercase">
                      {item.buttonText}
                    </span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TwoItems;
