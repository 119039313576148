import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridItems from "../../components/Sections/GridItems";
import Items from "../../components/Sections/TwoItems";
import Clients from "../../components/Common/Clients";
import ContactUs from "../../components/Sections/ContactUs";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/DataBox-Header.svg";
import contact from "../../images/vectors/DataBox-Contact.svg";
import { dataBox } from "../../utils/dataBox";
import { logos } from "../../utils/logos";

function DataBox() {
  return (
    <div>
      <Menu />

      <Header
        title="GBT Data Box: Your Agile & Efficient"
        subTitle="Containerized Data Center Solution"
        miniTitle="GBT Products"
        description="Businesses today need IT infrastructure that can adapt and grow quickly (agile and scalable). Traditional data centers are often clunky, costly, and take a long time to set up.  GBT's Data Box is a game-changer: a modular data center in a container that can be easily adjusted and deployed rapidly to meet your specific needs."
        vector={header}
      />

      <GradientContainer
        className="h-[345px] md:h-[245px]"
        title="Unveiling the Advantages of GBT's Data Box:"
        content="The GBT Data Box isn't just a containerized data center; it's a comprehensive solution designed to revolutionize the way you manage your IT infrastructure."
        description="Here's a deeper dive into the key advantages it offers:"
      />
      <GridItems content={dataBox.advantages} />

      <GradientContainer
        className="h-[392px] md:h-[260px]"
        title="GBT Data Box: The Future of Data Center Solutions"
        content="By combining modularity, cost-effectiveness, rapid deployment, scalability, and mobility, the GBT Data Box offers a revolutionary approach to data center infrastructure."
        description="This solution empowers businesses to:"
      />

      <Items content={dataBox.offers} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Revolutionize Your IT:"
        subTitle="Get Started with GBT Data Box Today"
        description="Contact GBT today to schedule a consultation and explore how the GBT Data Box containerized data center solution can revolutionize your IT infrastructure."
        vector={contact}
        subject="DATA BOX Product"
      />

      <Footer />
    </div>
  );
}
export default DataBox;
