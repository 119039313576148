import Menu from "../../components/Common/Menu";
import Header from "../../components/Sections/Header";
import GradientContainer from "../../components/Sections/GradientContainer";
import GridList from "../../components/Sections/GridItems";
import Clients from "../../components/Common/Clients";
import Footer from "../../components/Common/Footer";
import header from "../../images/vectors/Consultant-Header.svg";
import contact from "../../images/vectors/Consultant-Contact.svg";
import ThreeColList from "../../components/Sections/ThreeItems";
import ContactUs from "../../components/Sections/ContactUs";
import { consultancy } from "../../utils/consultancy";
import { logos } from "../../utils/logos";

function Consultancy() {
  return (
    <div>
      <Menu />

      <Header
        title="Navigate the Future with Confidence:"
        subTitle="GBT’s Consultancy Services"
        miniTitle="GBT Services"
        description="GBT's consultancy services help businesses thrive in the digital age by developing customized digital transformation strategies and leveraging data for informed decision-making."
        vector={header}
      />

      <GradientContainer
        className="h-[377px] md:h-[277px]"
        title="Our Areas of Expertise"
        content="At GBT, we offer a comprehensive suite of consultancy services designed to empower businesses with data-driven strategies for success. We partner with you to understand your unique challenges and goals, then leverage our expertise to develop customized solutions that address your specific needs."
        description="Here's what sets GBT apart and why you should choose us for your consultancy needs:"
      />

      <ThreeColList content={consultancy.offers} />

      <GradientContainer
        className="h-[410px] md:h-[310px]"
        title="Benefits of Choosing GBT for Your Consultancy Needs"
        content="In today's data-driven world, navigating the complexities of digital transformation and unlocking the true potential of your data requires a trusted partner with the expertise and experience to guide you."
        description="Here's what sets GBT apart and why you should choose us as your trusted digital marketing partner:"
      />

      <GridList content={consultancy.benefits} />

      <Clients
        title="The clients we care about"
        description="We're proud to serve a vibrant community of talented individuals and businesses.  This section highlights the diverse range of customers who rely on GBT for their online success."
        logos={logos.projects}
      />

      <ContactUs
        title="Ready to Unlock the Power of Data"
        subTitle="and Transform Your Business?"
        description="Contact GBT today and discuss your consultancy needs with our expert team. We'll work collaboratively to develop a data-driven strategy that empowers your organization to achieve its full potential."
        vector={contact}
        subject="Consultancy Services"
      />

      <Footer />
    </div>
  );
}
export default Consultancy;
