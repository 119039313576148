import React, { useState, useEffect } from "react";
import { CompanyInfo } from "../../utils/info";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const Statistics: React.FC = () => {
  const [startCount, setStartCount] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this threshold to control when the count-up starts
  });

  useEffect(() => {
    if (inView) {
      setStartCount(true);
    }
  }, [inView]);

  return (
    <div className="w-full h-auto lg:h-[314px] bg-cover bg-center bg-contact cursor-default">
      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="flex flex-col gap-4">
          <div className="text-3xl lg:text-4xl font-extrabold drop-shadow-blue uppercase gap-4 leading-8 lg:leading-[48.76px]">
            <div className="text-lightGray">Trusted by clients</div>
            <div className="text-primary">from over the World</div>
          </div>

          <div className="flex justify-between gap-8">
            {CompanyInfo.map((info, index) => (
              <div key={index} className="flex items-center">
                <img
                  src={info.icon}
                  alt=""
                  className="w-8 md:w-full h-8 md:h-full mr-2"
                />

                <div className="flex flex-col">
                  <div
                    ref={ref}
                    className="text-lightGray text-xl md:text-4xl font-extrabold"
                  >
                    {startCount && (
                      <CountUp
                        start={0}
                        end={info.number}
                        duration={5}
                        suffix={info.suffix}
                      />
                    )}
                  </div>
                  <span className="text-lightGray text-sm md:text-2xl font-extrabold">
                    {info.title}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Statistics;
