import Menu from "../components/Common/Menu";
import Header from "../components/Common/Header";
import Goals from "../components/About/Goals";
import Teams from "../components/About/Teams";
import GradientContainer from "../components/Sections/GradientContainer";
import Clients from "../components/Common/Clients";
import Footer from "../components/Common/Footer";
import vector from "../images/vectors/Join-us.svg";
import userFocus from "../images/grayIcons/UserFocus.svg";
import personRun from "../images/grayIcons/PersonSimpleRun.svg";
import handshake from "../images/grayIcons/Handshake.svg";
import { logos } from "../utils/logos";
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      <Menu />

      <Header
        title="GBT Solutions"
        subTitle="Where Ideas Meet Innovation"
        miniTitle="About GBT"
        description="In a world buzzing with technology, GBT Solutions isn't just
        another IT company. We're a team of passionate tech wizards,
        driven by a singular vision: to bridge the gap between brilliant
        ideas and tangible results. We don't just build servers; we
        build possibilities."
      />

      <GradientContainer
        className="h-[397px] md:h-[297px]"
        title="GBT Solutions"
        content="Grand Block Technologies (GBT) is an end-to-end solutions and
        integrated service management organization, offering
        comprehensive a services for the full spectrum of ICT Products &
        Software Solutions. Our strategic solutions and service delivery
        standards are designed to achieve higher level of productivity,
        efficiency, reliability and security with a proven global
        delivery standards."
        description="Whatever You Want to Do , Our Experts Have Probably Done it
        Before."
      />

      <div className="w-full">
        <Goals />
      </div>

      <GradientContainer
        className="h-[397px] md:h-[240px]"
        title=" What sets us apart?"
        content="We don't just build tech solutions; we build relationships. Our
                obsession isn't with code; it's with understanding your unique
                challenges and crafting tech that works for you, not against
                you. While others speak in algorithms, we speak your language,
                bridging the gap between business goals and digital realities.
                That's the GBT difference: tech with a pulse, designed to
                empower, not overwhelm."
      />

      <div className="w-full h-auto xl:h-[248px] flex flex-col lg:flex-row cursor-default">
        <div className="w-auto xl:w-[600px] bg-sky p-4 lg:p-8">
          <img className="w-full h-12" src={userFocus} alt="" />

          <h2 className="w-full text-center text-dark text-xl font-semibold">
            Customer Centricity
          </h2>
          <p className="text-justify text-dark/50 text-base font-normal">
            Customer Centricity is the willingness and ability to give priority,
            delivering high quality services and providing a great end-to-end
            customers journey which exceeds their needs and expectations.
          </p>
        </div>

        <div className="w-auto xl:w-[700px] bg-blue-100 p-4 lg:p-8">
          <img className="w-full h-12" src={personRun} alt="" />

          <h2 className="w-full text-center text-dark text-xl font-semibold">
            Agility
          </h2>
          <p className="text-justify text-dark/50 text-base font-normal">
            Agility is the ability to respond quickly and being able to think
            and respond differently. In GBT, we understand the fast market
            changes and customers needs. We are ALWAYS ready to catch the train.
          </p>
        </div>

        <div className="w-auto xl:w-[600px] bg-sky p-4 lg:p-8">
          <img className="w-full h-12" src={handshake} alt="" />

          <h2 className="w-full text-center text-dark text-xl font-semibold">
            Collaboration
          </h2>
          <p className="text-justify text-dark/50 text-base font-normal">
            Collaboration is about working co-opearatively across organizational
            levels and unit boundaries; recognizing and adapting to different
            ways of working to achieve common and shared goals.
          </p>
        </div>
      </div>

      {/* <div>
        <Teams />
      </div> */}

      {/* <Clients
        title="Our clients"
        description="At GBT Solutions, we don't see limitations, we see potential. Our
          customers are as diverse as the tech landscape itself, spanning from
          budding startups to established giants. Each story, each challenge,
          fuels our passion to craft solutions that empower, regardless of your
          size, industry, or ambition."
        logos={logos.customers}
      /> */}

      <div className="w-full bg-lightGray cursor-default">
        <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
          <div className="flex flex-col md:flex-row items-center gap-8 xl:gap-24">
            <div className="w-auto md:w-[500px] px-12">
              <div className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-3xl lg:text-5xl font-extrabold uppercase leading-10 lg:leading-[56px]">
                Ready to join
                <br />
              </div>
              <div className="text-dark text-3xl lg:text-4xl font-extrabold uppercase leading-10 lg:leading-[56px]">
                the flight?
              </div>
              <p className="text-justify text-dark/50 text-base lg:text-lg font-normal">
                Explore our services, meet our minds, or simply say hello. We're
                always eager to collaborate, challenge the status quo, and take
                the next leap into the realm of digital wonders.
              </p>
              <div className="flex gap-4 mt-4">
                <Link
                  to="#"
                  className="w-full p-3 md:p-4 bg-gradient-to-r from-primary to-primary rounded-t-md rounded-bl-md gap-1.5 shadow flex justify-center items-center"
                >
                  <span className="text-lightGray hover:text-lightGray/70 text-sm lg:text-lg text-center font-bold uppercase">
                    explore services
                  </span>
                </Link>
                <Link
                  to={`/contact?subject="About GBT page"#contactForm`}
                  className="w-full p-2 md:p-4 bg-gradient-to-r from-dark to-secondary rounded-t-md rounded-bl-md gap-1.5 shadow flex justify-center items-center brightness-90 hover:brightness-100"
                >
                  <span className="text-lightGray hover:text-white text-sm lg:text-lg text-center font-bold uppercase">
                    Contact Us
                  </span>
                </Link>
              </div>
            </div>

            <img
              className="w-auto md:w-[340px] xl:w-[540px] transition ease-out delay-150 hover:translate-x-5 hover:scale-110  duration-300"
              src={vector}
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default About;
